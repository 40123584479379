import AppConfigApi from "@/api/AppConfigApi";
import { AppPlatform } from "@/composables/useAppInfo";
import { useStore } from "@/composables/useTypedStore";
import { MapSettingsInterface } from "@/types/map/interfaces";
import { getCache, setCache } from "@/store/storage/ionicStorage";
import { Network } from "@capacitor/network";

export interface AppColorsInterface {
  primary: string;
  secondary: string;
}

export interface AppFontsInterface {
  primary: string;
  secondary: string;
}

export interface AppIdentifiersInterface {
  web: string;
  ios: string;
  android: string;
}

export interface MapConfig {
  mapStyle: {
    style: string;
    maxZoom: number;
    minZoom: number;
    mapCenter: Array<number>,
    mapInitialZoomLevel: number;
  };
  controller: {
    locate: boolean;
  };
  drawOptions: {
    controls: {
      point: boolean;
      trash: boolean;
      polygon: boolean;
      line_string: boolean;
    };
    displayControlsDefault: boolean;
  }
}

export interface MapPoints {
  min: number;
  max: number;
}

export interface AppFeatureFlags {
  matterport?: boolean;
  createSurvey?: boolean;
}

export interface AppConfig {
  config: {
    colors: AppColorsInterface;
    fonts: AppFontsInterface;
    appIdentifiers: AppIdentifiersInterface;
  };
}

export default function useAppConfig() {
  const store = useStore()

  async function handleAppConfig() {
    const cachedAppConfig = await getCache('cachedAppConfig')

    if (cachedAppConfig) {
      const parsedConfig = JSON.parse(cachedAppConfig) as AppConfig
      handleResponse(parsedConfig)
      getAppConfig()
    } else {
      await getAppConfig()
    }
  }

  async function getAppConfig() {
    const networkStatus = await Network.getStatus()
    if(!networkStatus.connected) return

    if(store.state.app.appPlatform === AppPlatform.desktop) {
      const hostname = window.location.origin
      const appConfigResponse = (await AppConfigApi.getAppConfig(hostname)).data.data
      setCache('cachedAppConfig', JSON.stringify(appConfigResponse))
      handleResponse(appConfigResponse)
    } else {
      const appId = store.state.app.appInfo.id as string
      const appConfigResponse = (await AppConfigApi.getAppConfig(appId)).data.data
      setCache('cachedAppConfig', JSON.stringify(appConfigResponse))
      handleResponse(appConfigResponse)
    }
  }

  async function handleResponse(data: AppConfig) {
    await store.commit("app/setAppConfig", data)
    const colors = data.config.colors
    const fonts = data.config.fonts
    await resolveAppColors(colors)
    await resolveAppFonts(fonts)
  }

  function resolveAppColors(colors: AppColorsInterface) {
    const root = document.querySelector(':root') as HTMLElement
    const colorsList = {
      '--primary': colors.primary,
      '--secondary': colors.secondary,
    };
  
    Object.entries(colorsList).forEach(v => {
      root.style.setProperty(v[0], v[1])
    })
  
    // TODO Desktop favicon - later stage
  }

  function resolveAppFonts(fonts: AppFontsInterface) {
    const root = document.querySelector(':root') as HTMLElement
    const fontsList = {
      '--primaryFont': fonts.primary,
      '--secondaryFont': fonts.secondary,
    };
  
    Object.entries(fontsList).forEach(v => {
      root.style.setProperty(v[0], v[1])
    })
  }

  return {
    handleAppConfig,
    resolveAppColors,
    resolveAppFonts
  };
}