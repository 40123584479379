<template>
  <!-- Seilzugflächen-->
  <span v-for="(seilzugflaeche, szfKey) in getSeilzugflaechen(ba)" :key="szfKey">
    <!-- #################### -->
    <!-- Seilzugflächen Pages -->
    <!-- #################### -->

  <!-- ###################### -->
  <!-- Seilzugfläche Allgemein Page -->
    <pdf-a4-page no-margin :pdf-key="lang" class="pdf-content"> 
    <div class="landingpage-background w-screen h-screen">
      <div class="flex flex-col justify-between" style="height:1020px">
        <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>

        <div class="text-xl md:text-xl font-bold">Seilzugfläche {{ szfKey + 1 }} ({{ seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.typ')?.getSelectedAuswahlLabelsString()  }})</div>
        <div class="content h-full max-h-full">
          <!-- Allgemein -->
            <div class="grid grid-cols-8 justify-items-stretch items-center gap-y-3">
            <div class="text-lg md:text-lg col-span-8">Allgemein</div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              Frage
            </div>
            <div
              class="text-sm font-bold col-span-6"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Beschreibung/Eingabewerte
            </div>

            <!-- Entfernung Mast start-->
            <div class="text-xs col-span-2">Seilzug von/nach Mast:</div>
            <div class="text-xs text-gray-500 col-span-5">
              {{seilzugflaeche.findFrageByRelativePath(ba,'allgemein-modal.allgemein-group.seilzugvonmast')['eingabeText']}}
            </div>
            <div class="text-xs col-span-2">Abspannabschnittlänge:</div>
            <div class="text-xs text-gray-500 col-span-5">
              {{seilzugflaeche.findFrageByRelativePath(ba,'allgemein-modal.allgemein-group.abspannabschnittlaenge')['eingabeText']}}
            </div>
            <div class="text-xs col-span-2">Seilzugfläche Erfordernisse:</div>
            <div class="text-xs text-gray-500 col-span-5">
              {{seilzugflaeche.findFrageByRelativePath(ba,'allgemein-modal.allgemein-group.erfordnerisse')?.getSelectedAuswahlLabelsString()}}
            </div>
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="allgemein-modal.allgemein-group.flaeche-vorhanden"
              label="Fläche zwischen Mast und Seilzugfläche vorhanden"
              class="col-span-8"
            />
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="allgemein-modal.allgemein-group.flaeche-notwendig"
              label="Fläche zwischen Mast und Seilzugfläche notwendig"
              class="col-span-8"
            />
          </div>
          <br>
          <div class="text-xl md:text-xl font-bold">Seilzugfläche</div>
          <!-- Allgemeine Punkte Start-->

          <div class="grid grid-cols-8 justify-items-stretch items-center gap-y-3">
            <div class="text-lg md:text-lg col-span-8">Allgemeine Punkte (Seilzugfläche)</div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              Frage
            </div>
            <div
              class="text-sm font-bold col-span-3"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Beschreibung/Eingabewerte
            </div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Weitere Eingaben
            </div>
            <div
              class="col-span- text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Bewertung
            </div>
            <!-- Entfernung Mast start-->
            <div class="text-xs col-span-2">Soll-Entfernung zum Mastmittelpunkt:</div>
            <div class="text-xs text-gray-500 col-span-5">
              {{seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.allgemein.entfernung-mast-soll')['eingabeText']}}
            </div>
            <div class="text-xs col-span-2">Ist-Entfernung zum Mastmittelpunkt:</div>
            <div class="text-xs text-gray-500 col-span-5">
              {{seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.allgemein.entfernung-mast-ist')['eingabeText']}}
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.allgemein.entfernung-mast-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>

            <!-- Nutzungsart Ende-->
            <!-- Nutzungsart start-->
            <div class="text-xs col-span-2">Nutzungsarten der Fläche:</div>
            <div class="text-xs text-gray-500 col-span-5">
              <ul
                v-for="(v, key) in seilzugflaeche.findFrageByRelativePath(ba,
                  'seilzugflaeche-modal.allgemein.nutzungsart-der-flaeche'
                )['eingabeMehrfachAuswahlWerts']"
                :key="key"
                class=""
              >
                <li :key="key" class="text-xs text-gray-500">{{ v }}</li>
              </ul>
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.allgemein.nutzungsart-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>

            <!-- Nutzungsart Ende-->
            <!-- Fremdobjekte start-->

            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="seilzugflaeche-modal.allgemein.fremdobjekte-vorhanden"
              label="Fremdobjekte auf Fläche"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-3">
              {{seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.allgemein.fremdobjekte-auf-flaeche')['eingabeText']}} 
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.allgemein.weitere-fremdobjekte-auf-flaeche')['eingabeText']}} 
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.allgemein.fremdobjekte-auf-flaeche-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>

            <!-- Fremdobjekte Ende-->
            <!-- Sonstiges start-->

            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="seilzugflaeche-modal.allgemein.sonstiges-vorhanden"
              label="Sonstiges"
              class="col-span-2"
            />
            <div class="text-xs col-span-4 text-gray-500">
              {{seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.allgemein.sonstiges-beschreibung')['eingabeText']}}
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.allgemein.sonstiges-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end col-span-2"
            ></bewertung-chip>
            <!-- Sonstiges end-->

            <!-- Flurstücke start-->
            <div class=" col-span-2 text-sm font-bold" style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;">Analyse </div>
            <div class=" text-sm font-bold col-span-6" style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;">Analyseergebnisse</div>

            <div class="text-xs md:text-xs col-span-2 ">Betroffene Flurstücke:</div>
            <div class="text-xs text-gray-500 col-span-6 grid grid-cols-3">
              <div v-for="(v,key) in seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.allgemein.betroffeneflurstuecke').getCurrentInput()?.split(';')?.sort()" :key="key"
                class="text-xs text-gray-500"
                >
                      {{v}}
              </div>
            </div>
            <!-- Flurstücke end-->

            <!-- Fremdobjekte Ende-->
            <!-- <oneto-five-image-div-mehrfach
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.allgemeine-punkte-group.allgemein-bilder"
              :lang="lang"
              class="col-span-8"
            >
            </oneto-five-image-div-mehrfach>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              Analyse
            </div>
            <div
              class="text-sm font-bold col-span-6"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Analyseergebnisse
            </div>
            <div class="text-xs col-span-2">Fremdobjekte auf Fläche:</div>
            <div class="text-xs text-gray-500 col-span-6">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.arbeitsflaeche-mast-tab.allgemeine-punkte-group.fremdobjekte-auf-flaeche'
                )['eingabeText']
              }}
            </div> -->
          </div>

          <!-- Allgemeine Punkte Ende-->
        </div>

        <Seilzugflaechen-footer
          :ba="ba"
          :immobilie="immobilie"
          :lang="lang"
          class=""
        />
      </div>
    </div>
    </pdf-a4-page>
  <!-- ###################### -->
  <!-- Seilzugfläche Geländebeschaffenheit Page 1  -->
    <pdf-a-4-page no-margin :pdf-key="lang" class="pdf-content">
      <div class="landingpage-background w-screen h-screen">
        <div class="flex flex-col justify-between" style="height:1020px">
          <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>
            <div class="h-full max-h-full">
            <!-- Geländebeschaffenheit Start-->
            <div class="text-lg md:text-lg col-span-8">Geländebeschaffenheit (Seilzugfläche) 1/2</div>

                      <div
            class="mt-0 grid grid-cols-8 justify-items-stretch items-center gap-y-3"
          >
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              Frage
            </div>
            <div
              class="text-sm font-bold col-span-3"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Beschreibung/Eingabewerte
            </div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Weitere Eingaben
            </div>
            <div
              class="col-span- text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Bewertung
            </div>
            <!-- Gefälle start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="seilzugflaeche-modal.gefaelle-group.gefaelle-vorhanden"
              label="Gefälle/Hang/Böschung"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-3">
              <ul
                v-for="(v, key) in seilzugflaeche.findFrageByRelativePath(ba,
                  'seilzugflaeche-modal.gefaelle-group.gefaelle-boeschung-art'
                )['eingabeMehrfachAuswahlWerts']"
                :key="key"
                class=""
              >
                <li :key="key" class="text-xs text-gray-500">{{ v }}</li>
              </ul>
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.gefaelle-group.gefaelle-beschreibung')['eingabeText']}}
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.gefaelle-group.gefaelle-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <!-- todo images gefälle/Hang/Böschung -->
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="seilzugflaeche-modal.gefaelle-group.gefaelle-bilder"
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>

            <!-- Gefälle Ende-->
            
            <!-- Ausholzung start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="seilzugflaeche-modal.ausholzung-group.ausholzung-erforderlich"
              label="Ausholzung erforderlich"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-3">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.ausholzung-group.ausholzung-art')?.getSelectedAuswahlLabelsString()  }}
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.ausholzung-group.ausholzung-beschreibung')['eingabeText']}}
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.ausholzung-group.ausholzung-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="seilzugflaeche-modal.ausholzung-group.ausholzung-bilder"
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>
            <!-- Ausholzung Ende-->


            <!-- Rodung start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="seilzugflaeche-modal.rodung-group.rodung-erforderlich"
              label="Rodung erforderlich"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-3">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.rodung-group.rodung-anzahl-baeume')['eingabeText'] }}
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.rodung-group.rodung-beschreibung')['eingabeText']}}
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.rodung-group.rodung-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="seilzugflaeche-modal.rodung-group.rodung-bilder"
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>
            <!-- Rodung Ende-->

            <!-- Graben start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="seilzugflaeche-modal.graben-group.graben-vorhanden"
              label="Graben vorhanden"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-3">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.graben-group.graben-anzahl')['eingabeText'] }}
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.graben-group.graben-beschreibung')['eingabeText']}}
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.graben-group.graben-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="seilzugflaeche-modal.graben-group.graben-bilder"
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>
            <!-- Graben Ende-->

          </div>
            </div>
             <Seilzugflaechen-footer
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class=""
            />
        </div>
      </div>
    </pdf-a-4-page>           
  <!-- ###################### -->
  <!-- Seilzugfläche Geländebeschaffenheit Page 2  -->
    <pdf-a-4-page no-margin :pdf-key="lang" class="pdf-content">
      <div class="landingpage-background w-screen h-screen">
        <div class="flex flex-col justify-between" style="height:1020px">
          <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>
            <div class="h-full max-h-full">
            <!-- Geländebeschaffenheit Start-->
            <div class="text-lg md:text-lg col-span-8">Geländebeschaffenheit (Seilzugfläche) 2/2</div>

                      <div
            class="mt-0 grid grid-cols-8 justify-items-stretch items-center gap-y-3"
          >
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              Frage
            </div>
            <div
              class="text-sm font-bold col-span-3"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Beschreibung/Eingabewerte
            </div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Weitere Eingaben
            </div>
            <div
              class="col-span- text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Bewertung
            </div>
            <!-- Feuchtgebiete start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="seilzugflaeche-modal.feuchtgebiete-group.graben-vorhanden"
              label="Feuchtgebiete vorhanden"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-5">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.feuchtgebiete-group.feuchtgebiete-beschreibung')['eingabeText'] }}
            </div>

            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.feuchtgebiete-group.feuchtgebiete-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="seilzugflaeche-modal.feuchtgebiete-group.feuchtgebiete-bilder"
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>
            <!-- Feuchtgebiete Ende-->

            <!-- Naturdenkmäler start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="seilzugflaeche-modal.naturdenkmaeler-group.naturdenkmaeler-vorhanden"
              label="Naturdenkmäler vorhanden"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-5">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.naturdenkmaeler-group.naturdenkmaeler-beschreibung')['eingabeText'] }}
            </div>

            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.naturdenkmaeler-group.naturdenkmaeler-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <!-- todo images Naturdenkmäler -->
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="seilzugflaeche-modal.naturdenkmaeler-group.naturdenkmaeler-bilder"
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>
            <!-- Naturdenkmäler Ende-->


            <!-- Kampfmittel start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="seilzugflaeche-modal.kampfmittel-group.kampfmittel-vorhanden"
              label="Kampfmittel vorhanden"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-1">
              <template v-if="seilzugflaeche.findFrageByRelativePath(ba, 'seilzugflaeche-modal.kampfmittel-group.kampfmittel-freimessung')?.getCurrentInput()">
                Freimessung erforderlich
              </template>
              <template v-else>
                Freimessung nicht erforderlich
              </template>
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.kampfmittel-group.kampfmittel-art')['eingabeText'] }}
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.kampfmittel-group.kampfmittel-beschreibung')['eingabeText'] }}
            </div>

            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.kampfmittel-group.kampfmittel-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <!-- todo images Kampfmittel -->
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="seilzugflaeche-modal.kampfmittel-group.kampfmittel-bilder"
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>
            <!-- Kampfmittel Ende-->


            <!-- Sonstiges start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="seilzugflaeche-modal.sonstiges-group.sonstiges-vorhanden"
              label="Sonstiges vorhanden"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-5">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.sonstiges-group.sonstiges-beschreibung')['eingabeText'] }}
            </div>

            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.sonstiges-group.sonstiges-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <!-- todo images Sonstiges -->
            <!-- <oneto-five-image-div-mehrfach
              :ba="ba"
              :immobilie="immobilie"
              fragesPath=""
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach> -->
            <!-- Sonstiges Ende-->

            <!-- Geländebeschaffenheit Ende-->
            <!-- Hang/Boeaschung Start-->
            <!-- <is-vorhanden-char-and-label
              :ba="ba"
              fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.hangboeschung-group.hang-boeschung-vorhanden"
              label="Hang/B&ouml;schung"
              class="col-span-2"
            />

            <div class="text-xs text-gray-500 col-span-3">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.arbeitsflaeche-mast-tab.hangboeschung-group.hang-boeschung-beschreibung'
                )['eingabeText']
              }}
            </div>
            <div class="text-xs">Art:</div>
            <div class="text-xs text-gray-500">
              <ul
                v-for="(v, key) in useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.arbeitsflaeche-mast-tab.hangboeschung-group.hang-boeschung-art'
                )['eingabeMehrfachAuswahlWerts']"
                :key="key"
              >
                <li :key="key" class="text-xs text-gray-500">{{ v }}</li>
              </ul>
            </div>
            <bewertung-chip
              fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.hangboeschung-group.hang-boeschung-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>

            <oneto-five-image-div-mehrfach
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.hangboeschung-group.hangboeschung-bilder"
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach> -->

            <!-- Gefälle Ende-->
          </div>
            </div>
             <Seilzugflaechen-footer
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class=""
            />
        </div>
      </div>
    </pdf-a-4-page>         
  <!-- ###################### -->
  <!-- Seilzugfläche Ökologie Page und Bilder -->
    <pdf-a-4-page no-margin :pdf-key="lang" class="pdf-content">
      <div class="landingpage-background w-screen h-screen">
        <div class="flex flex-col justify-between" style="height:1020px">
          <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>
            <div class="h-full max-h-full">
            <!-- Ökologie Start-->
            <div class="mt-4 text-lg md:text-lg">Ökologie (Seilzugfläche)</div>
              <div
                class="grid grid-cols-8 justify-items-stretch items-center gap-y-3"
              >
                <div
                  class="col-span-2 text-sm font-bold"
                  style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
                >
                  Frage
                </div>
                <div
                  class="text-sm font-bold col-span-5"
                  style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
                >
                  Beschreibung/Eingabewerte
                </div>
                <div
                  class="col-span- text-sm font-bold"
                  style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
                >
                  Bewertung
                </div>


                <div class="text-xs col-span-2">Ampelkarte</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'seilzugflaeche-modal.oekologie-group.ampelkarte')?.getSelectedAuswahlLabelsString() }}</div>
                 
                 <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="seilzugflaeche-modal.oekologie-group.ampelkarte-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>

                <div class="text-xs col-span-2">FFH-Gebiet</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'seilzugflaeche-modal.oekologie-group.ffh-gebiet')?.getSelectedAuswahlLabelsString() }}</div>

                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="seilzugflaeche-modal.oekologie-group.ffh-gebiet-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>

                <div class="text-xs col-span-2">Biotop</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'seilzugflaeche-modal.oekologie-group.biotop')?.getSelectedAuswahlLabelsString() }}</div>
                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="seilzugflaeche-modal.oekologie-group.biotop-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>


                <div class="text-xs col-span-2">Vogelschutzgebiet</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'seilzugflaeche-modal.oekologie-group.vogelschutzgebiet')?.getSelectedAuswahlLabelsString() }}</div>

                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="seilzugflaeche-modal.oekologie-group.vogelschutzgebiet-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>

                <!-- <div class="text-xs col-span-2">Bodenschutzgebiet</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'seilzugflaeche-modal.oekologie-group.bodenschutzgebiet')?.getCurrentInput() }}</div>

                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="seilzugflaeche-modal.oekologie-group.bodenschutzgebiet-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip> -->

                <div class="text-xs col-span-2">Wasserschutzgebiet</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'seilzugflaeche-modal.oekologie-group.wasserschutzgebiet')?.getSelectedAuswahlLabelsString() }}</div>


                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="seilzugflaeche-modal.oekologie-group.wasserschutzgebiet-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>

              
                <div class="text-xs col-span-2">Sonstiges</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'seilzugflaeche-modal.oekologie-group.sonstiges')?.getCurrentInput() }}</div>

                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="seilzugflaeche-modal.oekologie-group.sonstiges-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>

              </div>
            <br>
            <div class="mt-4 text-lg md:text-lg">Allgemeine Bilder Seilzugfläche</div>
                <div class="grid grid-cols-8 justify-items-stretch items-center gap-y-3">
                  <div class="col-span-8 text-sm font-bold" style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;">                  
                  </div>
                <oneto-five-image-div-mehrfach
                  :fragenblock="seilzugflaeche"
                  :ba="ba"
                  :immobilie="immobilie"
                  fragesPath="seilzugflaeche-modal.bilder-group.bilder"
                  :lang="lang"
                  class="col-span-8 ml-2"
                >
                </oneto-five-image-div-mehrfach>
              </div>

            <br>
            </div>
            <Seilzugflaechen-footer
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class=""
            />
        </div>
      </div>
    </pdf-a-4-page>


  <!-- ###################### -->
  <!-- Zuwegung Allgemein Page -->
    <pdf-a4-page no-margin :pdf-key="lang" class="pdf-content" v-if="
      seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.zuwegung-vorhanden')?.getCurrentInput() ||
      seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.zuwegung-notwendig')?.getCurrentInput() "
    >
      
    <div class="landingpage-background w-screen h-screen">
      <div class="flex flex-col justify-between" style="height:1020px">
        <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>

        <div class="text-xl md:text-xl font-bold">Zuwegung Seilzugfläche </div>
          <!-- Seilzugfläche {{ szfKey + 1 }} ({{ seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.typ')?.getSelectedAuswahlLabelsString()  }})</div> -->
        <div class="content h-full max-h-full">
          <!-- Allgemeine Punkte Start-->
          <div class="grid grid-cols-8 justify-items-stretch items-center gap-y-3">
            <div class="text-lg md:text-lg col-span-8">Allgemeine Punkte (Zuwegung)</div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              Frage
            </div>
            <div
              class="text-sm font-bold col-span-3"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Beschreibung/Eingabewerte
            </div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Weitere Eingaben
            </div>
            <div
              class="col-span- text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Bewertung
            </div>
            <!-- Fremdobjekte start-->

            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="zuwegung-seilzugflaeche-modal.allgemein.fremdobjekte-vorhanden"
              label="Fremdobjekte auf Fläche"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-3">
              {{seilzugflaeche.findFrageByRelativePath(ba,'zuwegung-seilzugflaeche-modal.allgemein.fremdobjekte-auf-flaeche')['eingabeText']}} 
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{seilzugflaeche.findFrageByRelativePath(ba,'zuwegung-seilzugflaeche-modal.allgemein.weitere-fremdobjekte-auf-flaeche')['eingabeText']}} 
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="zuwegung-seilzugflaeche-modal.allgemein.fremdobjekte-auf-flaeche-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>

            <!-- Fremdobjekte Ende-->
            <!-- Sonstiges start-->

            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="zuwegung-seilzugflaeche-modal.allgemein.sonstiges-vorhanden"
              label="Sonstiges"
              class="col-span-2"
            />
            <div class="text-xs col-span-4 text-gray-500">
              {{seilzugflaeche.findFrageByRelativePath(ba,'zuwegung-seilzugflaeche-modal.allgemein.sonstiges-beschreibung')['eingabeText']}}
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="zuwegung-seilzugflaeche-modal.allgemein.sonstiges-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end col-span-2"
            ></bewertung-chip>
            <!-- Sonstiges end-->

            <!-- Flurstücke start-->
            <div class=" col-span-2 text-sm font-bold" style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;">Analyse </div>
            <div class=" text-sm font-bold col-span-6" style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;">Analyseergebnisse</div>

            <div class="text-xs md:text-xs col-span-2 ">Betroffene Flurstücke:</div>
            <div class="text-xs text-gray-500 col-span-6 grid grid-cols-3">
              <div v-for="(v,key) in seilzugflaeche.findFrageByRelativePath(ba,'zuwegung-seilzugflaeche-modal.allgemein.betroffeneflurstuecke').getCurrentInput()?.split(';')?.sort()" :key="key"
                class="text-xs text-gray-500"
                >
                      {{v}}
              </div>
            </div>
            <!-- Flurstücke end-->
          </div>

          <!-- Allgemeine Punkte Ende-->
        </div>

        <Seilzugflaechen-footer
          :ba="ba"
          :immobilie="immobilie"
          :lang="lang"
          class=""
        />
      </div>
    </div>
    </pdf-a4-page>
  <!-- ###################### -->
  <!-- Zuwegung Geländebeschaffenheit Page  -->
   <pdf-a4-page no-margin :pdf-key="lang" class="pdf-content" v-if="
      seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.zuwegung-vorhanden')?.getCurrentInput() ||
      seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.zuwegung-notwendig')?.getCurrentInput() "
    >
      <div class="landingpage-background w-screen h-screen">
        <div class="flex flex-col justify-between" style="height:1020px">
          <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>
            <div class="h-full max-h-full">
            <!-- Geländebeschaffenheit Start-->
            <div class="mt-4 text-lg md:text-lg">Geländebeschaffenheit (Zuwegung)</div>
                      <div
            class="mt-0 grid grid-cols-8 justify-items-stretch items-center gap-y-3"
          >
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              Frage
            </div>
            <div
              class="text-sm font-bold col-span-3"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Beschreibung/Eingabewerte
            </div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Weitere Eingaben
            </div>
            <div
              class="col-span- text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Bewertung
            </div>
            <!-- Gefälle start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="zuwegung-seilzugflaeche-modal.gefaelle-group.gefaelle-vorhanden"
              label="Gefälle"
              class="col-span-7"
            />
            <!-- <div class="text-xs text-gray-500 col-span-3">
              <ul
                v-for="(v, key) in seilzugflaeche.findFrageByRelativePath(ba,
                  'zuwegung-seilzugflaeche-modal.gefaelle-group.gefaelle-boeschung-art'
                )['eingabeMehrfachAuswahlWerts']"
                :key="key"
                class=""
              >
                <li :key="key" class="text-xs text-gray-500">{{ v }}</li>
              </ul>
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.gefaelle-group.gefaelle-beschreibung')['eingabeText']}}
            </div> -->
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="zuwegung-seilzugflaeche-modal.gefaelle-group.gefaelle-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <!-- todo images gefälle/Hang/Böschung -->
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="zuwegung-seilzugflaeche-modal.gefaelle-group.gefaelle-bilder"
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>

            <!-- Gefälle Ende-->
            
            <!-- Ausholzung start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="zuwegung-seilzugflaeche-modal.ausholzung-group.ausholzung-erforderlich"
              label="Ausholzung erforderlich"
              class="col-span-2"
            />

            <div class="text-xs text-gray-500 col-span-5">
              {{seilzugflaeche.findFrageByRelativePath(ba,'zuwegung-seilzugflaeche-modal.ausholzung-group.ausholzung-beschreibung')['eingabeText']}}
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="zuwegung-seilzugflaeche-modal.ausholzung-group.ausholzung-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="zuwegung-seilzugflaeche-modal.ausholzung-group.ausholzung-bilder"
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>
            <!-- Ausholzung Ende-->


            <!-- Rodung start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="zuwegung-seilzugflaeche-modal.rodung-group.rodung-erforderlich"
              label="Rodung erforderlich"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-3">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'zuwegung-seilzugflaeche-modal.rodung-group.anzahl-baeume')['eingabeText'] }}
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{seilzugflaeche.findFrageByRelativePath(ba,'zuwegung-seilzugflaeche-modal.rodung-group.rodung-beschreibung')['eingabeText']}}
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="zuwegung-seilzugflaeche-modal.rodung-group.rodung-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <!-- todo images Rodung -->
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="zuwegung-seilzugflaeche-modal.rodung-group.rodung-bilder"
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>
            <!-- Rodung Ende-->
<!-- 
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="seilzugflaeche-modal.graben-group.graben-vorhanden"
              label="Graben vorhanden"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-3">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.graben-group.graben-anzahl')['eingabeText'] }}
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.graben-group.graben-beschreibung')['eingabeText']}}
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.graben-group.graben-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath=""
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>
 -->



            <!-- Sonstiges start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="seilzugflaeche-modal.sonstiges-group.sonstiges-vorhanden"
              label="Sonstiges vorhanden"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-5">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.sonstiges-group.sonstiges-beschreibung')['eingabeText'] }}
            </div>

            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.sonstiges-group.sonstiges-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
          </div>

            </div>
             <Seilzugflaechen-footer
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class=""
            />
        </div>
      </div>
    </pdf-a4-page>           
<!-- Zuwegung Geländebeschaffenheit Page  -->
    <pdf-a-4-page v-if="getGrabenquerungen(ba,seilzugflaeche).length > 0" no-margin :pdf-key="lang" class="pdf-content">
      <div class="landingpage-background w-screen h-screen">
        <div class="flex flex-col justify-between" style="height:1020px">
          <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>
            <div class="h-full max-h-full">
            <div class="mt-4 text-lg md:text-lg">Grabenquerungen</div>
            
            <div v-if="getGrabenquerungen(ba,seilzugflaeche).length > 0">
            <div class="grid grid-cols-8 justify-items-stretch items-center">
              <div
                class="col-span-3 text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
              >
                Anzahl
              </div>
              <div
                class="text-sm font-bold col-span-4"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Beschreibung/Eingabewerte
              </div>

              <div
                class="col-span- text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Bewertung
              </div>
            </div>
            <div
              v-for="(grabenquerung, key) in getGrabenquerungen(ba,seilzugflaeche)"
              :key="key"
              class="ml-2 grid grid-cols-8 justify-items-stretch items-center"
              style="
                border-style: solid;
                border-color: white white white rgb(216, 215, 26);
                border-radius: 5px;
              "
            >
            <div class="text-xs col-span-3"> {{ grabenquerung['graben-anzahl'] }} </div>
              <div class="text-xs text-gray-500 col-span-4">
                {{ grabenquerung['graben-beschreibung'] }}
              </div>
              <div class="justify-self-end row-span-2">
                <PdfChip
                  v-if="'plus' === grabenquerung['graben-bewertung']"
                  color="green"
                  mangel-amount-mode
                  value="1"
                  pdf-mode
                />
                <PdfChip
                  v-if="'o' === grabenquerung['graben-bewertung']"
                  color="blue"
                  mangel-amount-mode
                  value="1"
                  pdf-mode
                />
                <PdfChip
                  v-if="'minus' === grabenquerung['graben-bewertung']"
                  color="yellow"
                  mangel-amount-mode
                  value="1"
                  pdf-mode
                />
                <PdfChip
                  v-if="'x' === grabenquerung['graben-bewertung']"
                  color="red"
                  mangel-amount-mode
                  value="1"
                  pdf-mode
                />
              </div>
              <oneto-five-image-div
                :ba="null"
                :immobilie="null"
                fragesPath="null"
                :lang="lang"
                :imageObjects="grabenquerung['graben-bilder']"
                class="ml-2 col-span-8"
              >
              </oneto-five-image-div>
            </div>
          </div>
          <div v-else>
            <div
              class="mt-2 grid grid-cols-8 justify-items-stretch items-center"
            >
              <div
                class="justify-center items-center col-span-8"
                style="
                  background-color: rgb(235, 239, 240);
                  border-style: solid;
                  border-color: rgb(235, 239, 240) rgb(235, 239, 240)
                    rgb(235, 239, 240) rgb(235, 239, 240);
                  border-radius: 5px;
                "
              >
                <p class="text-xs text-center">
                  Keine Grabenquerungen aufgenommen
                </p>
              </div>
            </div>
          </div>
            </div>
             <Seilzugflaechen-footer
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class=""
            />
        </div>
      </div>
    </pdf-a-4-page>  
  <!-- ###################### -->
  <!-- Seilzugfläche Ökologie Page und Bilder -->
   <pdf-a4-page no-margin :pdf-key="lang" class="pdf-content" v-if="
      seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.zuwegung-vorhanden')?.getCurrentInput() ||
      seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.zuwegung-notwendig')?.getCurrentInput() "
    >
      <div class="landingpage-background w-screen h-screen">
        <div class="flex flex-col justify-between" style="height:1020px">
          <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>
            <div class="h-full max-h-full">
            <!-- Ökologie Start-->
            <div class="mt-4 text-lg md:text-lg">Ökologie (Zuwegung)</div>

              <div
                class="grid grid-cols-8 justify-items-stretch items-center gap-y-3"
              >
                <div
                  class="col-span-2 text-sm font-bold"
                  style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
                >
                  Frage
                </div>
                <div
                  class="text-sm font-bold col-span-5"
                  style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
                >
                  Beschreibung/Eingabewerte
                </div>
                <div
                  class="col-span- text-sm font-bold"
                  style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
                >
                  Bewertung
                </div>


                <div class="text-xs col-span-2">Ampelkarte</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.oekologie-group.ampelkarte')?.getSelectedAuswahlLabelsString() }}</div>
                 
                 <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="zuwegung-seilzugflaeche-modal.oekologie-group.ampelkarte-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>

                <div class="text-xs col-span-2">FFH-Gebiet</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.oekologie-group.ffh-gebiet')?.getSelectedAuswahlLabelsString() }}</div>

                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="zuwegung-seilzugflaeche-modal.oekologie-group.ffh-gebiet-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>

                <div class="text-xs col-span-2">Biotop</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.oekologie-group.biotop')?.getSelectedAuswahlLabelsString() }}</div>
                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="zuwegung-seilzugflaeche-modal.oekologie-group.biotop-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>


                <div class="text-xs col-span-2">Vogelschutzgebiet</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.oekologie-group.vogelschutzgebiet')?.getSelectedAuswahlLabelsString() }}</div>

                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="zuwegung-seilzugflaeche-modal.oekologie-group.vogelschutzgebiet-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>

                <!-- <div class="text-xs col-span-2">Bodenschutzgebiet</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'seilzugflaeche-modal.oekologie-group.bodenschutzgebiet')?.getCurrentInput() }}</div>

                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="seilzugflaeche-modal.oekologie-group.bodenschutzgebiet-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip> -->

                <div class="text-xs col-span-2">Wasserschutzgebiet</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.oekologie-group.wasserschutzgebiet')?.getSelectedAuswahlLabelsString()}}</div>


                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="zuwegung-seilzugflaeche-modal.oekologie-group.wasserschutzgebiet-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>

              
                <div class="text-xs col-span-2">Sonstiges</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.oekologie-group.sonstiges')?.getCurrentInput() }}</div>

                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="zuwegung-seilzugflaeche-modal.oekologie-group.sonstiges-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>

              </div>
            <br>
            <div class="mt-4 text-lg md:text-lg">Allgemeine Bilder Zuwegung</div>
                <div class="grid grid-cols-8 justify-items-stretch items-center gap-y-3">
                  <div class="col-span-8 text-sm font-bold" style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;">                  
                  </div>
                <oneto-five-image-div-mehrfach
                  :fragenblock="seilzugflaeche"
                  :ba="ba"
                  :immobilie="immobilie"
                  fragesPath="zuwegung-seilzugflaeche-modal.bilder-group.bilder"
                  :lang="lang"
                  class="col-span-8 ml-2"
                >
                </oneto-five-image-div-mehrfach>
              </div>

            <br>
            </div>
            <Seilzugflaechen-footer
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class=""
            />
        </div>
      </div>
    </pdf-a4-page> 
  <span>


  <!-- ###################### -->
  <!-- Seilzugfläche Allgemein Page -->
    <pdf-a4-page no-margin :pdf-key="lang" class="pdf-content" v-if="seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.flaeche-vorhanden')?.getCurrentInput() ||
      seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.flaeche-notwendig')?.getCurrentInput() "
      >
    <div class="landingpage-background w-screen h-screen">
      <div class="flex flex-col justify-between" style="height:1020px">
        <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>

        <div class="text-xl md:text-xl font-bold">Fläche zwischen Mast und Seilzugfläche</div>
          <!-- Seilzugfläche {{ szfKey + 1 }} ({{ seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.typ')?.getSelectedAuswahlLabelsString()  }})</div> -->
        <div class="content h-full max-h-full">

          <!-- Allgemeine Punkte Start-->
          <div class="grid grid-cols-8 justify-items-stretch items-center gap-y-3">
            <div class="text-lg md:text-lg col-span-8">Allgemeine Punkte (Fläche zwischen Mast und Seilzugfläche)</div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              Frage
            </div>
            <div
              class="text-sm font-bold col-span-3"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Beschreibung/Eingabewerte
            </div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Weitere Eingaben
            </div>
            <div
              class="col-span- text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Bewertung
            </div>
            <!-- Nutzungsart start-->
            <div class="text-xs col-span-2">Nutzungsarten der Fläche:</div>
            <div class="text-xs text-gray-500 col-span-5">
              <ul
                v-for="(v, key) in seilzugflaeche.findFrageByRelativePath(ba,
                  'seilzugflaeche-modal.allgemein.nutzungsart-der-flaeche'
                )['eingabeMehrfachAuswahlWerts']"
                :key="key"
                class=""
              >
                <li :key="key" class="text-xs text-gray-500">{{ v }}</li>
              </ul>
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.allgemein.nutzungsart-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>

            <!-- Nutzungsart Ende-->
            <!-- Fremdobjekte start-->

            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="seilzugflaeche-modal.allgemein.fremdobjekte-vorhanden"
              label="Fremdobjekte auf Fläche"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-3">
              {{seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.allgemein.fremdobjekte-auf-flaeche')['eingabeText']}} 
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.allgemein.weitere-fremdobjekte-auf-flaeche')['eingabeText']}} 
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.allgemein.fremdobjekte-auf-flaeche-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>

            <!-- Fremdobjekte Ende-->
            <!-- Sonstiges start-->

            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="seilzugflaeche-modal.allgemein.sonstiges-vorhanden"
              label="Sonstiges"
              class="col-span-2"
            />
            <div class="text-xs col-span-4 text-gray-500">
              {{seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.allgemein.sonstiges-beschreibung')['eingabeText']}}
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="seilzugflaeche-modal.allgemein.sonstiges-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end col-span-2"
            ></bewertung-chip>
            <!-- Sonstiges end-->

            <!-- Flurstücke start-->
            <div class=" col-span-2 text-sm font-bold" style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;">Analyse </div>
            <div class=" text-sm font-bold col-span-6" style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;">Analyseergebnisse</div>

            <div class="text-xs md:text-xs col-span-2 ">Betroffene Flurstücke:</div>
            <div class="text-xs text-gray-500 col-span-6 grid grid-cols-3">
              <div v-for="(v,key) in seilzugflaeche.findFrageByRelativePath(ba,'seilzugflaeche-modal.allgemein.betroffeneflurstuecke').getCurrentInput()?.split(';')?.sort()" :key="key"
                class="text-xs text-gray-500"
                >
                      {{v}}
              </div>
            </div>
            
          </div>

          <!-- Allgemeine Punkte Ende-->
        </div>

        <Seilzugflaechen-footer
          :ba="ba"
          :immobilie="immobilie"
          :lang="lang"
          class=""
        />
      </div>
    </div>
    </pdf-a4-page>
  <!-- ###################### -->
  <!--  Fläche zwischen Mast und Seilzugfläche Geländebeschaffenheit Page  -->
    <pdf-a-4-page no-margin :pdf-key="lang" class="pdf-content" v-if="seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.flaeche-vorhanden')?.getCurrentInput() ||
      seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.flaeche-notwendig')?.getCurrentInput() "
      >
       <div class="landingpage-background w-screen h-screen">
        <div class="flex flex-col justify-between" style="height:1020px">
          <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>
            <div class="h-full max-h-full">
            <!-- Geländebeschaffenheit Start-->
            <div class="text-lg md:text-lg col-span-8">Geländebeschaffenheit (Fläche zwischen Mast und Seilzugfläche)</div>

                      <div
            class="mt-0 grid grid-cols-8 justify-items-stretch items-center gap-y-3"
          >
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              Frage
            </div>
            <div
              class="text-sm font-bold col-span-3"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Beschreibung/Eingabewerte
            </div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Weitere Eingaben
            </div>
            <div
              class="col-span- text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Bewertung
            </div>

            
            <!-- Ausholzung start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.ausholzung-group.ausholzung-erforderlich"
              label="Ausholzung erforderlich"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-3">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'flaeche-zwischen-mast-und-seilzugflaeche-modal.ausholzung-group.ausholzung-art')?.getSelectedAuswahlLabelsString()  }}
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{seilzugflaeche.findFrageByRelativePath(ba,'flaeche-zwischen-mast-und-seilzugflaeche-modal.ausholzung-group.ausholzung-beschreibung')['eingabeText']}}
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.ausholzung-group.ausholzung-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <!-- todo images Ausholzung -->
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.ausholzung-group.ausholzung-bilder"
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>
            <!-- Ausholzung Ende-->


            <!-- Rodung start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.rodung-group.rodung-erforderlich"
              label="Rodung erforderlich"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-3">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'flaeche-zwischen-mast-und-seilzugflaeche-modal.rodung-group.rodung-anzahl-baeume')['eingabeText'] }}
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{seilzugflaeche.findFrageByRelativePath(ba,'flaeche-zwischen-mast-und-seilzugflaeche-modal.rodung-group.rodung-beschreibung')['eingabeText']}}
            </div>
            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.rodung-group.rodung-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <!-- todo images Rodung -->
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath=""
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>
            <!-- Rodung Ende-->



            <!-- Feuchtgebiete start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.feuchtgebiete-group.graben-vorhanden"
              label="Feuchtgebiete vorhanden"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-5">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'flaeche-zwischen-mast-und-seilzugflaeche-modal.feuchtgebiete-group.feuchtgebiete-beschreibung')['eingabeText'] }}
            </div>

            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.feuchtgebiete-group.feuchtgebiete-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <!-- todo images Feuchtgebiete -->
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.feuchtgebiete-group.feuchtgebiete-bilder"
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>
            <!-- Feuchtgebiete Ende-->

            <!-- Naturdenkmäler start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.naturdenkmaeler-group.naturdenkmaeler-vorhanden"
              label="Naturdenkmäler vorhanden"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-5">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'flaeche-zwischen-mast-und-seilzugflaeche-modal.naturdenkmaeler-group.naturdenkmaeler-beschreibung')['eingabeText'] }}
            </div>

            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.naturdenkmaeler-group.naturdenkmaeler-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <!-- todo images Naturdenkmäler -->
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath=""
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>
            <!-- Naturdenkmäler Ende-->


            <!-- Kampfmittel start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.kampfmittel-group.kampfmittel-vorhanden"
              label="Kampfmittel vorhanden"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-1">
              <template v-if="seilzugflaeche.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.kampfmittel-group.kampfmittel-freimessung')?.getCurrentInput()">
                Freimessung erforderlich
              </template>
              <template v-else>
                Freimessung nicht erforderlich
              </template>
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'flaeche-zwischen-mast-und-seilzugflaeche-modal.kampfmittel-group.kampfmittel-art')['eingabeText'] }}
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'flaeche-zwischen-mast-und-seilzugflaeche-modal.kampfmittel-group.kampfmittel-beschreibung')['eingabeText'] }}
            </div>

            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.kampfmittel-group.kampfmittel-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <!-- todo images Kampfmittel -->
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath=""
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>
            <!-- Kampfmittel Ende-->


            <!-- Sonstiges start-->
            <is-vorhanden-char-and-label
              :fragenblock="seilzugflaeche"
              :ba="ba"
              fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.sonstiges-group.sonstiges-vorhanden"
              label="Sonstiges vorhanden"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-5">
              {{ seilzugflaeche.findFrageByRelativePath(ba,'flaeche-zwischen-mast-und-seilzugflaeche-modal.sonstiges-group.sonstiges-beschreibung')['eingabeText'] }}
            </div>

            <bewertung-chip
              :fragenblock="seilzugflaeche"
              fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.sonstiges-group.sonstiges-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            
            <!-- todo images Sonstiges -->
            <oneto-five-image-div-mehrfach
              :fragenblock="seilzugflaeche"
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.sonstiges-group.sonstiges-bilder"
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div-mehrfach>
            <!-- Sonstiges Ende-->

          </div>
            </div>
             <Seilzugflaechen-footer
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class=""
            />
        </div>
      </div>
    </pdf-a-4-page>           

  <!-- ###################### -->
  <!-- Ausholzungsfläche Ökologie Page und Bilder -->
    <pdf-a-4-page no-margin :pdf-key="lang" class="pdf-content" v-if="
      seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.flaeche-vorhanden')?.getCurrentInput() ||
      seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.flaeche-notwendig')?.getCurrentInput() "
      >
      <div class="landingpage-background w-screen h-screen">
        <div class="flex flex-col justify-between" style="height:1020px">
          <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>
            <div class="h-full max-h-full">
            <!-- Ökologie Start-->
            <div class="mt-4 text-lg md:text-lg">Ökologie (Fläche zwischen Mast und Seilzugfläche)</div>
              <div
                class="grid grid-cols-8 justify-items-stretch items-center gap-y-3"
              >
                <div
                  class="col-span-2 text-sm font-bold"
                  style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
                >
                  Frage
                </div>
                <div
                  class="text-sm font-bold col-span-5"
                  style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
                >
                  Beschreibung/Eingabewerte
                </div>
                <div
                  class="col-span- text-sm font-bold"
                  style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
                >
                  Bewertung
                </div>


                <div class="text-xs col-span-2">Ampelkarte</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.ampelkarte')?.getSelectedAuswahlLabelsString() }}</div>
                 
                 <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.ampelkarte-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>

                <div class="text-xs col-span-2">FFH-Gebiet</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.ffh-gebiet')?.getSelectedAuswahlLabelsString() }}</div>

                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.ffh-gebiet-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>

                <div class="text-xs col-span-2">Biotop</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.biotop')?.getSelectedAuswahlLabelsString() }}</div>
                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.biotop-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>


                <div class="text-xs col-span-2">Vogelschutzgebiet</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.vogelschutzgebiet')?.getSelectedAuswahlLabelsString() }}</div>

                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.vogelschutzgebiet-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>

                <div class="text-xs col-span-2">Wasserschutzgebiet</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.wasserschutzgebiet')?.getSelectedAuswahlLabelsString() }}</div>


                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.wasserschutzgebiet-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>

              
                <div class="text-xs col-span-2">Sonstiges</div>
                <div class="col-span-5 text-xs">{{ seilzugflaeche.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.sonstiges')?.getCurrentInput() }}</div>

                  <bewertung-chip
                    :fragenblock="seilzugflaeche"
                    fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.sonstiges-bewertung"
                    :ba="ba"
                    :immobilie="immobilie"
                    :lang="lang"
                    class="justify-self-end"
                  ></bewertung-chip>

              </div>
            <br>
            <div class="mt-4 text-lg md:text-lg">Allgemeine Bilder Fläche zwischen Mast und Seilzugfläche</div>
                <div class="grid grid-cols-8 justify-items-stretch items-center gap-y-3">
                  <div class="col-span-8 text-sm font-bold" style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;">                  
                  </div>
                <oneto-five-image-div-mehrfach
                  :fragenblock="seilzugflaeche"
                  :ba="ba"
                  :immobilie="immobilie"
                  fragesPath="flaeche-zwischen-mast-und-seilzugflaeche-modal.bilder-group.bilder"
                  :lang="lang"
                  class="col-span-8 ml-2"
                >
                </oneto-five-image-div-mehrfach>
              </div>

            <br>
            </div>
            <Seilzugflaechen-footer
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class=""
            />
        </div>
      </div>
    </pdf-a-4-page>




      <!-- Grabenquerungen Pages -->
      <pdf-a4-page no-margin :pdf-key="lang" class="pdf-content" v-for="(grabenquerungenPage, gqPageKey) in seilzugflaeche['grabenquerungenPages']" :key="gqPageKey">
      <div class="landingpage-background w-screen h-screen">
        <div class="flex flex-col justify-between" style="height:1020px">
          <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>

          <div class="text-xl md:text-xl font-bold">Grabenquerungen ({{ gqPageKey + 1 }}) von Seilzugfläche {{ szfKey + 1 }}</div>
          <div class="content h-full max-h-full">
          
            <div v-for="(grabenquerung, gqKey) in grabenquerungenPage['grabenquerungen']" :key="gqKey">
              <div class="text-l md:text-l font-bold">Grabenquerung {{ (gqPageKey)*2 + gqKey+1 }}</div> <!--the *2 is hardcoded so if this value changes too much implement somthing smarter-->
              
              Grabenquerung erforderlich: {{ grabenquerung['erforderlich'] }}
              Anzahl: {{ grabenquerung['anzahl'] }}
              Graben Beschreibung: {{ grabenquerung['beschreibung'] }}
              Bewertung Graben: {{ grabenquerung['bewertung'] }}
              <UniversalPdfImageRow
                :image-objects="grabenquerung['bilder']"
              />
              <PdfChip
                v-if="'plus' === grabenquerung['bewertung']"
                color="green"
                mangel-amount-mode
                value="1"
                pdf-mode
              />
              <PdfChip
                v-if="'o' === grabenquerung['bewertung']"
                color="blue"
                mangel-amount-mode
                value="1"
                pdf-mode
              />
              <PdfChip
                v-if="'minus' === grabenquerung['bewertung']"
                color="yellow"
                mangel-amount-mode
                value="1"
                pdf-mode
              />
              <PdfChip
                v-if="'x' === grabenquerung['bewertung']"
                color="red"
                mangel-amount-mode
                value="1"
                pdf-mode
              />

              <br/>
            </div>

          </div>

          <Seilzugflaechen-footer
            :ba="ba"
            :immobilie="immobilie"
            :lang="lang"
            class=""
          />
        </div>
      </div>
      </pdf-a4-page>
    </span>
  </span>
</template>

<script lang="ts">
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useIdentifierFrageFinder,getAuswahlLabel } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import { getPhotoUrl } from '@/utilities/get-media-url';
import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';

import PdfChip from '../Components/PdfChip.vue';
import { useSeilzugflaechen } from './useSeilzugflaechen';
import UniversalPdfImageRow from '../Components/UniversalPdfImageRow.vue';
import IsVorhandenCharAndLabel from './isVorhandenCharAndLabelMehrfach.vue';
import OnetoFiveImageDivMehrfach from './OnetoFiveImageDivMehrfach.vue';
import OnetoFiveImageDiv from './OnetoFiveImageDiv.vue';

import TngHeader from './TngHeader.vue';
import SeilzugflaechenFooter from './SeilzugflaechenFooter.vue';
import BewertungChip from './BewertungChipMehrfach.vue';

export default defineComponent( {
  name: 'Seilzugflaechen',
  components: {
    PdfA4Page,
    PdfChip,
    UniversalPdfImageRow,
    IsVorhandenCharAndLabel,
    TngHeader,
    OnetoFiveImageDivMehrfach,
    OnetoFiveImageDiv,
    BewertungChip,
    SeilzugflaechenFooter
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup ( props: any ) {
    const { t } = useI18n( { useScope: 'global' } );
    const uUser = useUser();
    const { getSeilzugflaechenWrappers } = useSeilzugflaechen();
    const { getSeilzugflaechen, getGrabenquerungen } = useSeilzugflaechen();
    return {
      user: uUser.user,
      t,
      useIdentifierFrageFinder,
      moment,
      getPhotoUrl,
      getSeilzugflaechenWrappers,
      getSeilzugflaechen,
      getAuswahlLabel,
      getGrabenquerungen
    };
  },
} );
</script>
<style scoped>
* {
  color: rgb(23,68,81);
  font-family: 'Avenir-Next-LT-Pro-Regular';
}

.pdf-content {
  width: 800px;
  margin: 0 auto;
  color: white;
}

.landingpage-background {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 700px;
  height: 1050px;
  color: black;
}

.trenner {
  height: 1px;
  border-width: 0;
  color: black;
  background-color: black;
}

.content {
  height: 800px;
}
</style>
