<template>
  <ion-content
    v-if="user"
    class="ion-padding"
    style="background: white"
  >
    <div data-cy="user-name">
      {{ user.username }}
    </div>
    <div
      class="mb-4"
      data-cy="user-email"
    >
      <strong>{{ user.email }}</strong>
    </div>
    <div class="flex flex-col">
      <AButton
        data-cy="button-faq"
        :btn-primary="true"
        class="mb-2"
        :expand="true"
        @click="goToFaq"
      >
        {{ t('toolbar.hilfe') }}
      </AButton>
      <AButton
        data-cy="button-impressum"
        :btn-primary="true"
        class="mb-2"
        :expand="true"
        @click="goToImpressum"
      >
        {{ t('toolbar.impressum') }}
      </AButton>

      <AButton
        data-cy="button-impressum"
        :btn-primary="true"
        class="mb-2"
        :expand="true"
        @click="goToSettings"
      >
        {{ t('toolbar.settings') }}
      </AButton>

      <AButton
        data-cy="button-logout"
        :btn-primary="true"
        class="mb-2"
        :expand="true"
        @click="logout"
      >
        {{ t('toolbar.ausloggen') }}
      </AButton>
    </div>
  </ion-content>
</template>

<script>
import { defineComponent } from 'vue';
import { IonContent, IonItem, popoverController, useIonRouter } from '@ionic/vue';
import { useI18n } from 'vue-i18n';
//Composables
import useUser from "@/composables/useUser";
import useLogoutAlert from "@/composables/User/useLogoutAlert";
//Components
import AButton from "@/components/Base/AButton";

export default defineComponent({
  name: 'UserPopover',
  components: {
    IonContent,
    IonItem,
    AButton,
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const { user } = useUser();
    const router = useIonRouter();

    function dismiss() {
      popoverController.dismiss();
    }


    const logout = async () => {
      await useLogoutAlert(router, t);

      dismiss();
    }

    function goToSettings() {
      router.navigate('/settings', 'forward', 'push')
      dismiss();
    }

    function goToFaq() {
      router.navigate('/faq', 'forward', 'push')
      dismiss();
    }

    function goToImpressum() {
      router.navigate('/impressum', 'forward', 'push')
      dismiss();
    }

    return {
      logout,
      dismiss,
      user,
      goToSettings,
      goToFaq,
      goToImpressum,
      t
    }
  }
});
</script>

<style scoped>

ion-content {
  --background: var(--white100);
}
</style>