<template>
  <section
    class="pdf-content"
  >
    <pdf-a4-page
      v-for="(fragenblock, key) in dummyData.fragenblocks"
      :key="key"
      :pdf-key="lang"
    >
      <pdf-acon-logo />

      <h1 class="pdf-title">
        {{ t("immobilie.pdf.kopfzeile", 1, { locale: lang}) }}
      </h1>

      <pdf-general-data
        class="my-4 pb-2 border-bottom-1"
        :hzba="hzba"
        :immobilie="immobilie"
        :lang="lang"
      />

      <div class="flex justify-between mt-8">
        <h2 class="my-1">
          {{ fragenblock.titel }}
        </h2>
        <div>
          <span class="text-xs mr-2">Maluswert</span>
          <PdfChip
            class="my-1"
            :color="malusColors[fragenblock.malusColor]"
            :value="fragenblock.malus"
            pdf-mode
          />
        </div>
      </div>

      <div class="flex justify-between">
        <table class="my-2">
          <tr class="border-bottom-1">
            <th class="text-left pl-2 py-2 font-normal text-gray-500">
              Bezeichnung
            </th>
            <th class="text-left pl-2 py-2 font-normal text-gray-500">
              Wert
            </th>
          </tr>

          <tr
            v-for="(entry, key) in fragenblock.frages"
            :key="key"
            class="data-tr items-center border-bottom-1"
          >
            <td
              style="min-width: 80px; max-width: 220px;"
              class="text-left pl-2 py-2"
            >
              {{ entry.titel }}
            </td>
            <td
              style="min-width: 80px; max-width: 220px;"
              class="text-left pl-2 py-2"
            >
              {{ entry.value }}
            </td>
          </tr>
        </table>
        <table class="my-2 mr-2">
          <tr class="border-bottom-1">
            <th class="text-left pl-2 py-2 font-normal text-gray-500">
              Bezeichnung
            </th>
            <th class="text-left pl-2 py-2 font-normal text-gray-500">
              Wert
            </th>
          </tr>

          <tr
            v-for="(entry, key) in fragenblock.frages"
            :key="key"
            class="data-tr items-center border-bottom-1"
          >
            <td
              style="min-width: 80px; max-width: 220px;"
              class="text-left pl-2 py-2 min-h-fit"
            >
              {{ entry.titel }}
            </td>
            <td
              style="min-width: 80px; max-width: 220px;"
              class="text-left pl-2 py-2 min-h-fit"
            >
              {{ entry.value }}
            </td>
          </tr>
        </table>
      </div>

      <div class="my-8">
        <p class="mb-0 font-bold text-xs">
          Zusammenfassung
        </p>
        <p class="my-1 text-xs">
          Zur Erfassung und Klassifizierung von Mängeln werden vorrangig gesetzliche sowie normative Bestimmungen hinsichtlich Sicherheitsanforderungen zugrunde gelegt. Darüberhinausgehend wird der Gesamteindruck des Technikraumes, wie eine ausreichende Beleuchtung oder bauliche Mängel, berücksichtigt..
        </p>

        <p class="mb-0 font-bold text-xs">
          Besonderheiten
        </p>
        <p class="my-1 text-xs">
          Pumpensumpf pumpt in Ausguss im U1.
        </p>

        <p class="mb-0 font-bold text-xs">
          Mängel
        </p>
        <p class="my-1 text-xs">
          Teilweise fehlende Brandschutzdurchführung der SML Leitungen durch Decken z.B. Technikzentrale
        </p>
        <p class="mb-0 font-bold text-xs">
          Fotos
        </p>
        <img
          src=""
          alt=""
        >
      </div>

      <div class="my-16 border-top-1">
        <div class="mangel-cat my-4 flex flex-wrap">
          <div class="flex flex-nowrap items-center">
            <span class="font-bold text-center">Mangelkategorien:</span>
          </div>
          <div class="flex flex-nowrap items-center mx-1">
            <PdfChip
              color="green"
              pdf-mode
            />
            Keine Mängel
          </div>

          <div class="flex flex-nowrap items-center mx-1">
            <PdfChip
              color="blue"
              pdf-mode
            />
            Keine relevanten Mängel
          </div>

          <div class="flex flex-nowrap items-center mx-1">
            <PdfChip
              color="yellow"
              pdf-mode
            />
            Sicherheitsrelevante Mängel
          </div>

          <div class="flex flex-nowrap items-center">
            <PdfChip
              color="red"
              pdf-mode
            />
            Versorgungsrelevante Mängel
          </div>
        </div>
      </div>
    </pdf-a4-page>
  </section>
</template>

<script lang="ts">
import { getPhotoUrl } from "@/utilities/get-media-url";
import moment from "moment";
import { useI18n } from 'vue-i18n';

import PdfA4Page from "@/components/Pdf/Components/PdfA4Page.vue";
import PdfAconLogo from "@/components/Pdf/Components/PdfAconLogo.vue";
import PdfAppointmentTable from "@/components/Pdf/Components/PdfAppointmentTable.vue";
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";
import PdfColorCodes from "@/components/Pdf/Components/PdfColorCodes.vue";
import PdfDataSectionSummary from "@/components/Pdf/Components/PdfDataSectionSummary.vue";
import PdfDataTable from "@/components/Pdf/Components/PdfDataTable.vue";
import PdfGeneralData from "@/components/Pdf/Components/PdfGeneralData.vue";
import PdfImageRow from "@/components/Pdf/Components/PdfImageRow.vue";
import GenericPdfDataTable from "@/components/Pdf/Components/PdfMSEDataTable.vue";
import PdfMSEValues from "@/components/Pdf/Components/PdfMSEValues.vue";
import PdfPageHeader from "@/components/Pdf/Components/PdfPageHeader.vue";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { Fragenblock } from "@/models/ba/Fragenblock";
import Immobilie from "@/models/immobilie.model";
import { computed, defineComponent, PropType } from "vue";
import GenericPdfMgtSummaryTable from '../Components/GenericPdfMgtSummaryTable.vue';



export default defineComponent({
  name: "PdfTechnikzentraleTemplate",
  components: {
    PdfAppointmentTable,
    PdfGeneralData,
    PdfDataTable,
    GenericPdfDataTable,
    PdfColorCodes,
    PdfMSEValues, PdfImageRow, PdfDataSectionSummary, PdfA4Page, PdfPageHeader, PdfAconLogo,
    GenericPdfMgtSummaryTable,
    PdfChip,
},
  props: {
    hzba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true
    },
    // genericHzba: {
    //   type: Object as PropType<Bestandsaufnahme>,
    //   required: true
    // },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true
    },
    lang: {
      type: String,
      default: 'de'
    }
  },
  setup(props: any) {
    const store = useStore();
    const { t } = useI18n({ useScope: 'global' })
    const maxPages = 7;

    const uUser = useUser();
    const dummyData = {
    fragenblocks:[
      {
        titel:'Abwasser-Installation',
        malus:'5',
        malusColor:'green',
        frages:[{
        titel:"Entwässerung",
        value:"Mischentwässerung",
      },
      {
        titel:"Dämmung",
        value:"Ja",
      },
      {
        titel:"Leitungsmaterial",
        value:"SML, PP, HT ",
      },
      {
        titel:"Hebeanlage",
        value:"Fäkalienhebeanlage Doppelpumpe mit Pumpensumpf im U2",
      }]
      },
      {
        titel:'Technikraum',
        malus:'10',
        malusColor:'yellow',
        frages:[{
        titel:"Entwässerung",
        value:"Mischentwässerung",
      },
      {
        titel:"Dämmung",
        value:"Ja",
      },
      {
        titel:"Leitungsmaterial",
        value:"SML, PP, HT ",
      },
      {
        titel:"Hebeanlage",
        value:"Fäkalienhebeanlage Doppelpumpe mit Pumpensumpf im U2",
      }]
      },
      {
        titel:'Trinkwasser Installation',
        malus:'15',
        malusColor:'red',
        frages:[{
        titel:"Entwässerung",
        value:"Mischentwässerung",
      },
      {
        titel:"Dämmung",
        value:"Ja",
      },
      {
        titel:"Leitungsmaterial",
        value:"SML, PP, HT ",
      },
      {
        titel:"Hebeanlage",
        value:"Fäkalienhebeanlage Doppelpumpe mit Pumpensumpf im U2",
      }]
      }
    ]
    }


    // const hzba = new Bestandsaufnahme(useNewHzbaJson().data);
    // console.log('hzba mocked',hzba)

    const genericHzbaFromStore = computed<Bestandsaufnahme | undefined>(() => {
      console.log('store.state.currentHzba',store.state)
      return store.state.currentHzba.currentBa;
    });

    const fragenBlocks = computed<Fragenblock[] | undefined>(() => {
      console.log('typeof hzba.value?.fragenBlocks', genericHzbaFromStore.value?.fragenblocks)
      return genericHzbaFromStore.value?.fragenblocks
    });

    const malusColors = computed(() => {
      return store.getters["app/malusColors"];
    });

    function techData(){
      if(!fragenBlocks.value) return
      const copyOfFragenBlocks = [...fragenBlocks.value]
      console.log('copyOfFragenBlocks',copyOfFragenBlocks)
      copyOfFragenBlocks.shift()
      console.log('techdatatest',techData)
    return copyOfFragenBlocks
    }


    return {
      // hzba,
      // immobilie,
      dummyData,
      getPhotoUrl,
      techData,
      fragenBlocks,
      maxPages,
      moment,
      user: uUser.user,
      genericHzbaFromStore,
      malusColors,
      t
    }
  }
})
</script>

<style scoped lang="scss">

.pdf-content {
  width: 800px;
  margin: 0 auto;
}

table {
  font-size: 12px;
}
// tr {
//   border-bottom: 1px solid $grey-800 !important;
// }

// th {
//   font-size: 12px;
//   padding-bottom: 4px;
//   margin-left: 2px;
// }

.mangel-cat {
  font-size: 9px;
}

.data-tr {
  height: 22px;
}

.value-col {
  padding: 8px;
}

</style>
