<template>
  <div>
    <div class="">
      <hr class="trenner" />
      <div
        class="flex justify-between items-center h-7"
        data-cy="pdf-coverpage-header"
      >
        <div class="text-xs">Kategorie der Einschr&auml;nkung:</div>
        <div class="">
          <PdfChip color="green" mangel-amount-mode value="keine" pdf-mode />
        </div>
        <div>
          <PdfChip
            color="blue"
            mangel-amount-mode
            value="geringf&uuml;gige"
            pdf-mode
          />
        </div>
        <div>
          <PdfChip
            color="yellow"
            mangel-amount-mode
            value="massive"
            pdf-mode
          />
        </div>
        <div>
          <PdfChip
            color="red"
            mangel-amount-mode
            value="nicht realisierbar"
            pdf-mode
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

import PdfChip from '../Components/PdfChip.vue';
export default defineComponent({
  name: 'TNGSeilzugflaechenFooter',
  components: { PdfChip },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();
    return {
      user: uUser.user,
      t,
      moment,
    };
  },
});
</script>
<style scoped>
* {
  color: rgb(23,68,81);
  font-family: 'Avenir-Next-LT-Pro-Regular';
}
.trenner {
  height: 1px;
  border-width: 1;
  background-color: rgb(23,68,81);
}
</style>