<template>
  <div class="pdfViewerHeader">
    <ion-header>
      <ion-toolbar
        color="red"
        class="text-white flex items-end ml-background ion-no-border"
      >
        <div class="container flex items-end">
          <img
            src="../../../../public/assets/mlLogos/logo-white-font.png"
            alt="ml_icon"
            class="logo mr-4"
          >
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="viewerHolder">
      <div
        id="pdfViewer"
        ref="pdfViewerRef"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, ref } from 'vue';
import { IonHeader, IonToolbar, modalController } from '@ionic/vue';
import { useStore } from "@/composables/useTypedStore";
//Models
import StrapiMedia from '@/models/photo/strapi-media.model';
//Components
import WebViewer from '@pdftron/webviewer';
//Icons
import { closeSharp } from "ionicons/icons";
//Other
import useDownloadHelper from '@/composables/useDownloadHelper';
import { getPdfUrl } from '@/utilities/get-media-url';

export default defineComponent({
  name: 'PdfViewer',
  components: {
    IonHeader,
    IonToolbar,
  },
  props: {
    pdf: {
      type: Object as PropType<StrapiMedia>,
      required: true,
    },
  },
  setup() {
    const pdfViewerRef = ref()
    const store = useStore()
    const { downloadFile } = useDownloadHelper();


    return {
      pdfViewerRef,
      modalController,
      downloadFile
    }
  },
  async mounted() {
    await this.loadWebViewer()
  },
  methods: {
    async loadWebViewer() {
      const pdfUrl = await getPdfUrl(this.pdf)
      WebViewer({
        path: '/pdfTron',
        licenseKey: 'MovingLayers GmbH:PWS:MovingLayers::B+2:A2F08A234CC5EB0A8D4EF8FF374353A7F0845030028198B786980E5726BD',
        initialDoc: pdfUrl,
      }, this.pdfViewerRef)
      .then((instance: any) => {
        const { documentViewer, annotationManager } = instance.Core;

        instance.UI.updateElement('downloadButton', {
          onClick: () => this.downloadPdf(instance, documentViewer, annotationManager),
        });

        instance.UI.disableElements(['saveAsButton' ]);

        instance.UI.setHeaderItems((header: any) => {
          header.push({
              type: 'actionButton',
              img: closeSharp,
              onClick: async () => {
                const doc = documentViewer.getDocument();
                const xfdfString = await annotationManager.exportAnnotations();
                const data = await doc.getFileData({
                  xfdfString
                });
                const arr = new Uint8Array(data);
                const blob = new Blob([arr], { type: 'application/pdf' });
                this.modalController.dismiss({blob: blob, pdf: this.pdf})
              }
          });
        });
      });
    },

    async downloadPdf(instance: any, documentViewer: any, annotationManager: any) {
      const doc = await documentViewer.getDocument();
      const xfdfString = await annotationManager.exportAnnotations();
      const options = { xfdfString };

      const data = await doc.getFileData(options);
      const blob = new Blob([new Uint8Array(data)], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);

      this.downloadFile(blobUrl, this.pdf.name)

      instance.UI.closeElements(['menuOverlay']);
    }
  }
})
</script>

<style lang="scss">
.pdfViewerHeader {
  height: 100%;
  display: flex;
  flex-direction: column;

  .viewerHolder {
    flex-grow: 1;

    #pdfViewer {
      width: 100%;
      height: 100%;
    }
  }

  .logo {
    height: 36px;
    filter: brightness(0) invert(1);
  } 
}
</style>
