import { Storage } from "@ionic/storage"

export type CacheType = 'cachedAppConfig' | 'cachedUserProject' | 'cachedSurveyTemplates' | 'selectedSurveyTemplate' | 'selectedSurveyProperty' | 'offlineSurveys' | 'onlineSurveys'

const localStorage = new Storage({ name: 'movingLayersLocalStore'})
export const createLocalStorage = async () => {
  await localStorage.create()
}

export const ionicStorageSetItem = async (index: string, value: string) => {
  await localStorage.set(index, value);
}

export const ionicStorageGetItem = async (index: string): Promise<string> => {
  return await localStorage.get(index)
}

export const ionicStorageRemoveItem = async (index: string) => {
  await localStorage.remove(index)
}

export const ionicStorageClearAll = async () => {
  await localStorage.clear()
}

createLocalStorage()

export const setCache = async (cacheType: CacheType, value: string): Promise<void> => {
  await ionicStorageSetItem(`${cacheType}`, value)
}

export const getCache = async (cacheType: CacheType): Promise<string> => {
  return await ionicStorageGetItem(`${cacheType}`)
}

export const removeCache = async (cacheType: CacheType): Promise<void> => {
  await ionicStorageRemoveItem(`${cacheType}`)
}
