import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pdf-preview"
}
const _hoisted_2 = {
  key: 1,
  class: "hidden lg:block",
  style: {"position":"fixed","z-index":"999999","top":"72px","width":"100%"}
}
const _hoisted_3 = { class: "container w-full relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Vue3Html2Pdf = _resolveComponent("Vue3Html2Pdf")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_AButton = _resolveComponent("AButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.show)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_Vue3Html2Pdf, {
      ref: "pdfPreview",
      "show-layout": false,
      "float-layout": false,
      "enable-download": _ctx.controlValue.enableDownload,
      "preview-modal": _ctx.controlValue.previewModal,
      filename: _ctx.controlValue.filename,
      "paginate-elements-by-height": _ctx.controlValue.paginateElementsByHeight,
      "pdf-quality": _ctx.controlValue.pdfQuality,
      "pdf-format": _ctx.controlValue.pdfFormat,
      "pdf-orientation": _ctx.controlValue.pdfOrientation,
      "pdf-content-width": _ctx.controlValue.pdfContentWidth,
      "js-pdf": _ctx.controlValue.jsPdf,
      "manual-pagination": _ctx.controlValue.manualPagination,
      "html-to-pdf-options": _ctx.htmlToPdfOptions,
      onBeforeDownload: _cache[0] || (_cache[0] = ($event: any) => (_ctx.beforeDownload($event))),
      onHasDownloaded: _cache[1] || (_cache[1] = () => _ctx.afterDownload())
    }, {
      "pdf-content": _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["enable-download", "preview-modal", "filename", "paginate-elements-by-height", "pdf-quality", "pdf-format", "pdf-orientation", "pdf-content-width", "js-pdf", "manual-pagination", "html-to-pdf-options"]), [
      [_vShow, false]
    ]),
    (_ctx.show && !_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AButton, {
              "btn-primary": true,
              style: {"position":"absolute","right":"0"},
              onClick: _ctx.downloadPdf
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  size: "small",
                  icon: _ctx.canShare ? _ctx.shareSocialOutline : _ctx.downloadOutline
                }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_AButton, {
              "btn-primary": true,
              style: {"position":"absolute","left":"0"},
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.replace(_ctx.goBackUrl)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  color: _ctx.primary,
                  icon: _ctx.arrowBack
                }, null, 8, ["color", "icon"]),
                _createTextVNode(" " + _toDisplayString(_ctx.t("buttons.zurueck")), 1)
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}