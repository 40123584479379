<template>
  <div class="grid grid-cols-1 lg:p-0 sm:grid-cols-1 md:grid-cols-2 gap-5 lg:grid-cols-3 xl:grid-cols-4">
    <PropertyCard
      v-for="property in properties"
      :key="property.id"
      :property="property"
      class="m-0"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Property from "../../models/immobilie.model";
import PropertyCard from "./PropertyCard.vue";

export default defineComponent({
  name: "PropertyList",
  components: {
    PropertyCard,
  },
  props: {
    properties: {
      type: Object as PropType<Property[]>,
      required: true,
    },
  },
});
</script>