<template>
<svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>7648BE75-BA60-4D7D-919D-89474526879A</title>
    <g id="Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="1.1-Übersicht-Allgemein" transform="translate(-20.000000, -37.000000)" :stroke="isActive ? 'var(--secondary)' : 'var(--white100)'" stroke-width="2">
            <g id="Settings" transform="translate(20.000000, 37.000000)">
                <path d="M12.5,17.9743786 C9.47630139,17.9743786 7.02541867,15.5235251 7.02541867,12.4998626 C7.02541867,9.47620015 9.47630139,7.0253467 12.5,7.0253467 C15.5236986,7.0253467 17.9748561,9.47620015 17.9748561,12.4998626 C17.9748561,15.5235251 15.5236986,17.9743786 12.5,17.9743786 Z M24,15.0306621 L24,9.96906318 L21.5694474,9.96906318 C21.363948,9.23114227 21.0710839,8.530035 20.702119,7.87700522 L22.4213909,6.15775391 L18.8421845,2.57859028 L17.1231874,4.29784159 C16.4701498,3.92888114 15.7687594,3.63629523 15.0308297,3.43079827 L15.0308297,1 L9.96917031,1 L9.96917031,3.43079827 C9.23124059,3.63629523 8.53012494,3.92888114 7.87708736,4.29784159 L6.15781552,2.57859028 L2.57860914,6.15775391 L4.29788098,7.87700522 C3.92891612,8.530035 3.63605198,9.23114227 3.43055257,9.96906318 L1,9.96906318 L1,15.0306621 L3.43055257,15.0306621 C3.63605198,15.768583 3.92891612,16.4696903 4.29788098,17.1227201 L2.57860914,18.8419714 L6.15781552,22.4214097 L7.87708736,20.7021584 C8.53012494,21.0708441 9.23124059,21.3637048 9.96917031,21.5692017 L9.96917031,24 L15.0308297,24 L15.0308297,21.5692017 C15.7687594,21.3637048 16.4698751,21.0708441 17.1229126,20.7021584 L18.8421845,22.4214097 L22.4213909,18.8419714 L20.702119,17.1227201 C21.0710839,16.4696903 21.363948,15.768583 21.5694474,15.0306621 L24,15.0306621 Z" id="Stroke-1"></path>
            </g>
        </g>
    </g>
</svg>
</template>


<script>

export default {
  name: 'SettingsIcon',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>
