<template>
  <ion-card class="rounded-xl bg-white">
    <button
      class="accordion"
      data-cy="button-faq-item"
      @click="headerClicked"
    >
      <h3 class="mt-0 mb-0">
        {{ getCleanedFaq().frage }}
      </h3>
    </button>
    <div
      class="panel"
      data-cy="faq-item-antwort"
    >
      <Markdown :source="getCleanedFaq().antwort" />
    </div>
  </ion-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Markdown from "vue3-markdown-it";
import { IonCard } from "@ionic/vue";
import Faq from "@/models/faq.model";
import { strapiUrl } from "@/api/api";

export default defineComponent({
  name: "FaqItem",
  components: {
    IonCard,
    Markdown,
  },
  props: {
    faq: {
      type: Object as PropType<Faq>,
      required: true,
    },
  },
  setup(props) {
    const getCleanedFaq = () => {
      const cleanedFaq = props.faq;
      // NOTE: because strapi markdown adds the image url without the host, we need to concatenate every img url with the backend url
      cleanedFaq.antwort = cleanedFaq.antwort?.replace(
        /(!\[.*?\]\()(.+?)(\))/g,
        (whole: string, a: string, b: string, c: string) => {
          if (!b.startsWith("http")) {
            return a + strapiUrl + b + c;
          }
          return a + b + c;
        }
      );
      return cleanedFaq;
    };

    const headerClicked = (_event: any) => {
      _event.currentTarget.classList.toggle("active");
      /* Toggle close all panels, except on that was clicked */
      const allPanels: any = document.getElementsByClassName("panel");
      allPanels.forEach((panel: any) => {
        if (_event.currentTarget.nextElementSibling !== panel) {
          panel.style.maxHeight = null;
        }
        panel.previousElementSibling.classList.remove("active");
      });
      /* Toggle between hiding and showing the active panel */
      const panel = _event.currentTarget.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }

    return {
      getCleanedFaq,
      headerClicked
    };
  },
});
</script>

<style scoped>
.accordion {
  background-color: var(--white100);
  cursor: pointer;
  padding: 18px;
  margin: 2px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}
.active,
.accordion:hover {
  background-color: rgb(231, 231, 231);
}

.panel {
  padding: 0 18px;
  background-color: var(--white100);
  color: var(--black100);
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  font-size: 16px;
}

.panel >>> img {
  margin-top: 16px;
  display: block !important;
  max-width: 300px !important;
}
</style>