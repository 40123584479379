import { APhoto } from '@/models/photo/a-photo.model';
import { Model } from '@vuex-orm/core';


export interface Role {
    id: number;
    name: string;
    description: string;
    type: string;
}

export default class User extends Model {
    static entity = 'users';
    static primaryKey = 'id';

    static fields() {
        return {
            id: this.attr(this.number),
            username: this.attr(''),
            name: this.attr(''),
            email: this.attr(''),
            role: this.attr(null),
            createdAt: this.attr(''),
            updatedAt: this.attr(''),
            order: this.attr(null),
            profilbild: this.attr(null),
            jobbeschreibung: this.attr(''),
            organisation: this.attr({}),
        };
    }

    id!: number;
    username!: string;
    name!: string;
    email!: string;
    role!: Role;
    createdAt!: string;
    updatedAt!: string;
    order!: any; // TODO Order[];
    profilbild!: APhoto;
    organisation!: any;
    jobbeschreibung!: string;

    get organisationPrefix () {
        const organisationName = this.organisation?.name.toLowerCase();

        return !organisationName || organisationName.includes("movinglayers") ? "" : organisationName + ".";
    }
}
