<template>
  <div class="downloadSurveyButton" @click="$event.stopPropagation(); emit('onClick')">
    <div class="downloadSurveyButtonContent px-4 py-1.5">
      <div class="label text-sm font-medium">{{ t('downloadSurveyButton.download') }}</div>
      <div class="icon flex">
        <ion-spinner v-if="props.isDownloading" name="circular" color="light" />
        <ion-icon v-else class="md icon-small" :icon="arrowDownCircleOutline" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IonSpinner, IonIcon } from '@ionic/vue'
import { arrowDownCircleOutline } from "ionicons/icons"
import { useI18n } from 'vue-i18n';

//Emits
const emit = defineEmits(['onClick'])

//Props
const props = defineProps({
  isDownloading: { type: Boolean, required: false }
})

//Variables
const { t } = useI18n({ useScope: 'global' })
</script>

<style lang="scss">
.downloadSurveyButton {
  width: fit-content;

  .downloadSurveyButtonContent {
    border-radius: 99px;
    border: 1px solid rgb(81, 26, 63);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 6px;

    .icon {
      ion-spinner {
        width: 14px;
        height: 14px;
      }
    }
  }
}
</style>
