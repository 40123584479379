<template>
  <pdf-a4-page no-margin :pdf-key="lang" class="pdf-content">
    <div class="landingpage-background w-screen h-screen">
      <div class="flex flex-col justify-between" style="height: 1020px">
        <div class="flex justify-between">
          <div class="text-3xl md:text-3xl">Bericht zur Regelkontrolle</div>
        </div>
        <div>
          <img
            src="../../../../public/assets/img/pdf/baumkataster/preview.png"
            width="600"
          />
        </div>
        <div class="content">
          <div class="h-full max-h-full">To be clarified</div>
        </div>
      </div>
    </div>
  </pdf-a4-page>
</template>


<script lang="ts">
import useUser from "@/composables/useUser";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import Immobilie from "@/models/immobilie.model";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import moment from "moment";
import {
  useIdentifierFrageFinder,
  getAuswahlLabel,
} from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import { getPhotoUrl } from "@/utilities/get-media-url";
import PdfA4Page from "@/components/Pdf/Components/PdfA4Page.vue";

import PdfChip from "../Components/PdfChip.vue";

export default defineComponent({
  name: "CoverPage",
  components: {
    PdfA4Page,
    PdfChip,
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: "de",
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: "global" });
    const uUser = useUser();

    return {
      user: uUser.user,
      t,
      useIdentifierFrageFinder,
      moment,
      getPhotoUrl,
      getAuswahlLabel,
    };
  },
});
</script>

<style scoped>
* {
  color: rgb(23, 68, 81);
  font-family: var(--secondaryFont);
}
.pdf-content {
  width: 800px;
  margin: 0 auto;
  color: var(--white100);
}

.landingpage-background {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 700px;
  height: 1050px;
  color: var(--black100);
}
.trenner {
  height: 1px;
  border-width: 0;
  color: gray;
  background-color: gray;
}
.content {
  height: 800px;
}
</style>