<template>
  <div
    class="absolute bg-yellow-50 z-50 p-4 right-0 left-0 m-4"
    style="box-shadow: var(--black100) 4px 4px 7px 2px; border-radius: 5px;"
  >
    <strong>{{ frage.getTitle() }}</strong> <a-button @click="$emit('close')">
      Close
    </a-button>
    <div>
      <span>{{ frage.path }}</span>
    </div>
    <div class="mt-2 flex flex-wrap">
      <div class="debug-chips">
        <strong>Progress: </strong><span>{{ frage.getProgress() }}</span>
      </div>
      <div class="debug-chips">
        <strong>isValid: </strong><span>{{ frage.isValid() }}</span>
      </div>
      <div class="debug-chips">
        <strong>isRequired: </strong><span>{{ frage.isRequired() }}</span>
      </div>
      <div class="debug-chips">
        <strong>currentInput: </strong><span>{{ frage.getCurrentInput() }}</span>
      </div>
      <div class="debug-chips">
        <strong>computedDetectorSetup: </strong><span>{{ frage.isComputedDetectorSetup }}</span>
      </div>
      <div class="debug-chips">
        <strong>isShowDetectorSetup: </strong><span>{{ frage.isShowDetectorSetup }}</span>
      </div>
      <div class="debug-chips">
        <strong>mangels: </strong><span>{{ frage.mangels?.length }}</span>
      </div>
      <div class="debug-chips">
        <strong>mode: </strong>{{ frage.mode }}
      </div>
      <div class="debug-chips">
        <strong>print: </strong><a-button @click="() => frage.print()">
          print
        </a-button>
      </div>
    </div>

    <a-button
      class="mt-2"
      @click="showJson = showJson === 'frage' ? undefined : 'frage'"
    >
      {{ showJson === 'frage' ? 'Hide ' : 'Show' }} Frage Json
    </a-button>&nbsp;|&nbsp;
    <a-button
      class="mt-2"
      @click="showJson = showJson === 'mangeljson' ? undefined : 'mangeljson'"
    >
      {{ showJson === 'mangeljson' ? 'Hide ' : 'Show' }} Mangel Json
    </a-button>&nbsp;|&nbsp;
    <a-button
      class="mt-2"
      @click="showJson = showJson === 'mangel' ? undefined : 'mangel'"
    >
      {{ showJson === 'mangel' ? 'Hide ' : 'Show' }} Maengel Values
    </a-button>
    <pre
      v-if="showJson === 'frage'"
      style="max-height: 350px"
    >{{ frageJson }}</pre>
    <pre
      v-if="showJson === 'mangeljson'"
      style="max-height: 350px"
    >{{ frage.mangels?.map(el => el.toClassJson()) }}</pre>
    <div
      v-if="showJson === 'mangel'"
      style="max-height: 350px"
    >
      <div
        v-for="(m, key) in frage.mangels"
        :key="key"
        class="mt-2"
      >
        <div>{{ m.titel() }}</div>
        <div class="flex flex-wrap">
          <div class="debug-chips">
            <strong>isShowDetectorSetup: </strong>{{ m.isFireDetectorSetup }}
          </div>
          <div class="debug-chips">
            <strong>isFired: </strong>{{ m.isFired() }}
          </div>
          <div class="debug-chips">
            <strong>isTriggered: </strong>{{ m.isTriggered }}
          </div>
          <div class="debug-chips">
            <strong>mängel modal mode: </strong>{{ m.mode }}
          </div>

          <div class="debug-chips">
            <strong>currentLogicElement item path: </strong>{{ m.mangellogik?.currentPath }}
          </div>
          <div class="debug-chips">
            <strong>currentLogicElement found?: </strong>{{ !!m.mangellogik?.currentLogicElement }}
          </div>
          <div class="debug-chips">
            <strong>currentLogicElement current input: </strong>{{ !!m.mangellogik?.currentLogicElement?.getCurrentInput() }}
          </div>
          <div class="debug-chips">
            <strong>currentLogicElement modal mode: </strong>{{ m.mangellogik?.currentLogicElement?.mode || 'undefined' }}
          </div>
          <div class="debug-chips">
            <strong>print mangel: </strong><a-button @click="() => m.print()">
              print
            </a-button>
          </div>
          <div class="debug-chips">
            <strong>print currentLogicElement: </strong><a-button @click="() => m.mangellogik?.currentLogicElement?.print()">
              print
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref, watch} from "vue";
import AButton from "@/components/Base/AButton";
import {Frage} from "@/models/ba/Frage";

export default defineComponent({
  name: "DebugFrage",
  components: {AButton},
  props: {
    frage: {
      type: Object as PropType<Frage>,
      required: true,
    },

  },
  setup(props) {
    const showJson = ref();

    const frageJson = ref();
    watch(() => props.frage, async (newFrage) => {
      frageJson.value = await newFrage.toClassJson()
    }, { immediate: true })

    return {
      showJson,
      frageJson,
    }
  }
})
</script>

<style scoped>

</style>