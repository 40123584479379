import { setCache } from '@/store/storage/ionicStorage';

export const user = {
  namespaced: true,
  state: () => ({
    currentUserProject: {},
  }),
  mutations: {
    async storeCurrentUserProject(state: any, project: object) {
      state.currentUserProject = project
      await setCache('cachedUserProject', JSON.stringify(project))
    }
  }
};
