<template>
  <div>
    <div
      v-for="(frage, index) in formFrages"
      :key="index"
      :class="(frage.separator === undefined || frage.separator) && frage.type && frage.isShown() ? 'border-bottom-1-light' : ''"
      class="relative"
      :data-cy="`frage-${frage.identifier}`"
    >
      <hzba-choose-input
        v-if="frage.eingabeTyp?.toLowerCase() === 'boolean' && frage.isShown()"
        v-model="frage.eingabeBoolean"
        :data-cy="`input-choose-${frage.identifier}`"
        :errors="frage.errors(t)"
        :label="frage.getTitle()"
        :dependent-reason="frage.config?.beschreibung"
        :readonly="isReadonly( frage.config?.readOnly )"
        @updateSocket="handleSocketUpdate(frage)"
      />
  
      <hzba-textarea-input
        v-if="frage.eingabeTyp?.toLowerCase() === 'textblock' && frage.isShown()"
        v-model="frage.eingabeText"
        :data-cy="`input-textarea-${frage.identifier}`"
        :errors="frage.errors(t)"
        :label="frage.getTitle()"
        :type="frage.inputType"
        :placeholder="frage.platzhalter"
        :readonly="isReadonly( frage.config?.readOnly )"
        :hide-title="frage.hideTitle"
        :limit="frage.config?.maxSymbols"
        @updateSocket="handleSocketUpdate(frage)"
      />
  
      <hzba-photo-input
        v-if="frage.eingabeTyp?.toLowerCase() === 'medien' && frage.isShown()"
        v-model="frage.eingabeMedien"
        :errors="frage.errors(t)"
        :data-cy="`input-photo-${frage.identifier}`"
        class="flex py-2"
        :label="frage.getTitle()"
        :min-max="frage.config?.allowedNumberOfMedien"
        :min-max-label="frage.getPhotoMinMaxLabel(t)"
        :upload-type="frage.config?.mediaUploadType"
        :readonly="isReadonly( frage.config?.readOnly )"
        @updateSocket="handleSocketUpdate(frage)"
      />
  
      <hzba-select-input
        v-if="frage.eingabeTyp?.toLowerCase() === 'auswahl' && frage.isShown()"
        v-model="frage.eingabeAuswahlWert"
        :data-cy="`input-select-${frage.identifier}`"
        :errors="frage.errors(t)"
        :multiple="false"
        :label="frage.getTitle()"
        :options="frage.getAuswahlOptionValues()"
        :labels="frage.getAuswahlOptionLabels()"
        :readonly="isReadonly( frage.config?.readOnly )"
        :dependent-reason="frage.config?.beschreibung"
        @updateSocket="handleSocketUpdate(frage)"
      />
  
      <hzba-geo-json-input
        v-if="frage.eingabeTyp?.toLowerCase() === 'map' && frage.isShown()"
        v-model="frage.eingabeJson"
        :identifier="frage.path"
        :label="frage.getTitle()"
        :map-settings="frage.config.map"
        :readonly="isReadonly( frage.config?.readOnly )"
        :data-cy="`input-geojson-${frage.identifier}`"
        :errors="frage.errors(t)"
        @socketUpdate="handleSocketUpdate(frage)"
      />
  
      <hzba-select-input
        v-if="frage.eingabeTyp?.toLowerCase() === 'mehrfachauswahl' && frage.isShown()"
        v-model="frage.eingabeMehrfachAuswahlWerts"
        :data-cy="`input-select-${frage.identifier}`"
        :errors="frage.errors(t)"
        :multiple="true"
        :label="frage.getTitle()"
        :options="frage.getAuswahlOptionValues()"
        :labels="frage.getAuswahlOptionLabels()"
        :readonly="isReadonly( frage.config?.readOnly )"
        :dependent-reason="frage.config?.beschreibung"
        @updateSocket="handleSocketUpdate(frage)"
      />
      <hzba-base-input
        v-if="(
          frage.eingabeTyp.toLowerCase() === 'text'
          || frage.eingabeTyp.toLowerCase() === 'zahl'
          || frage.eingabeTyp.toLowerCase() === 'uhrzeit'
          || frage.eingabeTyp.toLowerCase() === 'datum'
        ) && frage.isShown()"
        v-model="frage.eingabeText"
        :data-cy="`input-text-${frage.identifier}`"
        :errors="frage.errors(t)"
        :label="frage.getTitle()"
        :type="getInputType(frage.eingabeTyp.toLowerCase())"
        :unit="frage.einheit"
        :limit="frage.config?.maxSymbols"
        :min="frage.min"
        :readonly="isReadonly( frage.config?.readOnly ) || !!frage.berechnunglogik"
        :placeholder="frage.platzhalter"
        :dependent-reason="frage.config?.beschreibung"
        @updateSocket="handleSocketUpdate(frage)"
      />
  
      <div
        v-if="enabledDevMode"
        class="absolute top-0 right-2 z-10"
        @click="activeDebug = activeDebug === index ? undefined : index"
      >
        o
      </div>
      <DebugFrage 
        v-if="enabledDevMode && activeDebug === index"
        :frage="frage"
        @close="activeDebug = undefined"
      />
  
  
      <div
        v-for="(mangel, mangelIndex) in frage.mangels"
        :key="mangelIndex"
      >
        <mangel-item
          v-if="mangel"
          :mangel="mangel"
          :frage="frage"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/composables/useTypedStore";
import { computed, ref, watch } from "vue";
import { PropType, defineComponent } from "vue-demi";
import { useI18n } from "vue-i18n";
//Models
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { Frage } from "@/models/ba/Frage";
import Immobilie from '@/models/immobilie.model';
//Composables
import useBestandsaufnahmeSockets from "@/composables/Bestandsaufnahme/useBestandsaufnahmeSockets";
import { UploadBaPhotos } from "@/composables/Upload/UploadBaPhotos";

//Components
import AButton from "@/components/Base/AButton.vue";
import DebugFrage from "@/components/Other/DebugFrage.vue";
import HzbaGeoJsonInput from "@/components/hzba/Base/Input/HzbaGeoJsonInput.vue";
import MangelItem from "@/components/hzba/Base/MangelItem.vue";
import HzbaBaseInput from "./Input/HzbaBaseInput.vue";
import HzbaChooseInput from "./Input/HzbaChooseInput.vue";
import HzbaModalItemBlock from "./Input/HzbaModalItemBlock.vue";
import HzbaPhotoInput from "./Input/HzbaPhotoInput.vue";
import HzbaSelectInput from "./Input/HzbaSelectInput.vue";
import HzbaTextareaInput from "./Input/HzbaTextareaInput.vue";
//Helpers
import { openHzbaModal } from "@/utilities/modal-helper";
import { Network } from "@capacitor/network";

export default defineComponent({
  name: "HzbaForm",
  components: {
    HzbaGeoJsonInput,
    DebugFrage,
    AButton,
    MangelItem,
    HzbaChooseInput,
    HzbaSelectInput,
    HzbaPhotoInput,
    HzbaBaseInput,
    HzbaTextareaInput,
    HzbaModalItemBlock,
  },
  emits: ['inputChanged'],
  props: {
    formFrages: {
      type: Object as PropType<Frage[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n({ useScope: 'global' })
    const activeDebug = ref();
    const enabledDevMode = computed(() => store.state.app.enabledDevMode );
    const isOffline = computed(() => {
      return store.getters["app/isOffline"];
    });
    const ba = computed<Bestandsaufnahme | undefined>(() => {
      return store.state.currentHzba.currentBa;
    });    
    const immobilie = computed<Immobilie | undefined>(() => {
      return (ba.value && Immobilie.find(ba.value.immobilie)) || undefined
    });

    watch(() => props.formFrages, (newVal, oldVal) => {
      if(props.formFrages) {
        props.formFrages.forEach(el => {
          el.setupComputedValues();
  
          watch(() => el.getCurrentInput(), (newEl, oldEl) => {
            console.log("setdirty")
            el.setDirty();
            emit('inputChanged')
  
          } )
        })
      }
    }, { immediate: true })

    const isReadonly = ( isFrageReadOnly: boolean | undefined ) => {
      if(!isOffline.value) {
        return isFrageReadOnly || store.getters['currentHzba/isBaReadonly'];
      } else {
        return false
      }
    }

    const getInputType = (eingabeTyp: string) => {
      switch (eingabeTyp) {
        case "uhrzeit":
          return "time";
        case "datum":
          return "date";
        case "zahl":
          return "number";
        default:
          return "text";
      }
    }

    return {
      openHzbaModal,
      isReadonly,
      activeDebug,
      getInputType,
      enabledDevMode,
      t,
      immobilie,
      ba,
      store
    }
    
  },

  methods: {
    async handleSocketUpdate(frage: any) {     
      if ( frage.eingabeTyp?.toLowerCase() === 'medien' ) {
        const networkStatus = await Network.getStatus();
        if ( networkStatus.connected ) {
          this.store.commit('currentHzba/setPhotoUploadTasks', new UploadBaPhotos());
          const photoUploadTasks = this.store.state.currentHzba.photoUploads;
          photoUploadTasks.getPhotoUploadTasks(this.ba, this.immobilie);

          //console.log('Photo upload task looks like this', photoUploadTasks?.toJson() );
          const imageUploadRes = await photoUploadTasks.uploadPhotos(this.ba, this.store);
          //console.log("imageUploadRes", imageUploadRes)        
        }
      } else {
        await useBestandsaufnahmeSockets().handleSocketEmit(frage)
      }
    }
  }
});
</script>
