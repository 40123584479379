<template>
  <div class="">
    <PdfChip
      v-if="
        'plus' ===
        useIdentifierFrageFinder(ba, fragesPath)?.getCurrentInput()
      "
      color="green"
      mangel-amount-mode
      value="1"
      pdf-mode
    />
    <PdfChip
      v-if="
        'o' === useIdentifierFrageFinder(ba, fragesPath)?.getCurrentInput()
      "
      color="blue"
      mangel-amount-mode
      value="1"
      pdf-mode
    />
    <PdfChip
      v-if="
        'minus' ===
        useIdentifierFrageFinder(ba, fragesPath)?.getCurrentInput()
      "
      color="yellow"
      mangel-amount-mode
      value="1"
      pdf-mode
    />
    <PdfChip
      v-if="
        'x' === useIdentifierFrageFinder(ba, fragesPath)?.getCurrentInput()
      "
      color="red"
      mangel-amount-mode
      value="1"
      pdf-mode
    />
  </div>
</template>


<script lang="ts">
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useIdentifierFrageFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import PdfChip from '../Components/PdfChip.vue';
export default defineComponent({
  name: 'OneToFiveImageDiv',
  components: {
    PdfChip,
  },
  props: {
    fragesPath: {
      type: String,
      required: true,
      default: '',
    },
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();
    return {
      user: uUser.user,
      t,
      moment,
      useIdentifierFrageFinder,
    };
  },
});
</script>
<style scoped>
* {
  color: var(--black100);
  font-family: var(--secondaryFont);
}
</style>