<template>
  <footer>
    <h2>Copyright &#169; MovingLayers {{ new Date().getFullYear() }}</h2>
  </footer>
</template>

<script>
export default {
  name: "FooterComp",
}
</script>


<style scoped lang="scss">
footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  background: var(--primary);
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;

  animation: animatedgradient 30s ease infinite;


  @media (max-height: 650px) {
    position: static;
  }

  h2 {
    font-family: var(--secondaryFont);
    color: var(--white100);
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    margin: 10px 30px;
  }
  
  @-webkit-keyframes animatedgradient{
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  @-moz-keyframes animatedgradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  @keyframes animatedgradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }

}


</style>