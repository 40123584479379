import { Network } from '@capacitor/network';
import { Storage } from '@ionic/storage';
import * as Sentry from '@sentry/capacitor';


const storage = new Storage();

//First we must create ionic storage
async function initIonicStorage() {
  await storage.create();
}

initIonicStorage().then(() => {
  async function sendErrorsToSentry(errors: any) {
    for (const error of errors) {
      await Sentry.captureException(error)
    }
  }

  async function handleOfflineErrors(error: any) {
    try {
      const storedErrors = (await storage.get('sentryErrors')) || '[]';
      const errors = JSON.parse(storedErrors);
      errors.push(error);
      await storage.set('sentryErrors', JSON.stringify(errors)); //Store errprs in ionic storage
    } catch (error) {
      console.log(error, 'Something is wrong with ionic storage')
    }
  }

  async function handleErrorsWhenGetOnline() {
    const isOnline = await Network.getStatus();
    if (isOnline.connected) {
      try {
        const storedErrors = (await storage.get('sentryErrors')) || '[]';
        const errors = JSON.parse(storedErrors);
        if (errors.length > 0) {
          //First send errors to sentry
          await sendErrorsToSentry(errors);
          //Then clear ionic storage to prevent duplicated errors in dashboard
          await storage.remove('sentryErrors');
        }
      } catch (error) {
        console.log(error, 'Something is wrong with ionic storage')
      }
    }
  }

  setInterval(handleErrorsWhenGetOnline, 30000)

  function eventListenerForErrors() {
    window.onerror = async function (error) {
      if (error) {
        //Check if online (if yes then send it to sentry, if not then store it in ionic storage)
        const isConnected = (await Network.getStatus()).connected;
        if (!isConnected) {
          await handleOfflineErrors(error);
        } else {
          await sendErrorsToSentry([error]);
        }
      }
    };
  }

  eventListenerForErrors();
});