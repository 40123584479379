<template>
  <span></span>
</template>

<script setup>

import Disposable from "ol/Disposable";
import { createCommentVNode, defineComponent } from "vue";

window?.pdfPageCounters?.forEach(func => {
  func();
});

</script>