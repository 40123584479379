<template>
  <PdfHzbaTemplate
    v-if="immobilie && ba && ba.identifier === 'hzba'"
    :ba="ba"
    :immobilie="immobilie"
    :lang="lang"
  />
  <RippisteinBauflaechen
    v-if="immobilie && ba && ba.identifier === 'rippistein-bauflaechen'"
    :ba="ba"
    :immobilie="immobilie"
    :lang="lang"
  />
  <RippisteinSeilzugflaechen
    v-if="immobilie && ba && ba.identifier === 'rippistein-seilzugflaechen'"
    :ba="ba"
    :immobilie="immobilie"
    :lang="lang"
  />
  <LEWMassnahme
    v-if="immobilie && ba && ba.identifier === 'lew-massnahme'"
    :ba="ba"
    :immobilie="immobilie"
    :lang="lang"
  />
    <Baumkataster
    v-if="immobilie && ba && ba.identifier === 'baumkataster'"
    :ba="ba"
    :immobilie="immobilie"
    :lang="lang"
  />


  <!--          TODO reactivate  -->
  <!--            <PdfTemplate-->
  <!--              v-if="ba && ba.identifier === 'techzen' && immobilie"-->
  <!--              :ba="ba"-->
  <!--              :immobilie="immobilie"-->
  <!--              :lang="lang"-->
  <!--            />-->
</template>

<script lang="ts">
import PdfHzbaTemplate from "./Bericht_Heizung/PdfHzbaTemplate";
import RippisteinBauflaechen from "./Bericht_Bauflaechen/RippIsteinBauflaechen.vue";
import RippisteinSeilzugflaechen from "./Bericht_Seilzugflaechen/RippIsteinSeilzugflaechen.vue";
import LEWMassnahme from "./Bericht_Massnahme/LEWMassnahme.vue";
import Baumkataster from "./Bericht_Baumkataster/Baumkataster.vue";

import {PropType} from "vue/dist/vue";
import Bestandsaufnahme from "../../models/ba/Bestandsaufnahme";
import Immobilie from "../../models/immobilie.model";
import {defineComponent} from "vue";
import { onMounted } from "vue";

export default defineComponent({
  name: "PdfTemplates",
  components: {PdfHzbaTemplate, RippisteinBauflaechen, RippisteinSeilzugflaechen, LEWMassnahme, Baumkataster},
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: "de",
    },
  },
  setup(props, { emit }) {

    console.log(props.ba);
    console.log(props.immobilie);

    onMounted(() => {
        console.log(props.ba);
        console.log(props.immobilie);
    });
  }
})
</script>

<style scoped>

</style>