import { io, Socket } from 'socket.io-client';
import { computed } from 'vue';
//Api
import { strapiSocketUrl } from "@/api/api";
//Composables
import { useStore } from "@/composables/useTypedStore";
//Models
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
//Helpers
import User from '@/models/user';
import { updateNestedObject } from "@/utilities/helper";
import { Network } from '@capacitor/network';

let socket: Socket | null = null;

export default function useBestandsaufnahmeSockets() {
  const store = useStore()
  const currentBa = computed(() => store.state.currentHzba.currentBa)
  const user = User.query().first()
  let networkStatus = null as any
  const currentProject = computed(() => store.state.user.currentUserProject)
  const networkConnected = computed(() => store.state.app.networkConnected)
  
  Network.addListener("networkStatusChange", async (status: any) => {
    if(status.connected) {
      await connect()
    } else {
      await disconnect()
    }
  });

  async function checkNetworkStatus() {
    networkStatus = (await (Network.getStatus())).connected
    return networkStatus
  }

  async function connect() {
    if(await checkNetworkStatus() === false) return
    if(!socket) {
      socket = io(strapiSocketUrl)
      socket.connect()
      console.log('Socket connected')
    }
  }

  function disconnect() {
    if(socket && networkConnected.value) {
      socket.disconnect()
      socket = null
      console.log('Socket disconnected')
    }
  }

  function subscribeToBestandsaufnahme() {
    if(socket && networkConnected.value) {
      socket.emit('subscribeToBestandsaufnahme', { baId: currentBa.value.id, userId: user?.id, projectId: currentProject.value.id })
      console.log('subscribed to', currentBa.value.id, user?.id )
    }
  }

  function unsubscribeFromBestandsaufnahme() {
    if(socket && networkConnected.value) {
      socket.emit('unsubscribeFromBestandsaufnahme', currentBa.value.id)
      console.log('unsubscribed from', currentBa.value.id)
    }
  }

  if(socket && networkConnected.value) {
    socket.on('bestandsaufnahmeUpdated', (data) => {
      handleSocketEvent(data)
      console.log('bestandsaufnahme updated', socket?.id)
    });
  }

  const handleSocketEmit = (data: any) => {
    if(socket && networkConnected.value) {
      const frageData = {
        id: data.id,
        identifier: data.identifier,
        eingabeJson: data.eingabeJson,
        eingabeMehrfachAuswahlWerts: data.eingabeMehrfachAuswahlWerts,
        eingabeText: data.eingabeText,
        eingabeZahl: data.eingabeZahl,
        eingabeBoolean: data.eingabeBoolean,
        eingabeAuswahlWert: data.eingabeAuswahlWert
      }
      socket.emit('updateBestandsaufnahme', { frageData, baId: currentBa.value.id, userId: user?.id })
      console.log('bestandsaufnahme frage sent to backend', currentBa.value.id)
    }
  };

  async function handleSocketEvent(frageData: any) {
    const updatedBa = updateNestedObject(currentBa.value, frageData.id, frageData.identifier, frageData)

    if(updatedBa) {
      store.commit('currentHzba/setCurrentBa', updatedBa)
      const updatedBaJson = await updatedBa.toClassJson()
      await BestandsaufnahmeModel.update({where: updatedBa.id, data: updatedBaJson})
      await BestandsaufnahmeModel.dispatch('$updateLocally', { data: updatedBaJson })
      // const modelBa = await BestandsaufnahmeModel.find(updatedBa.id)
      // await BestandsaufnahmeModel.dispatch('addToPersistedBestandsaufnahmes', modelBa);
    }
  }

  return {
    connect,
    disconnect,
    subscribeToBestandsaufnahme,
    unsubscribeFromBestandsaufnahme,
    handleSocketEmit
  };
}