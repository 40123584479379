<template>
  <div class="text-xs">
    <span
      v-if="useIdentifierFrageFinder(ba, fragesPath)?.getCurrentInput()"
      class="text-xs text-gray-500"
    >
      {{ '\u{2611} ' }}
    </span>
    <span v-else class="text-xs text-gray-500">
      {{ '\u{2610} '  }}
    </span>
    <span class="text-xs">{{ label }}</span>
  </div>
</template>


<script lang="ts">
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { useIdentifierFrageFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';

export default defineComponent({
  name: 'isVorhandenCharAndLabel',
  components: {},
  props: {
    label: { type: String, required: true, default: 'Label' },
    fragesPath: {
      type: String,
      required: true,
      default: '',
    },
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' });
    return {
      t,
      useIdentifierFrageFinder,
    };
  },
});
</script>
<style scoped>
* {
  color: rgb(23,68,81);
  font-family: 'Avenir-Next-LT-Pro-Regular';
}
</style>