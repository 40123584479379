<template>
  <svg
    width="33px"
    height="33px"
    viewBox="0 0 33 33"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Map Icon</title>
    <g
      id="Page"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="1.1-Übersicht-Allgemein"
        transform="translate(-715.000000, -101.000000)"
      >
        <g
          id="Icon-/-Events-/-Location-/-Background"
          transform="translate(715.000000, 101.000000)"
        >
          <circle
            id="Oval-3-Copy"
            :fill="active ? 'var(--primary)' : '#EBEFF5'"
            style="mix-blend-mode: multiply;"
            cx="16.5"
            cy="16.5"
            r="16.5"
          />
          <g
            id="Icon-/-Events-/-List-Copy"
            transform="translate(11.000000, 8.000000)"
          >
            <path
              id="Fill-1"
              d="M10.5183,5.37971786 C10.5183,8.04461071 5.53615714,14.9465393 5.53615714,14.9465393 C5.53615714,14.9465393 0.554014286,8.04461071 0.554014286,5.37971786 C0.554014286,2.71371786 2.78490714,0.553682143 5.53615714,0.553682143 C8.2863,0.553682143 10.5183,2.71371786 10.5183,5.37971786"
            />
            <path
              id="Stroke-3"
              d="M10.5183,5.37971786 C10.5183,8.04461071 5.53615714,14.9465393 5.53615714,14.9465393 C5.53615714,14.9465393 0.554014286,8.04461071 0.554014286,5.37971786 C0.554014286,2.71371786 2.78490714,0.553682143 5.53615714,0.553682143 C8.2863,0.553682143 10.5183,2.71371786 10.5183,5.37971786 Z"
              :stroke="active ? 'var(--secondary)' : 'var(--black100)'"
            />
            <path
              id="Stroke-7"
              d="M3.81931071,4.98723571 C3.81931071,4.03952143 4.58766786,3.27116429 5.53648929,3.27116429 C6.48309643,3.27116429 7.25256071,4.03952143 7.25256071,4.98723571 C7.25256071,5.93495 6.48309643,6.70330714 5.53648929,6.70330714 C4.58766786,6.70330714 3.81931071,5.93495 3.81931071,4.98723571 Z"
              :stroke="active ? 'var(--secondary)' : 'var(--black100)'"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>


<script>

export default {
  name: 'MapIcon',
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>