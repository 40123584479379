import { createApp } from 'vue';
import App from './App.vue';
import router from './routes';

import { environment } from "../environments/environment";

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/display.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { IonicVue } from '@ionic/vue';


// Vuex
import { store, storeTypes } from '@/store/store';

// Styling
import { i18n } from "@/i18n";
import '@/styles/ionic_variables.css';
import '@/styles/utilities.css';
import Markdown from 'vue3-markdown-it';

import '@/services/error.service'

import * as Sentry from "@sentry/capacitor";
import *  as SentryVue from '@sentry/vue';
import {Replay} from '@sentry/replay';

const app = createApp(App)
  .use(IonicVue, {
    backButtonText: '',
  })
  .use(router)
  .use(store, storeTypes)
  .use(i18n)
  .use(Markdown);

  
  Sentry.init({
    app,
    dsn: "https://b7db3548c5e59380a024ee3c242c2345@o4507222280044544.ingest.de.sentry.io/4507222438969424",
    integrations: [
      new Replay({maskAllText: true, blockAllMedia: true}),
      new SentryVue.VueIntegration()
      // Waiting for new release of @sentry/capacitor to include tracing. See https://github.com/getsentry/sentry-capacitor/issues/639
      // SentrySibling.browserTracingIntegration({ router }),
    ],
  
    tracesSampleRate: 1.0,
  
    tracePropagationTargets: ["localhost"],
  
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: environment.ENVIRONMENT,
    release: environment.BUILD_VERSION
  },
  SentryVue.init
  );

router.isReady().then(() => {
  app.mount('#app');
});


// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);