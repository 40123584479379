import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5eab5e05"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-4" }
const _hoisted_2 = { class: "md:flex" }
const _hoisted_3 = { class: "flex-1" }
const _hoisted_4 = { class: "text-2xl mb-2" }
const _hoisted_5 = {
  key: 0,
  class: "px-4 py-2 flex items-center"
}
const _hoisted_6 = { class: "ml-2 synced" }
const _hoisted_7 = { class: "block md:flex items-center" }
const _hoisted_8 = { class: "mb-2 mt-2 flex-1" }
const _hoisted_9 = { style: {"color":"#b6b6b6"} }
const _hoisted_10 = {
  key: 0,
  class: "mb-2 mt-2"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_info_banner = _resolveComponent("info-banner")!
  const _component_offline_hint = _resolveComponent("offline-hint")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.t("hzba.vorschau.synchronisierungStatus")), 1)
        ]),
        _createElementVNode("div", null, [
          (!_ctx.hzba.isLocal)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createTextVNode(_toDisplayString(_ctx.t("hzba.vorschau.synchronisiert")) + " ", 1),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.checkmark,
                    color: "white",
                    size: "medium"
                  }, null, 8, ["icon"])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      ((!_ctx.isOffline) && (_ctx.hzba?.isLocal || _ctx.uploadImageStatus.totalSuccessfulUploads() !== _ctx.uploadImageStatus.totalImages()))
        ? (_openBlock(), _createBlock(_component_info_banner, {
            key: 0,
            "no-extra-margin": ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.hzba?.isLocal ? _ctx.t("hzba.vorschau.nichtSynchronisierteDaten") : _ctx.t("hzba.vorschau.nichtSynchronisierteBilder")), 1)
                ]),
                _createElementVNode("div", null, [
                  (!_ctx.isOffline)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        fill: "flat",
                        shape: "round",
                        disabled: _ctx.isReadonly,
                        "data-cy": "button-upload-data",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('saveHzba')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("hzba.buttons.synchronisieren")), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_offline_hint, { style: {"margin-top":"8px"} }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("hzba.vorschau.keinInternetLokalGespeichert")), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_9, [
        (_ctx.hzba && _ctx.hzba.updatedAt)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.t("hzba.vorschau.zuletztSynchronisiertAm")) + " " + _toDisplayString(new Date(_ctx.hzba.updatedAt).toLocaleString("de-De")), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          (_ctx.photoUploads)
            ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.t('hzba.vorschau.bilderHochladenStatus')), 1))
            : (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(`${_ctx.uploadImageStatus.totalSuccessfulUploads()}/${_ctx.uploadImageStatus.totalImages()} ${_ctx.t('hzba.vorschau.bilderHochgeladen')}`), 1))
        ]),
        (!_ctx.hzba?.updatedAt)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.t("hzba.vorschau.nichtHochgeladenInfo")), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}