<template>
  <div
    class="grid grid-cols-1 lg:p-0 sm:grid-cols-1 md:grid-cols-2  gap-5"
    :class="[
      propertyView ? 'lg:grid-cols-2' : 'lg:grid-cols-3',
      propertyView ? 'xl:grid-cols-3' : 'xl:grid-cols-4',
    ]
    "
  >
    <CreateHzbaCard v-if="propertyView && featureFlags?.createSurvey" @click="openAufnahmenModal()" />
    <hzba-card
      v-for="ba in hzbas"
      :key="ba.id"
      :ba="ba"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonButton,
  IonLabel,
  IonBadge,
  IonChip,
  modalController
} from "@ionic/vue";
import HzbaCard from "./HzbaCard.vue";
import CreateHzbaCard from "@/components/hzba/CreateHzbaCard.vue";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import CreateSurveyModal from "@/components/v2/General/CreateSurveyModal.vue";
import useUser from "@/composables/useUser";

export default defineComponent({
  name: "HzbaList",
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonButton,
    IonLabel,
    IonBadge,
    IonChip,
    HzbaCard,
    CreateHzbaCard,
  },
  props: {
    hzbas: {
      type: Object as PropType<Bestandsaufnahme[]>,
      required: true,
    },
    propertyView: {
      type: Boolean,
      default: false
    },
    property: {
      type: Object,
      required: false
    },
    templateIdentifier: {
      type: String,
      required: false
    }
  },
  setup() {
    const { user } = useUser()

    return {
      featureFlags: computed(() => user.value?.organisation?.featureFlags)
    }
  },
  methods: {
    async openAufnahmenModal() {
      const modal = await modalController.create({
        component: CreateSurveyModal,
        cssClass: 'v2Modal',
        componentProps: {
          property: this.property,
          templateIdentifier: this.templateIdentifier
        },
        canDismiss: true,
        //@ts-ignore
        presentingElement: this.$root.$refs.ionRouter.ionRouterOutlet
      });
      return modal.present();
    },
  }
});
</script>

<style scoped>

.forceOneCol {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

</style>


