<template>
  <ion-item class="appInput" :lines="readonly ? 'none' : 'full'">
    <ion-input
      :placeholder="props.placeholder"
      :value="props.modelValue"
      :readonly="readonly"
      @ionChange="handleChange($event)"
    >
    </ion-input>
  </ion-item>
</template>

<script setup lang="ts">
import { IonInput, IonItem } from '@ionic/vue';

//Emits
const emit = defineEmits(['valueChanged', 'update:modelValue'])

//Props
const props = defineProps({
  placeholder: { type: String, required: false },
  modelValue: { type: String, required: false },
  readonly: { type: Boolean, required: false }
})

//Functions
function handleChange(event: any) {
  emit('valueChanged', event)
  emit('update:modelValue', event.detail.value)
}
</script>

<style lang="scss">
ion-item.appInput {
  --ion-background-color: transparent;
  --border-color: transparent;
}
</style>
