<template>
  <div
    v-if="!isReadonly"
    class="boxed-container text-center mt-16"
  >
    <ion-button
      fill="clear"
      color="danger"
      class="d-button"
      @click="displayAlert()"
    >
      {{ fragenblock.getTitle() }} {{ t("hzba.buttons.loeschen") }}
    </ion-button>
  </div>
  <div
    v-if="!isReadonly"
    class="boxed-container text-center"
  >
    <ion-button 
      fill="clear" 
      color="info"
      class="d-button"
      @click="$emit('duplicate')"
    >
      {{ fragenblock.getTitle() }} {{ t("hzba.buttons.duplizieren") }}
    </ion-button>
    <div
      v-if="fragenblock.isFormDirty()"
      style="color: #a9a9a9;"
      class="mb-4"
    >
      {{ t("hzba.duplizierenInfoText") }}
    </div>
  </div>
</template>

<script lang="ts">
import useAlert from '@/composables/useAlert';
import { Fragenblock } from "@/models/ba/Fragenblock";
import { store } from "@/store/store";
import { IonButton } from "@ionic/vue";
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: "HzbaDeleteDuplicateItem",
  components: { IonButton },
  props: {
    fragenblock: {
      type: Object as PropType<Fragenblock>,
      required: true
    }
  },
  emits: ['duplicate', 'delete'],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: 'global' })
    const alert = useAlert();
    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    function displayAlert() {
      alert.showAdv(
        t("alerts.deleteTitle"),
        t("alerts.deleteDescription"),
        [
          { text: t("alerts.deleteCancel"), },
          { text: t("alerts.deleteConfirm"), handler: () => { emit('delete')} },
        ]
      )
    }

    return {
      displayAlert,
      isReadonly,
      t
    }
  }
})
</script>

<style scoped>
.d-button {
  width: 100%;
  white-space: break-spaces;
  height: unset;
  min-height: 48px;
  display: flex;
  align-items: center;
}

</style>
