import { useBauflaechen } from '@/components/Pdf/Bericht_Bauflaechen/useBauflaechen';
import { useSeilzugflaechen } from '@/components/Pdf/Bericht_Seilzugflaechen/useSeilzugflaechen';
import { useBestandsaufnahmens } from '@/composables/Bestandsaufnahme/useBestandsaufnahmens';
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import { BestandsaufnahmeJson } from '@/models/ba/interfaces/IBestandsaufnahme';
import BestandsaufnahmeModel from '@/models/ba/models/bestandsaufnahme.model';
import Immobilie from '@/models/immobilie.model';
import { ICurrentHzba } from '@/store/store.types';
import { logger } from "@/utilities/logging";
import {
  loadHzbaPhotosLocal,
  saveHzbaPhotosLocal
} from '@/utilities/photo-storage';
import { Network } from '@capacitor/network';
import { Module } from 'vuex';

const { summarizeOverall } = useBauflaechen();
const { summarizeOverallSeilzugflaechen } = useSeilzugflaechen();

export const HZBA_MODE = {
  MODAL: 'modal',
  SUBMODAL: 'subModal',
};

export const currentHzba: Module<ICurrentHzba, any> = {
  namespaced: true,
  state: () => ({
    currentHzba: {},
    currentBa: undefined,
    photoUploads: undefined,
    uploadStatus: undefined,
    subModalHzba: undefined,
    modalHzba: undefined,
    pleasePauseSync: false
  }),
  mutations: {
    setCurrentBa(state: ICurrentHzba, payload: Bestandsaufnahme) {
      state.currentBa = payload;
    },

    /**
     * Removes temporary ba. Does nothing say about whether this ba has been saved to next (or top) level or has been discarded.
     */
    cleanCurrentHzba(state: ICurrentHzba) {
      // state.currentBa = undefined
    },
    setUploadStatus(state: ICurrentHzba, payload) {
      state.uploadStatus = payload;
    },
    setPhotoUploadTasks(state: ICurrentHzba, payload) {
      state.photoUploads = payload;
    },
    setPleasePauseSync(state: ICurrentHzba, payload) {
      state.pleasePauseSync = payload;
    }
  },
  actions: {
    async setCurrentHzba({ state }: { state: ICurrentHzba }, ba: BestandsaufnahmeModel ) {
      if (!ba) {
        console.warn('[currentHzba/setCurrentHzba] setting ba failed because param ba is null or undefined');
        // logger.warn(`{"message": "[currentHzba/setCurrentHzba] setting ba failed because param ba is null or undefined"}`);
        state.currentBa = undefined;
        return;
      }

      state.currentHzba = {};
      const currentBaJson = await ba.copyJson();

      /**
       * local testing:
       */
      // @ts-ignore
      // const currentBaJson = useGenericHzbaJsonInProgress().data as BestandsaufnahmeJson;
      // const currentBaJson = useNewHzbaJson().data as BestandsaufnahmeJson;

      const currentBa = new Bestandsaufnahme(currentBaJson, undefined);
      if ( !currentBa.areMinimumFragenblocksSet) {
        const promises: Promise<any>[] = [];

        currentBa.fragenblocks?.forEach((el) => {
          promises.push(el.setupFragenblockMinimumInstances());
        });

        await Promise.all(promises);
      }

      logger.defaultMeta.currentBa = currentBa.id;

      console.log('[currentHzba/setCurrentHzba] set current ba with content:', await currentBa.toClassJson());
      // logger.info(`{"message": "[currentHzba/setCurrentHzba] set current ba ${currentBa.id} with content}"}`); 
      state.currentBa = currentBa;
      await loadHzbaPhotosLocal(state.currentBa);
      await this.dispatch("currentHzba/setValueDetectors");
    },

    /**
     * Todo refactor this function and move to useBestandsaufnahmes
     * @param state
     * @param storeToStore
     */
    async refetchCurrentBa({ state }: { state: any }, projectId: number, storeToStore?: boolean) {
      if(!state.currentBa) return
      const res = await BestandsaufnahmeModel.api().get(
          `/bestandsaufnahmes/${state.currentBa!.id}`,
          { params: { projectId: String(projectId) } }
      );
      const newBa = (res.getDataFromResponse() as any)
          .data as BestandsaufnahmeJson;

      const ba = new Bestandsaufnahme(newBa, undefined);
      ba.fragenblocks?.forEach((el) =>
          el.setupFragenblockMinimumInstances(),
      );

      const isDownloaded = state.currentBa?.isDownloaded;

      state.currentBa = ba;

      if (!storeToStore) {
        return state.currentBa;
      }

      await BestandsaufnahmeModel.update({
        where: newBa.id,
        data: newBa,
      });

      if (isDownloaded) {
        const { downloadBa } = useBestandsaufnahmens();

        // @ts-ignore
        await downloadBa(undefined, newBa.id, isNaN(newBa.immobilie) ? newBa.immobilie?.id : newBa.immobilie)
      }

      await this.dispatch("currentHzba/setValueDetectors");
      return state.currentBa;
    },

    async saveHzbaLocally({ state, dispatch }: { state: any; dispatch: any }, setToLocal = true) {
      try {
        if (!state.currentBa.isLocal) {
        const im = Immobilie.find(state.currentBa.immobilie);
          if (im) {
            im!.isDownloaded = true;
            await Immobilie.dispatch('$updateLocally', { data: im });
          }
        }

        const networkStatus = await Network.getStatus();

        if ( !networkStatus.connected  ) {
          state.currentBa.hasUnsavedChanges = true;
        }

        let malusResult;
        switch (state.currentBa.identifier) {
          case 'rippistein-bauflaechen':
            malusResult = summarizeOverall( state.currentBa)
            state.currentBa.malus = malusResult.amount
            state.currentBa.malusColor = malusResult.color
            break;
          case 'rippistein-seilzugflaechen':
            malusResult = summarizeOverallSeilzugflaechen( state.currentBa)
            state.currentBa.malus = malusResult.amount
            state.currentBa.malusColor = malusResult.color
            break;
          default:
            //vorlagen identifier not considered yet
            state.currentBa.malus = null;
            state.currentBa.malusColor = null;
            break;
        } 
        // state.currentBa.malus = state.currentBa.maengelReport()?.getCalculatedMalus()?.toString();  old hzba solution based on maengel
        // state.currentBa.malusColor = state.currentBa.maengelReport()?.getColorByMaengel(); old hzba solution based on maengel
        state.currentBa.isDownloaded = true;

        console.log("SAVE HZBA LOCALLY")
        // logger.info(`{"message": "SAVE BA LOCALLY"}`);


        const ba = state.currentBa;
        await saveHzbaPhotosLocal(state.currentBa);

        await BestandsaufnahmeModel.update({
          where: ba.id,
          data: await ba.toClassJson(),
        });
        const uHzba = await BestandsaufnahmeModel.find(ba.id);
        // cacheOfflineSurvey(ba)
        await BestandsaufnahmeModel.dispatch('$updateLocally', { data: uHzba });
        // await BestandsaufnahmeModel.dispatch('addToPersistedBestandsaufnahmes', uHzba);
      } catch (err) {
        console.error('Error trying to save hzba locally: ', err);
        logger.error(`Error trying to save hzba locally: ${err}`);
      }
    },

    async confirmSuccessfullUpload({state, dispatch,}: { state: any; dispatch: any; }) {
      state.photoUploads = undefined;
    },

    async setValueDetectors({ state, dispatch }: { state: any; dispatch: any }) {
      state.currentBa.fragenblocks?.forEach((el: any) => {
        el.setupDetectors();
      });
    },
    
  },
  getters: {
    /**
     * Evaluates if user is allowed to edit hzbas
     * Roles allowed to edit hzbas are projektverwalter (1) and gutachter (3).
     * gutachter (3) are only allowed to edit assigned hzbas but because they cannot fetch not-assigned hzbas, we don't need to evaluate this here
     * Immobilienverwalter (2) are never allowed to edit hzbas, even if assigned to hzba
     *
     * @returns if current user is allowed to edit hzbas
     */
    hasHzbaEditPermission( state: any) { 
      const { user } = useUser();

      // roles allowed to edit hzbas are projektverwalter (1) and gutachter (2), and bearbeiter (6)
      return [1, 3, 6].includes(user.value?.role.id ?? -1);
    },

    isBaReadonly(state: any, getters: any, rootState: any, rootGetters: any) {
      return state.currentBa?.status === "FREIGEGEBEN" ||
        state.currentBa?.status === "ABGESCHLOSSEN" ||
        !getters.hasHzbaEditPermission
    }
  },
};
