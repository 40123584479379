<template>
  <ion-card class="rounded-sm bg-white acon-card-shadow p-4 projectCardHolder">
    <div class="imageHolder">
      <a-image
        :image-class="`w-28 h-28 md:h-32 object-cover p-0 m-0`"
        :src="getPhotoUrl(user?.organisation.vorschaubild, { small: true })" 
        set-to-max
      />
    </div>
    <div class="contentHolder">
      <ion-card-title class="text-lg leading-5 md:text-xl mb-3 ion-inherit-color md">
        {{ props.project.name }}
      </ion-card-title>
      <ion-card-subtitle class="text-md ion-inherit-color md">
        {{ user.organisation.name }}
      </ion-card-subtitle>
    </div>
  </ion-card>
</template>

<script setup lang="ts">
import { IonCard, IonCardTitle, IonCardSubtitle } from '@ionic/vue';
//Composables
import useUser from "@/composables/useUser";
//Components
import AImage from "@/components/Base/AImage.vue";
//Helpers
import { getPhotoUrl } from "@/utilities/get-media-url";


//Props
const props = defineProps({
  project: {
    type: Object,
    required: true
  }
})

const { user } = useUser();
</script>

<style lang="scss">
.projectCardHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
  cursor: pointer;
}
</style>
