<template>
  <ion-datetime max="2222-05-31T23:59:59" :presentation="props.presentation" @ionChange="handleChange($event)"></ion-datetime>
</template>

<script setup lang="ts">
import { IonDatetime } from '@ionic/vue'
//Emits
const emit = defineEmits(['valueChanged', 'update:modelValue'])

//Props
const props = defineProps({
  presentation: {
    type: String,
    required: false,
    default: () => { return 'date-time' }
  }
})

//Functions
function handleChange(event: any) {
  emit('valueChanged', event)
  emit('update:modelValue', event.detail.value)
}
</script>

<style lang="scss">

</style>
