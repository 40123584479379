import { FragenblockJson } from '@/models/ba/interfaces/IFragenblock';
import ErweitertesMedium from '@/models/erweitertes-medium.model';
import Unterschrift from '@/models/hzba/unterschrift.model';
import Localization from '@/models/localization.model';
import { APhoto } from '@/models/photo/a-photo.model';
import StrapiMedia from '@/models/photo/strapi-media.model';
import User from '@/models/user';

export interface BestandsaufnahmeFlatJson {
  id: number;
  name: Localization;
  nameMehrzahl: Localization;
  nameBericht: Localization;
  identifier: string;
  bilder: APhoto[];
  malus: string;
  malusColor: string;
  kommentar: string;
  begehungsdatum: string;
  berichts: ErweitertesMedium[];
  mangellists: any;
  archive: StrapiMedia;
  matterportLink?: string;
  immobilie: number;
  status: HzbaStatusKey;
  gutachter: any;
  isLocal: boolean;
  hasUnsavedChanges: boolean;
  hasLocalImages: boolean;
  isDownloaded: boolean;
  unterschrifts?: Unterschrift[];
  auftraggeber: string;
  technProjektsteuerungName: string;
  reihenfolge: string;
  technProjektsteuerungUnterschrift: APhoto[];
  freigabeDatum: string;
  fazit: string;
  createdBy: string;
  updatedBy?: User;
  createdAt: string;
  updatedAt: string;
  bearbeitenderNutzer?: User;
  bearbeitendesGeraetId?: string;
  removedMangelsFromMigrations?: any;
  surveyType: string;
  areMinimumFragenblocksSet?: boolean;
}

export interface BestandsaufnahmeJson extends BestandsaufnahmeFlatJson {
  fragenblocks?: FragenblockJson[];
}

export enum HzbaStatus {
  ANGELEGT = 'Angelegt',
  GEPLANT = 'Geplant',
  IN_DURCHFUEHRUNG = 'In Durchführung',
  ABGESCHLOSSEN = 'Abgeschlossen',
  FREIGEGEBEN = 'Freigegeben',
  ARCHIVIERT = 'Archiviert',
}

export enum HzbaStatusCode {
  ANGELEGT = 'ANGELEGT',
  GEPLANT = 'GEPLANT',
  IN_DURCHFUEHRUNG = 'IN_DURCHFUEHRUNG',
  ABGESCHLOSSEN = 'ABGESCHLOSSEN',
  FREIGEGEBEN = 'FREIGEGEBEN',
  ARCHIVIERT = 'ARCHIVIERT',
  OFFLINE = 'LOCALE_offline',
  RESET = 'LOCALE_reset'
}

export const translatedHzbaStatus = (key: any, t: any) => {
  switch (key) {
    case HzbaStatusCode.ANGELEGT:
      return t('hzba.vorschau.statusAngelegt');
    case HzbaStatusCode.GEPLANT:
      return t('hzba.vorschau.statusGeplant');
    case HzbaStatusCode.IN_DURCHFUEHRUNG:
      return t('hzba.vorschau.statusInDurchführung');
    case HzbaStatusCode.ABGESCHLOSSEN:
      return t('hzba.vorschau.statusAbgeschlossen');
    case HzbaStatusCode.FREIGEGEBEN:
      return t('hzba.vorschau.statusFreigegeben');
    case HzbaStatusCode.ARCHIVIERT:
      return t('hzba.vorschau.statusArchiviert');
    case HzbaStatusCode.RESET:
      return t('hzba.vorschau.statusZurücksetzen');
    case HzbaStatusCode.OFFLINE:
      return t('hzba.vorschau.statusOffline');
  }
};

export type HzbaStatusKey = keyof typeof HzbaStatus;
export const HzbaStatusKeys = new Set(Object.keys(HzbaStatus));
