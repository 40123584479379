<template>
  <div class="downloadSurveysButton">
    <div id="popoverTrigger" class="downloadSurveysButtonContent px-4 py-1.5">
      <div class="label text-sm font-medium">{{ t('downloadSurveysPopover.offlineDownload') }}</div>
      <div class="icon flex">
        <ion-icon class="md icon-small" :icon="ellipsisHorizontalCircle" />
      </div>
    </div>

    <ion-popover trigger="popoverTrigger" trigger-action="click" :dismissOnSelect="true">
      <ion-content class="ion-padding">
        <div class="popoverContent">
          <div class="popoverItem py-4 text-base font-medium" @click="emit('downloadAll')">{{ t('downloadSurveysPopover.all') }}</div>
          <div class="popoverItem py-4 text-base font-medium" @click="emit('downloadPlannedForToday')">{{ t('downloadSurveysPopover.plannedToday') }}</div>
          <div class="popoverItem py-4 text-base font-medium" @click="emit('downloadFiltered')">{{ t('downloadSurveysPopover.filtered') }}</div>
        </div>
      </ion-content>
    </ion-popover>
  </div>
</template>

<script setup lang="ts">
import { IonPopover, IonContent, IonIcon } from '@ionic/vue'
import { ellipsisHorizontalCircle } from "ionicons/icons"
import { useI18n } from 'vue-i18n';

//Emits
const emit = defineEmits(['downloadAll', 'downloadPlannedForToday', 'downloadFiltered'])

//Variables
const { t } = useI18n({ useScope: 'global' })
</script>

<style lang="scss">
.downloadSurveysButton {
  width: fit-content;
  cursor: pointer;

  .downloadSurveysButtonContent {
    border-radius: 99px;
    border: 1px solid rgb(81, 26, 63);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
  }
}

.popoverContent {
  .popoverItem {
    cursor: pointer;
  }
}
</style>
