<template>
  <div
    :style="toolbarMode ? 'margin-top: 0; margin-bottom: 0;' : ''"
    class="searchbar-row row"
    :class="toolbarMode ? '' : '' "
  >
    <ion-searchbar
      slot="primary"
      class="ion-searchbar"
      data-cy="searchbar"
      :value="searchTerm"
      :placeholder="t(`${organisationPrefix}immobilie.suchePlatzhalter`)"
      @ionChange="e => $emit('update:searchTerm', e.target.value)"
    />
  </div>
</template>

<script lang="ts">
import User from "@/models/user";
import {
IonButton,
IonIcon,
IonSearchbar,
} from "@ionic/vue";
import { ComputedRef, computed, defineComponent } from "vue";
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: "Searchbar",
  components: {
    IonButton,
    IonIcon,
    IonSearchbar,
  },
  props: {
    searchTerm: {
      type: String,
      default: '',
    },
    toolbarMode: {
      type: Boolean,
      default: false
    }
  },
   setup(_, params) {


    const { t } = useI18n({ useScope: 'global' })
    const organisationPrefix: ComputedRef<string> = computed(() => {
      const currentUser: User | null = User.query().first();

      return currentUser ? currentUser.organisationPrefix : "";
    });

    return {
      t,
      organisationPrefix
    };
   }
})

</script>

<style lang="scss">

//@import "@/styles/main.scss";

.searchbar-row {
  height: 48px;
  fill: #efefef;
  color: #5F5F5F;
}

.ion-searchbar {
  --background: #f8f9fb !important;
  background-color: #F8F9FB;

  border-radius: 36px;
  --border-radius: 36px;

  --box-shadow: inset 0 0 4px 0 #ECECEC;

  padding: 0px !important;
  height: inherit !important;
  border: 1px solid #E9E9E9;
}

.searchbar-input-container, .searchbar-input { /** .sc-ion-searchbar-ios **/
  height: inherit !important;
}

.searchbar-button {
  height: inherit !important;
  margin: 0;
}

.searchbar-search-icon.sc-ion-searchbar-md {
  top: 14px;
}

</style>