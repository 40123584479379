<template>
  <div class="">
    <PdfChip
      v-if="
        'plus' === fragenblock.findFrageByRelativePath(ba, fragesPath)['eingabeAuswahlWert']
      "
      color="green"
      mangel-amount-mode
      value="1"
      pdf-mode
    />
    <PdfChip
      v-if="
        'o' === fragenblock.findFrageByRelativePath(ba, fragesPath)['eingabeAuswahlWert']
      "
      color="blue"
      mangel-amount-mode
      value="1"
      pdf-mode
    />
    <PdfChip
      v-if="
        'minus' === fragenblock.findFrageByRelativePath(ba, fragesPath)['eingabeAuswahlWert']
      "
      color="yellow"
      mangel-amount-mode
      value="1"
      pdf-mode
    />
    <PdfChip
      v-if="
        'x' === fragenblock.findFrageByRelativePath(ba, fragesPath)['eingabeAuswahlWert']
      "
      color="red"
      mangel-amount-mode
      value="1"
      pdf-mode
    />
  </div>
</template>


<script lang="ts">
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useIdentifierFrageFinder,useIdentifierFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import PdfChip from '../Components/PdfChip.vue';
import { Fragenblock } from '@/models/ba/Fragenblock';
export default defineComponent({
  name: 'OneToFiveImageDiv',
  components: {
    PdfChip,
  },
  props: {
    fragesPath: {
      type: String,
      required: true,
      default: '',
    },
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    fragenblock: {
      type: Object as PropType<Fragenblock>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {

    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();
    return {
      user: uUser.user,
      t,
      moment,
      useIdentifierFrageFinder,
      useIdentifierFinder
    };
  },
});
</script>
<style scoped>
* {
  color: black;
  font-family: 'Avenir-Next-LT-Pro-Regular';
}
</style>