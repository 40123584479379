<template>
  <div class="icon-wrapper flex-shrink-0">
    <ProgressZeroIcon v-if="progress <= 0 || !progress" />
    <ProgressQuarterIcon v-else-if="progress <= 33" />
    <ProgressHalfIcon v-else-if="progress <= 66" />
    <ProgressThreeQuarterIcon v-else-if="progress < 100" />
    <ProgressCompletedIcon v-else-if="progress >= 100" data-cy="completed" />
  </div>
</template>

<script lang="ts">
//Icons
import ProgressZeroIcon from '@/components/Icons/ProgressZeroIcon.vue'
import ProgressQuarterIcon from '@/components/Icons/ProgressQuarterIcon.vue'
import ProgressHalfIcon from '@/components/Icons/ProgressHalfIcon.vue'
import ProgressThreeQuarterIcon from '@/components/Icons/ProgressThreeQuarterIcon.vue'
import ProgressCompletedIcon from '@/components/Icons/ProgressCompletedIcon.vue'

export default {
  name: "ProgressIndicator",
  components: {
    ProgressZeroIcon,
    ProgressQuarterIcon,
    ProgressHalfIcon,
    ProgressThreeQuarterIcon,
    ProgressCompletedIcon,
  },
  props: {
    progress: {
      type: Number,
      default: null
    },
  },
}
</script>

<style lang="scss">

</style>
