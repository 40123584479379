
<template>
  <ion-page>
    <toolbar
      :title="t('toolbar.hilfe')"
      show-back-button
      @onBackButtonClicked="() => {}"
    >
      <template #trailing>
        <UserDialogButton />
      </template>
    </toolbar>
    <ion-content>
      <div class="container mt-10">
        <h3
          class="m-4 text-3xl"
          data-cy="header-faq"
        >
          {{ t('toolbar.hilfe') }}
        </h3>
        <faq-item
          v-for="faq in faqs"
          :key="faq.frage"
          :faq="faq"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import AButton from "@/components/Base/AButton";
import Toolbar from "@/components/Navigation/Toolbar";
import useUser from "@/composables/useUser";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonImg,
  IonPage,
  onIonViewWillEnter,
  IonCard,
} from "@ionic/vue";
import { computed, defineComponent } from "vue";
import UserDialogButton from "@/components/User/UserDialogButton.vue";
import { useStore } from "@/composables/useTypedStore";
import { useFaqs } from "@/composables/useFaqs";
import FaqItem from "@/components/Faq/faq-item.vue";

export default defineComponent({
  name: "FaqPage",
  components: {
    AButton,
    Toolbar,
    IonPage,
    IonButton,
    IonButtons,
    IonContent,
    IonImg,
    UserDialogButton,
    IonCard,
    FaqItem
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const { user } = useUser();
    const store = useStore();

    const { faqs } = useFaqs();

    onIonViewWillEnter(() => {
      store.commit("app/showBottomNavigation");
    });

    return {
      user,
      faqs,
      t
    };
  },
});
</script>

<style scoped lang="scss">
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary);
}


ion-card {
  box-shadow: $boxShadow;
}
</style>