import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonIcon = _resolveComponent("IonIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "text-xl inline-block pill",
    onClick: _cache[0] || (_cache[0] = (e) => _ctx.openPopover( e, 'sortby', { items: _ctx.sortTerms, preselectedItem: _ctx.sortTerm }, (res) => _ctx.$emit('update:sortTerm', res)))
  }, [
    _createTextVNode(" Sortierung: " + _toDisplayString(_ctx.sortTerm && _ctx.sortTerm.label) + " ", 1),
    (_ctx.sortTerm && _ctx.sortTerm.orderBy === 'asc')
      ? (_openBlock(), _createBlock(_component_IonIcon, {
          key: 0,
          icon: _ctx.chevronUpOutline,
          size: "default"
        }, null, 8, ["icon"]))
      : (_ctx.sortTerm && _ctx.sortTerm.orderBy === 'desc')
        ? (_openBlock(), _createBlock(_component_IonIcon, {
            key: 1,
            icon: _ctx.chevronDownOutline,
            size: "default"
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true)
  ]))
}