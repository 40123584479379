<template>
  <ion-item lines="none" data-cy="text-input-item">
    <ion-grid class="p-0 mb-8">
      <ion-row class="ion-wrap d-flex items-center">
        <input-label>{{ label }}</input-label>
        <ion-col class="ion-float-right" />
      </ion-row>
      <ion-row class="mb-4">
        <AppMap
          class="hzbaMap"
          :identifier="identifier"
          :inputJson="modelValue"
          :mapSettings="mapSettings"
          :readonly="readonly"
          @update:input-json="onChanged"
        />
      </ion-row>
      <input-error :errors="errors" />
    </ion-grid>
  </ion-item>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow, IonText, IonTextarea } from "@ionic/vue";
//Interfaces & Enums
import { MapSettingsInterface } from "@/types/map/interfaces";
//Components
import InputError from "@/components/hzba/Base/Input/InputElements/InputError";
import InputLabel from "@/components/hzba/Base/Input/InputElements/InputLabel";
import AppMap from "@/components/v2/App/AppMap.vue";

export default defineComponent({
  name: "HzbaGeoJsonInput",
  emits: ['update:modelValue', 'socketUpdate'],
  components: {
    AppMap,
    InputError,
    InputLabel,
    IonItem,
    IonCol,
    IonRow,
    IonGrid,
    IonText,
    IonInput,
    IonLabel,
    IonTextarea
  },
  props: {
    modelValue: { type: Object, default: undefined, },
    identifier: { type: String, required: true },
    label: { type: String, required: true },
    mapSettings: { type: Object as PropType<MapSettingsInterface>, required: true },
    errors: { type: Array, default: () => [], },
    readonly: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    function onChanged(e: any) {
      emit("update:modelValue", e);
      emit('socketUpdate')
    }

    return {
      onChanged
    }
  },
})
</script>

<style scoped lang="scss">
ion-label {
  max-width: unset !important;
}

ion-textarea {
  background: $grey-100;
  border: 1px solid $grey-300;
  border-radius: 5px;
  min-height: 150px;
  text-align: left;
  padding: 16px;
  //margin-right: 16px;
  margin-bottom: 16px;
}

.error-text {
  color: var(--error100);
}

</style>

<style>
textarea {
  min-height: 150px;
}

.hzbaMap {
  height: 500px;
  width: 100%;
}
</style>