import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d1b8dc9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-xs" }
const _hoisted_2 = {
  key: 0,
  class: "text-xs text-gray-500"
}
const _hoisted_3 = {
  key: 1,
  class: "text-xs text-gray-500"
}
const _hoisted_4 = { class: "text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.useIdentifierFrageFinder(_ctx.ba, _ctx.fragesPath)?.getCurrentInput())
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString('\u{2611} ')))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString('\u{2610} '))),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.label), 1)
  ]))
}