<template>
  <pdf-a4-page no-margin :pdf-key="lang" class="pdf-content">
    <div class="landingpage-background w-screen h-screen">
      <div class="flex flex-col justify-between" style="height:1020px">
     <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>
      <!-- Header Ende-->
      <div class="text-xl md:text-xl font-bold">Geplante Baumaßnahme</div>
 <div class="content h-full max-h-full">
      <!-- Geplante Baumaßnahme Content Start-->

      <div class="grid grid-cols-8 justify-items-stretch items-center gap-y-3">
        <!-- Baumaßnahme Ende-->
        <div class="col-span-2 text-sm font-bold border-solid rounded-md whiteYellowBorderColor">Frage </div>
        <div class="text-sm font-bold col-span-3 border-solid rounded-md whiteYellowBorderColor">Beschreibung/Eingabewerte</div>
        <div class="col-span-3 text-sm font-bold border-solid rounded-md whiteYellowBorderColor">
          Weitere Eingaben
        </div>
          <!-- Baumaßnahme Start-->
        <div class="text-xs col-span-2  row-span-2" >
          Art:
          <span class="text-gray-500 text-xs">{{
            getAuswahlLabel(ba,'rippistein-bauflaechen.allgemein-tab.geplante-baumassnahme-group.baumassnahme.baumassnahme-group.baumassnahmeart')
          }}</span>
        </div>
        <div class="text-xs col-span-3 " >
          Masttyp:
          <span class="text-xs text-gray-500">
            {{
              getAuswahlLabel(
                ba,
                'rippistein-bauflaechen.allgemein-tab.geplante-baumassnahme-group.baumassnahme.baumassnahme-group.masttyp'
              )
            }}</span>
        </div>
        <div class="text-xs col-span-3">
          Mastbild:
          <span class="text-xs text-gray-500">
            {{
              getAuswahlLabel(
                ba,
                'rippistein-bauflaechen.allgemein-tab.geplante-baumassnahme-group.baumassnahme.baumassnahme-group.mastbild'
              )
            }}</span>
        </div>
        <div class="text-xs col-span-3 " >
          Arbeitsfläche Größe:
          <span class="text-gray-500 text-xs">{{
            useIdentifierFrageFinder(
              ba,
              'rippistein-bauflaechen.allgemein-tab.allgemein-group.flaechengroesse'
            )['eingabeText']
          }} {{ '\u{33A1} ' }}</span>
        </div>
        <div class="text-xs col-span-3">
          Mast-Bauflächenkante:
          <span class="text-xs text-gray-500">
            {{
              useIdentifierFrageFinder(
                ba,
                'rippistein-bauflaechen.allgemein-tab.allgemein-group.abstand-mast-bauflaechenkante'
              )['eingabeText']
            }} m</span>
        </div>


        <is-vorhanden-char-and-label :ba="ba"
          fragesPath="rippistein-bauflaechen.allgemein-tab.geplante-baumassnahme-group.baumassnahme.baumassnahme-group.masthoehe-bekannt"
          label="Masthöhe vorhanden"
          class="col-span-2 "
          />


        <div class="text-xs col-span-5">
          Masthöhe:
          <span class="text-xs text-gray-500">
            {{
              useIdentifierFrageFinder(
                ba,
                'rippistein-bauflaechen.allgemein-tab.geplante-baumassnahme-group.baumassnahme.baumassnahme-group.masthoehe'
              )['eingabeText']
            }} m</span>
        </div>
        <!-- Seilzug Start-->
        <is-vorhanden-char-and-label :ba="ba"
          fragesPath="rippistein-bauflaechen.allgemein-tab.geplante-baumassnahme-group.seilzug-vorhanden"
          label="Seilzug vorhanden"
          class="col-span-8 "
          />

        <!-- Seilzug Ende-->
        <!-- Fundamentverstärkung Start-->
        <is-vorhanden-char-and-label :ba="ba"
          fragesPath="rippistein-bauflaechen.allgemein-tab.geplante-baumassnahme-group.fundamentverstaerkung-vorhanden"
          label="Fundamentverstärkung"
          class="col-span-2 "
          />


        <div class="text-xs col-span-6">
          <span class="text-xs text-gray-500">
            {{
              useIdentifierFrageFinder(
                ba,
                'rippistein-bauflaechen.allgemein-tab.geplante-baumassnahme-group.fundamentverstaerkung-beschreibung'
              )['eingabeText']
            }}</span>
        </div>
        <!-- Fundamentverstärkung Ende-->
        <!-- Mastverstäkrung Start-->
        <is-vorhanden-char-and-label :ba="ba"
          fragesPath="rippistein-bauflaechen.allgemein-tab.geplante-baumassnahme-group.mastverstaerkung-vorhanden"
          label="Mastverstärkung"
          class="col-span-2 "
          />

        <div class="text-xs col-span-6">
          <span class="text-xs text-gray-500">
            {{
              useIdentifierFrageFinder(
                ba,
                'rippistein-bauflaechen.allgemein-tab.geplante-baumassnahme-group.mastverstaerkung-beschreibung'
              )['eingabeText']
            }}</span>
        </div>
        <!-- Mastverstäkrung Ende-->
        <!-- Sonstiges Start-->
        <is-vorhanden-char-and-label :ba="ba"
          fragesPath="rippistein-bauflaechen.allgemein-tab.geplante-baumassnahme-group.sonstiges-vorhanden"
          label="Sonstiges" class="col-span-2  text-xs"
          />


        <div class="text-xs col-span-6">
          <span class="text-xs text-gray-500">
            {{
              useIdentifierFrageFinder(
                ba,
                'rippistein-bauflaechen.allgemein-tab.geplante-baumassnahme-group.sonstiges-beschreibung'
              )['eingabeText']
            }}</span>
        </div>
        <!-- Sonstiges Ende-->
        <!-- Zusätzliche Maßnahmen Start-->
        <is-vorhanden-char-and-label :ba="ba"
          fragesPath="rippistein-bauflaechen.allgemein-tab.geplante-baumassnahme-group.zus-massnahmen-vorhanden"
          label="Zusätzliche Maßnahmen:" class="col-span-2 "
          />
        <div class="text-xs col-span-3">
          <span class="text-xs text-gray-500">
            {{
              useIdentifierFrageFinder(
                ba,
                'rippistein-bauflaechen.allgemein-tab.geplante-baumassnahme-group.zus-massnahmen-beschreibung'
              )['eingabeText']
            }}</span>
        </div>
        <div class="text-xs" >Art:</div>
        <div class="text-xs col-span-2 grid grid-cols-1">

          <div v-for="(v,key) in useIdentifierFrageFinder(
                ba,
                'rippistein-bauflaechen.allgemein-tab.geplante-baumassnahme-group.zus-massnahmen-art'
              )['eingabeMehrfachAuswahlWerts']" :key="key"
          class="text-xs text-gray-500"
              >
                {{v}}
        </div>
        </div>
        <!-- Zusätzliche Maßnahmen Ende-->

        <div class="col-span-2 text-sm font-bold border-solid rounded-md whiteYellowBorderColor">Analyse </div>
        <div class="text-sm font-bold col-span-6 border-solid rounded-md whiteYellowBorderColor">Analyseergebnisse</div>

      <div class="text-xs md:text-xs col-span-2 ">
        Betroffene Flurstücke:</div>
        <div class="text-xs text-gray-500 col-span-6 grid grid-cols-3">
          <div v-for="(v,key) in useIdentifierFrageFinder(
            ba,
            'rippistein-bauflaechen.allgemein-tab.allgemein-group.betroffeneflurstuecke'
          ).getCurrentInput()?.split(';')?.sort()" :key="key"
          class="text-xs text-gray-500"
              >
                {{v}}
        </div>
        </div>

      </div>
      <!-- Geplante Baumaßnahme Content Ende-->

      </div>
      <bauflaechen-footer
        :ba="ba"
        :immobilie="immobilie"
        :lang="lang"
        class=""
      />
      </div>
    </div>
  </pdf-a4-page>
</template>

<script lang="ts">
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useIdentifierFrageFinder,getAuswahlLabel } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import { getPhotoUrl } from '@/utilities/get-media-url';
import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';

import PdfChip from '../Components/PdfChip.vue';
import { useBauflaechen } from './useBauflaechen';
import UniversalPdfImageRow from '../Components/UniversalPdfImageRow.vue';
import IsVorhandenCharAndLabel from './isVorhandenCharAndLabel.vue';

import TngHeader from './TngHeader.vue';
import BauflaechenFooter from './BauflaechenFooter.vue';

export default defineComponent( {
  name: 'AllgemeinPage',
  components: {
    PdfA4Page,
    PdfChip,
    UniversalPdfImageRow,
    IsVorhandenCharAndLabel,
    TngHeader,
    BauflaechenFooter
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup ( props: any ) {
    const { t } = useI18n( { useScope: 'global' } );
    const uUser = useUser();
    const { getAnmerkungenGutachter ,} = useBauflaechen();
    return {
      user: uUser.user,
      t,
      useIdentifierFrageFinder,
      moment,
      getPhotoUrl,
      getAnmerkungenGutachter,
      getAuswahlLabel
    };
  },
} );
</script>
<style scoped>
* {
  color: rgb(23,68,81);
  font-family: var(--secondaryFont);
}

.pdf-content {
  width: 800px;
  margin: 0 auto;
  color: var(--white100);
}

.landingpage-background {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 700px;
  height: 1050px;
  color: var(--black100);
}

.trenner {
  height: 1px;
  border-width: 0;
  color: var(--black100);
  background-color: var(--black100);
}

.content {
  height: 800px;
}
</style>
