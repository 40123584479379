<template>
  <!--  <ion-button-->
  <!--    fill="outline"-->
  <!--    :color="toolbarMode ? 'light' : 'dark'"-->
  <!--    class="ml-4 searchbar-button"-->
  <!--    :class="toolbarMode ? 'mr-4' : ''"-->
  <!--  >-->
  <!--    <ion-icon :icon="swapVerticalOutline" />-->
  <!--  </ion-button>-->

  <div 
    class="text-xl inline-block pill"
    @click="(e) => openPopover( e, 'sortby', { items: sortTerms, preselectedItem: sortTerm }, (res) => $emit('update:sortTerm', res))"
  >
    Sortierung: {{ sortTerm && sortTerm.label }}
    <IonIcon
      v-if="sortTerm && sortTerm.orderBy === 'asc'"
      :icon="chevronUpOutline"
      size="default"
    />
    <IonIcon
      v-else-if="sortTerm && sortTerm.orderBy === 'desc'"
      :icon="chevronDownOutline"
      size="default"
    />
  </div>
</template>

<script lang="ts">
import {swapVerticalOutline} from "ionicons/icons";
import {IonIcon} from '@ionic/vue';
import {popoverHelper} from "../../utilities/popover-helper";
import {defineComponent, PropType} from "vue";
import {SortTermActive} from "@/composables/Sort/SortTerms";
import { chevronUpOutline, chevronDownOutline } from "ionicons/icons";

export default defineComponent({
  name: "SortBy",
  components: {IonIcon},
  props: {
    sortTerms: {
      type: Object,
      required: true,
    },
    sortTerm: {
      type: Object as PropType<SortTermActive>,
      default: undefined,
    },
    toolbarMode: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // const sortTermValues = props.sortTerms.map(el => el. Object.values(props.sortTerms[0]);

    return {
      swapVerticalOutline,
      chevronUpOutline,
      chevronDownOutline,
      // sortTermValues,
      openPopover: popoverHelper,
    }
  }
})
</script>

<style scoped>
.pill {
  padding: 4px 8px;
  background: #F8F9FB;
  border-radius: 12px;
  margin-right: 6px;
  font-size: 14px;
  user-select: none;
  border: 1px solid #efefef;
  flex-shrink: 0;

  &:hover {
    cursor: pointer;
  }
}
</style>