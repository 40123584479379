import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "icon-wrapper flex-shrink-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressZeroIcon = _resolveComponent("ProgressZeroIcon")!
  const _component_ProgressQuarterIcon = _resolveComponent("ProgressQuarterIcon")!
  const _component_ProgressHalfIcon = _resolveComponent("ProgressHalfIcon")!
  const _component_ProgressThreeQuarterIcon = _resolveComponent("ProgressThreeQuarterIcon")!
  const _component_ProgressCompletedIcon = _resolveComponent("ProgressCompletedIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.progress <= 0 || !$props.progress)
      ? (_openBlock(), _createBlock(_component_ProgressZeroIcon, { key: 0 }))
      : ($props.progress <= 33)
        ? (_openBlock(), _createBlock(_component_ProgressQuarterIcon, { key: 1 }))
        : ($props.progress <= 66)
          ? (_openBlock(), _createBlock(_component_ProgressHalfIcon, { key: 2 }))
          : ($props.progress < 100)
            ? (_openBlock(), _createBlock(_component_ProgressThreeQuarterIcon, { key: 3 }))
            : ($props.progress >= 100)
              ? (_openBlock(), _createBlock(_component_ProgressCompletedIcon, {
                  key: 4,
                  "data-cy": "completed"
                }))
              : _createCommentVNode("", true)
  ]))
}