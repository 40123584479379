<template>
  <div class="fixed acon-gradient update-screen">
    <div class="flex justify-center flex-col">
      <MovingLayersLogo/>
      <div class="mt-8 text-center">
        We are optimizing your app experience.
      </div>
      <ion-spinner
        name="dots"
        duration="1000"
        class="inline-block m-auto mt-2"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { IonSpinner } from "@ionic/vue";
//Icons
import MovingLayersLogo from '@/components/Icons/MovingLayersLogo.vue';

export default {
  name: "UpdateScreen",
  components: {
    IonSpinner,
    MovingLayersLogo,
  }
}
</script>

<style scoped lang="scss">


.acon-gradient {
  background: linear-gradient(101deg, white, white);
}

.update-screen {
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white100);
}

</style>