<template>
  <ion-page>
    <toolbar
      :title="t(`${organisationPrefix}toolbar.mobile.createObject`)"
    >
      <template #leading>
        <ion-buttons slot="start">
          <ion-button
            fill="clear"
            class="px-0 -ml-4"
            size="small"
            @click="() => router.back()"
          >
            <ion-icon
              :icon="arrowBack"
              size="large"
              class="whiteColor"
            />
          </ion-button>
        </ion-buttons>
      </template>
    </toolbar>
    <div class="createPropertyWrapper">
      <ion-content>
        <div class="createPropertyContent container p-6 mt-12">
          <div class="formHolder">
            <div class="headline3 mb-5">
              {{ t(`${organisationPrefix}createProperty.createObject`) }}
            </div>
            <div class="sectionItem mb-4">
              <div class="label">
                {{ t('createProperty.name') }}*
              </div>
              <div class="value">
                <AppInput
                  v-model="body.name"
                  :placeholder="t('createProperty.typeLabel')"
                />
              </div>
            </div>
            <div class="sectionItem mb-4">
              <div class="label">
                {{ t(`${organisationPrefix}createProperty.externalObjNumber`) }}
              </div>
              <div class="value">
                <AppInput
                  v-model="body.externeObjektNr"
                  :placeholder="t('createProperty.typeLabel')"
                />
              </div>
            </div>
            <div class="imagesSection mb-4">
              <div class="label">
                {{ t('createProperty.images') }}
              </div>
              <div class="value">
                <ion-item @click="addPhotos()">
                  {{ t('createProperty.addPhotos') }}
                </ion-item>
                <div
                  v-if="images.length > 0"
                  class="imagesList mt-4"
                >
                  <div
                    v-for="(item, i) in images"
                    :key="item.id"
                    class="imageItem"
                    @click="removePhoto(i)"
                  >
                    <ion-icon :icon="closeCircleOutline" />
                    <img :src="item.webPath">
                  </div>
                </div>
              </div>
            </div>
            <div class="sectionItem mb-4">
              <div class="label">
                {{ t('createProperty.street') }}
              </div>
              <div class="value">
                <AppInput
                  v-model="body.strasse"
                  :placeholder="t('createProperty.typeLabel')"
                />
              </div>
            </div>
            <div class="sectionItem mb-4">
              <div class="label">
                {{ t('createProperty.plz') }}
              </div>
              <div class="value">
                <AppInput
                  v-model="body.plz"
                  :placeholder="t('createProperty.typeLabel')"
                />
              </div>
            </div>
            <div class="sectionItem mb-4">
              <div class="label">
                {{ t('createProperty.city') }}
              </div>
              <div class="value">
                <AppInput
                  v-model="body.stadt"
                  :placeholder="t('createProperty.typeLabel')"
                />
              </div>
            </div>
            <div class="sectionItem mb-4">
              <div class="label">
                {{ t('createProperty.bezirk') }}
              </div>
              <div class="value">
                <AppInput
                  v-model="body.stadtbezirk"
                  :placeholder="t('createProperty.typeLabel')"
                />
              </div>
            </div>
            <div class="sectionItem mb-4">
              <div class="label">
                {{ t(`${organisationPrefix}createProperty.buildingYear`) }}*
              </div>
              <div class="value">
                <AppInput
                  v-model="body.baujahr"
                  :placeholder="t('createProperty.typeLabel')"
                />
              </div>
            </div>
            <div v-if="isNetworkAvailable" class="geolocationItem mb-4">
              <div class="label mb-4">
                {{ t('createProperty.geoLocation') }}*
              </div>
              <div class="value">
                <AppMap
                  :identifier="'objectLocation'"
                  :map-settings="currentProject.mapConfig?.createPropertyMap"
                  :createPropertyMap="true"
                  @update:input-json="onChanged"
                  @update:mapSearch="handleMapSearchResult"
                  @update:clearMapSearchInputs="clearMapSearchResult"
                />
              </div>
            </div>
          </div>
          <div class="buttonsHolder">
            <AButton
              :disabled="isButtonActive ? false : true"
              :btn-primary="true"
              class="px-16 py-6 text-lg"
              @click="handleCreateProperty()"
            >
              {{ t('createProperty.submit') }}
            </AButton>
            <AButton
              :btn-secondary="true"
              class="px-16 py-6 text-lg"
              @click="router.back()"
            >
              {{ t('createProperty.cancel') }}
            </AButton>
          </div>
        </div>
      </ion-content>
    </div>
  </ion-page>
</template>

<script setup lang="ts">
import { useStore } from "@/composables/useTypedStore";
import { IonButton, IonButtons, IonContent, IonIcon, IonItem, IonPage, useIonRouter } from '@ionic/vue';
import { arrowBack, closeCircleOutline } from "ionicons/icons";
import { ComputedRef, computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
//Endpoints
import CreatePropertyApi from '@/api/CreatePropertyApi';
//Models
import Immobilie from "@/models/immobilie.model";
//Composables
import { useProperties } from "@/composables/Property/useProperties";
//Components
import AButton from '@/components/Base/AButton.vue';
import Toolbar from "@/components/Navigation/Toolbar";
import AppInput from '@/components/v2/App/AppInput.vue';
import AppMap from '@/components/v2/App/AppMap.vue';
//Helpers
import useImmobilieHelper from "@/composables/Property/useImmobilieHelper";
import { usePhotoRecording } from "@/composables/usePhotoRecording";
import User from "@/models/user";
import { default as QueryString } from 'qs';

//Variables
const store = useStore()
const router = useIonRouter()
const isNetworkAvailable = computed(() => store.state.app.networkConnected)
const { t } = useI18n({ useScope: 'global' })
const { openPhotoDialog } = usePhotoRecording();
const { loadAllProperties, uploadPropertyVorschaubild } = useProperties();
const organisationPrefix: ComputedRef<string> = computed(() => {
  const currentUser: User | null = User.query().first();

  return currentUser ? currentUser.organisationPrefix : "";
});

const body = ref({
  name: '',
  strasse: '',
  plz: '',
  stadt: '',
  stadtbezirk: '',
  externeObjektNr: '',
  baujahr: '',
  geolocationLat: '',
  geolocationLon: '',
  projects: {
    disconnect: [],
    connect: [
      {
        id: null,
        position: {
          end: true
        }
      }
    ]
  }
});
const images = ref([] as any)
const currentProject = computed(() => store.state.user.currentUserProject)


//Computed Methods
const isButtonActive = computed(() => {
  let value = false

  if(isNetworkAvailable.value) {
    value = buttonActiveOnline()
  } else {
    value = buttonActiveOffline()
  }

  return value
})

//Lifecycle Hooks
onMounted(() => {
  body.value.projects.connect[0].id = currentProject.value.id
})

//Methods
function buttonActiveOnline() {
  let value = false
  if(body.value.name !== '' && body.value.baujahr !== '' && body.value.geolocationLat !== '' && body.value.geolocationLon !== '') {
    value = true
  } else {
    value = false
  }
  return value
}

function buttonActiveOffline() {
  let value = false
  if(body.value.name !== '' && body.value.baujahr !== '') {
    value = true
  } else {
    value = false
  }
  return value
}

function handleMapSearchResult(data: any) {
  body.value.strasse = data.address.road ? data.address.road : ''
  body.value.plz = data.address.postcode ? data.address.postcode : ''
  body.value.stadt = data.address.city ? data.address.city : ''
  body.value.stadtbezirk = data.address.city_district ? data.address.city_district : ''
  body.value.geolocationLat = data.lat
  body.value.geolocationLon = data.lon
}

function clearMapSearchResult() {
  body.value.strasse = ''
  body.value.plz = ''
  body.value.stadt = ''
  body.value.stadtbezirk = ''
  body.value.geolocationLat = ''
  body.value.geolocationLon
}

function onChanged(event: any) {
  if(event.features[0]?.geometry.coordinates) {
    body.value.geolocationLon = event.features[0].geometry.coordinates[0]
    body.value.geolocationLat = event.features[0].geometry.coordinates[1]
  }
}

async function addPhotos () {
  const uploadLimit = 1;
  const photoRes = await openPhotoDialog(t, uploadLimit, {
    hideFile: true,
  });

  if(photoRes.success && photoRes.data && photoRes.data.length > 0) {
    images.value = photoRes.data
  }
}

function removePhoto(index: number) {
  images.value.splice(index, 1)
}

async function handleCreateProperty() {
  try {
    const response = await CreatePropertyApi.postProperty(body.value)
    if(response.status === 200) {
      if ( images.value.length > 0) {
        await uploadPropertyVorschaubild(response.data.data, images.value)
      }
      await loadAllProperties()
      router.navigate('/properties', 'forward', 'pop')
    }
  } catch(err: any) {
    const immobilieObject = {
      id: `0.${Math.floor(Math.random() * 10000000)}`,
      isDownloaded: true,
      hasUnsavedChanges: true,
      vorschaubild: images.value,
      ...body.value
    }
    const result = await Immobilie.insertOrUpdate({ data: immobilieObject });
    await Immobilie.dispatch('$updateLocally', { data: result.immobilie });
    
    router.navigate('/properties', 'forward', 'pop')
  }
}
</script>

<style lang="scss">
.createPropertyWrapper {
  height: 100%;

  .createPropertyContent {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;

    .formHolder {
      max-width: 800px;
      width: 100%;
      margin: 0 auto;

      .sectionItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
  
        .label, .value {
          width: calc(50% - 8px);
        }
  
        .value {
          background: var(--background100);
        }
      }

      .geolocationItem {
        display: flex;
        flex-direction: column;

        #objectLocation {
          height: 500px;
        }
      }

      .imagesSection {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
  
        .label, .value {
          width: calc(50% - 8px);
        }

        .value {
          ion-item {
            --ion-background-color: var(--background100);
            --border-color: transparent;
          }

          .imagesList {
            display: flex;
            flex-direction: row;
            gap: 4px;

            .imageItem {
              position: relative;
              display: flex;
              width: 50px;
              height: 50px;
              border-radius: 10px;
              overflow: hidden;

              ion-icon {
                position: absolute;
                color: var(--black100);
                background: var(--white100);
                top: 0;
                right: 0;
              }

              img {
                width: 50px;
                height: 50px;
              }
            }
          }
        }
      }
    }

    .buttonsHolder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;

      @media (min-width: 1024px) {
        flex-direction: row;
      }
    }
  }
}

ion-icon.whiteColor {
  color: var(--white100);
}
</style>
