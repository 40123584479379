

<template>
  <section class="pdf-content">
    <div>
      <PageCounterStart/>
      <PageCounter
        :displayCounter="false"
        :displayMaxPages="false"
      />
      <cover-page :ba="ba" :immobilie="immobilie" :lang="lang"></cover-page>
      <allgemein :ba="ba" :immobilie="immobilie" :lang="lang"></allgemein>
      <anmerkungen :ba="ba" :immobilie="immobilie" :lang="lang"></anmerkungen>
      <arbeitsflaeche-mast
        :ba="ba"
        :immobilie="immobilie"
        :lang="lang"
      ></arbeitsflaeche-mast>
      <zuwegung-mast
        :ba="ba"
        :immobilie="immobilie"
        :lang="lang"
      ></zuwegung-mast>
      <!-- <grabenquerungen :ba="ba" :immobilie="immobilie" :lang="lang"></grabenquerungen>
      <bauwerke :ba="ba" :immobilie="immobilie" :lang="lang"></bauwerke> -->
      <fremdleitung :ba="ba" :immobilie="immobilie" :lang="lang"></fremdleitung>
      <PageCounterEnd/>
    </div>
  </section>
</template>


<script lang="ts">
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import CoverPage from './CoverPage.vue';
import Allgemein from './Allgemein.vue';
import ArbeitsflaecheMast from './ArbeitsflaecheMast.vue';
import Fremdleitung from './Fremdleitung.vue';
import ZuwegungMast from './ZuwegungMast.vue';
import Anmerkungen from './Anmerkungen.vue';
import Bauwerke from './Bauwerke.vue';
import Grabenquerungen from './Grabenquerungen.vue';
import PageCounter from '@/components/Pdf/Components/PageCounter';
import PageCounterStart from '@/components/Pdf/Components/PageCounterStart';
import PageCounterEnd from '@/components/Pdf/Components/PageCounterEnd';

export default defineComponent({
  name: 'PdfRippisteinBauflaechenTemplate',
  components: {
    CoverPage,
    Allgemein,
    ArbeitsflaecheMast,
    ZuwegungMast,
    Fremdleitung,
    Anmerkungen,
    Bauwerke,
    Grabenquerungen,
    PageCounter,
    PageCounterStart,
    PageCounterEnd  
    },
  props: {
     ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();
    return {
      user: uUser.user,
      t,
    };
  },
});
</script>

<style scoped>
.pdf-content {
  width: 800px;
  margin: 0 auto;
  color: var(--white100);
}

* {
  color: var(--black100);
  font-family: var(--secondaryFont);
}
</style>