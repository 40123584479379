<template>
  <button
    :type="type"
    :disabled="disabled"
    :class="[
      disabled ? 'disabled' : '',
      acon ? 'acon' : '',
      blueSolid ? 'blueSolid' : '',
      flatWhite ? 'flat-white' : '',
      flatAubergine ? 'flat-aubergine' : '',
      btnPrimary ? 'btn-primary h-8 px-6 inline-flex justify-center items-center rounded-full' : '',
      btnSecondary ? 'btn-secondary h-8 px-4 inline-flex justify-center items-center rounded-full' : '',
      btnSecondarySm ? 'btn-secondary h-6 px-2 inline-flex justify-center items-center rounded-full' : '',
      btnTertiary ? 'btn-tertiary h-6 px-4 mb-1 inline-flex justify-center items-center rounded-full' : ''
    ]"
    @click="( e ) => $emit('click', e)"
  >
    <slot class="align-middle" />
  </button>
</template>

<script>
export default {
  name: "AButton",
  props: {
    type: {
      type: String,
      default: 'Button'
    },
    disabled: { type: Boolean, required: false },
    acon: { type: Boolean, default: false},
    flatWhite: { type: Boolean, default: false},
    blueSolid: { type: Boolean, default: false},
    mintSolid: { type: Boolean, default: false},
    flatAubergine: { type: Boolean, default: false },
    btnPrimary: { type: Boolean, default: false },
    btnSecondary: { type: Boolean, default: false },
    btnSecondarySm: { type: Boolean, default: false },
    btnTertiary: { type: Boolean, default: false }
  },
  emits: ['click'],
}
</script>

<style scoped lang="scss">

button.disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

button.acon {
  margin-left: 1.5rem;
  margin-top: 1rem;
  border-radius: 100rem;
  padding: 1rem;
  max-width: 350px;
  font-size: 1rem;
  color: var(--error100);
  border: solid 2px transparent;
  background-image: $aconGradient;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

button.acon:hover {
  box-shadow: none;
  color: var(--white100);
}

button.flat-white {
  background: var(--white100);
  padding: 16px 8px;
}
button.flat-aubergine {
  background: var(--white100);
  border: 1px solid var(--primary);
  padding: 1rem;
  border-radius: 36px;
}
button.blueSolid {
  background: #145aff;
  color: var(--white100);
  font-weight: 550;
  padding: 12px 8px;
  border-radius: 36px;
  width: 100%;
  max-width: 350px;
}

.btn-primary {
  background: var(--secondary);
  color: var(--primary);
} 

button.btn-primary:hover {
  background: var(--secondaryHover);
  cursor: pointer;
}

button.btn-primary:focus {
  background: var(--secondaryPressed);
}

.btn-secondary {
  background: var(--white100);
  color: var(--primary);
  border: 1px solid var(--primary);
}

button.btn-secondary:hover {
  background: rgba(255,165,226,0.22)
}
button.btn-secondary:focus {
  background: var(--primary);
  color: var(--white100);
}

.btn-tertiary {
  background: rgba(0, 0, 0, 0.6);
  color: var(--white100);
}

button.btn-tertiary:hover {
  background:rgba(0, 0, 0, 0.4);
}
button.btn-tertiary:focus {
  background: rgba(0, 0, 0, 0.9);
}

</style>