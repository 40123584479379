<template>
  <pdf-a4-page
    no-margin
    :pdf-key="lang"
    class="pdf-content"
    v-for="(anmerkungPage, key) in getAnmerkungenPages(ba)"
    :key="key"
  >
    <div class="landingpage-background w-screen h-screen">
      <div class="flex flex-col" style="height: 1000px">
        <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>
        <div class="text-xl md:text-xl font-bold">
          Geplante Baumaßnahme - Fortsetzung
        </div>

        <div class="mt-4 text-sm md:text-sm font-bold border-solid rounded-md whiteYellowBorderColor">
          Anmerkungen
        </div>
        <div v-if="anmerkungPage['anmerkungen'].length >0 " class="ml-2" >
          <div
            v-for="(anmerkung, key) in anmerkungPage['anmerkungen']"
            :key="key"
            style="
              border-style: solid;
              border-color: var(--white100) var(--white100) var(--white100) rgb(216, 215, 26);
              border-radius: 5px;
            "
            class="mt-2"
          >
            <div class="ml-2 text-xs">
              Titel:
              <span class="text-xs text-gray-500">{{ anmerkung.title }}, {{ anmerkung.lfndnr }}</span>
            </div>
            <div class="text-xs md:text-xs ml-2">
              Anmerkung:
              <span class="text-xs text-gray-500">{{
                anmerkung.anmerkung
              }}</span>
            </div>
            <oneto-five-image-div
              :ba="undefined"
              :immobilie="undefined"
              :fragesPath="undefined"
              :lang="lang"
              :imageObjects="
                anmerkung.bilder === [] ? undefined : anmerkung.bilder
              "
              class="ml-2 col-span-8"
            >
            </oneto-five-image-div>
          </div>
        </div>
        <div v-else class="justify-center items-center col-span-5 mt-1"
      style="
        background-color: rgb(235, 239, 240);
        border-style: solid;
        border-color: rgb(235, 239, 240) rgb(235, 239, 240) rgb(235, 239, 240)
          rgb(235, 239, 240);
        border-radius: 5px;"
        >

      <p class="text-xs text-center">Keine Anmerkungen aufgenommen</p>

        </div>
      </div>
      <bauflaechen-footer
        :ba="ba"
        :immobilie="immobilie"
        :lang="lang"
        class=""
      />
    </div>
  </pdf-a4-page>
</template>

<script lang="ts">
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useIdentifierFrageFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import { getPhotoUrl } from '@/utilities/get-media-url';
import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';

import PdfChip from '../Components/PdfChip.vue';
import { useBauflaechen } from './useBauflaechen';
import UniversalPdfImageRow from '../Components/UniversalPdfImageRow.vue';
import IsVorhandenCharAndLabel from './isVorhandenCharAndLabel.vue';

import TngHeader from './TngHeader.vue';
import BauflaechenFooter from './BauflaechenFooter.vue';
import OnetoFiveImageDiv from './OnetoFiveImageDiv.vue';

export default defineComponent({
  name: 'AllgemeinPage',
  components: {
    PdfA4Page,
    PdfChip,
    UniversalPdfImageRow,
    IsVorhandenCharAndLabel,
    TngHeader,
    BauflaechenFooter,
    OnetoFiveImageDiv,
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();
    const { getAnmerkungenPages } = useBauflaechen();
    return {
      user: uUser.user,
      t,
      useIdentifierFrageFinder,
      moment,
      getPhotoUrl,
      getAnmerkungenPages,
    };
  },
});
</script>
<style scoped>
* {
  color: rgb(23, 68, 81);
  font-family: var(--secondaryFont);
}

.pdf-content {
  width: 800px;
  margin: 0 auto;
  color: var(--white100);
}

.landingpage-background {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 700px;
  height: 1050px;
  color: var(--black100);
}

.trenner {
  height: 1px;
  border-width: 0;
  color: var(--black100);
  background-color: var(--black100);
}

.content {
  height: 800px;
}
</style>
