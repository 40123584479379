<template>
  <ion-item-sliding
    :disabled="!slideEnabled || isReadonly || disabled"
    data-cy="modal-item-block"
  >
    <ion-item-options side="start">
      <ion-item-option
        color="primary"
        @click.stop="() => $emit('duplicate')"
      >
        {{ t("hzba.buttons.duplizieren") }}
      </ion-item-option>
    </ion-item-options>
    <ion-item
      button
      :detail="false"
      :disabled="disabled"
      data-cy="ion-item-button"
      lines="none"
      class="py-2 px-0 item-block shadow-sm rounded-sm"
      @click="$emit('clickItem')"
    >
      <ion-grid class="pl-0">
        <ion-row class="flex-nowrap d-flex items-center">
          <ion-col
            v-if="addIcon"
            class="flex-grow-0 px-4"
          >
            <IonIcon :icon="addOutline" />
          </ion-col>
          <ion-col
            v-else
            class="flex-grow-0"
          >
            <progress-indicator :progress="progress" />
          </ion-col>

          <input-label>
            {{ title }}<span
              v-if="suffix"
              class="italic"
            > - {{ suffix }}</span>
          </input-label>

          <slot />

          <div
            v-if="chip1"
            style="margin-right: 1rem"
          >
            {{ chip1 }}
          </div>

          <div
            class="mr-4"
          >
            <pdf-chip
              v-if="maengelAmount > 0"
              :value="`${maengelAmount} ${ maengelAmount === 1 ? t('hzba.mangel.mangel') : t('hzba.mangel.maengel') }`"
              :color="'yellow'"
              mangel-amount-mode
            />
          </div>

          <div v-if="!addIcon">
            <img src="/assets/icon/ArrowIconRight.svg">
          </div>
        </ion-row>

        <input-error :errors="errors" />
      </ion-grid>
    </ion-item>

    <ion-item-options side="end">
      <ion-item-option
        color="danger"
        @click.stop="() => displayAlert()"
      >
        {{ t("hzba.buttons.loeschen") }}
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
</template>

<script>
import { useI18n } from 'vue-i18n';
import {
  IonItem,
  IonIcon,
  IonLabel,
  IonChip,
  IonGrid,
  IonRow,
  IonCol,
  IonItemOptions,
  IonItemOption,
  IonItemSliding,
} from "@ionic/vue";
import ProgressIndicator from "../../../ProgressIndicator";
import InputLabel from "@/components/hzba/Base/Input/InputElements/InputLabel";
import InputError from "./InputElements/InputError.vue";
import {computed, defineComponent} from "vue";
import {store} from "@/store/store";
import { addOutline } from 'ionicons/icons';
import PdfChip from "@/components/Pdf/Components/PdfChip";
import useAlert from '@/composables/useAlert';

export default defineComponent({
  name: "HzbaModalItemBlock",
  emits: ['delete'],
  components: {
    PdfChip,
    InputLabel,
    ProgressIndicator,
    IonItem,
    IonIcon,
    IonLabel,
    IonChip,
    IonGrid,
    IonRow,
    IonCol,
    IonItemOptions,
    IonItemOption,
    IonItemSliding,
    InputError,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    suffix: {
      type: String,
      default: ''
    },
    progress: {
      type: Number,
      default: -1,
    },
    chip1: {
      type: String,
      default: "",
    },
    maengelAmount: {
      type: Number,
      default: -1,
    },
    disabled: {

    },
    slideEnabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => []
    },
    addIcon: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: 'global' })
    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );
    const alert = useAlert();

    function displayAlert() {
      alert.showAdv(
        t("alerts.deleteTitle"),
        t("alerts.deleteDescription"),
        [
          { text: t("alerts.deleteCancel"), },
          { text: t("alerts.deleteConfirm"), handler: () => { emit('delete')} },
        ]
      )
    }

    return {
      displayAlert,
      isReadonly,
      addOutline,
      t
    }
  },
})
</script>

<style scoped>
.item-block {
  background: var(--white100);
}
</style>