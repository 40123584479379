<template>
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>89BBAB94-1F52-48DE-8B05-1D1B7033B28E</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="00_MovingLayers_UI_Design-System" transform="translate(-196.000000, -4004.000000)">
        <g id="Icon/Progress/0" transform="translate(196.000000, 4004.000000)" stroke="var(--lightGrey100)" stroke-width="2">
          <circle id="Oval" cx="11" cy="11" r="10"></circle>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProgressZeroIcon'
})
</script>

<style lang="scss">

</style>
