import HzbasPage from "@/pages/hzbas.vue";
import CreateProperty from '@/pages/property/CreateProperty.vue';
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import Demo from '../pages/demo.vue';
import Matterport from '../pages/hzba/_id/matterport.vue';
import Login from '../pages/login.vue';
import Logout from '../pages/logout.vue';
import Settings from '../pages/settings.vue';
import Signup from '../pages/signup.vue';

import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import ProjectSelection from '@/pages/ProjectSelection.vue';
import WaitingRoom from "@/pages/WaitingRoom.vue";
import FaqPage from "@/pages/faq.vue";
import ImpressumPage from "@/pages/impressum.vue";
import LogsPage from "@/pages/logs.vue";
import PropertiesPage from "@/pages/properties.vue";
import { removeCache } from "@/store/storage/ionicStorage";
import { store } from '@/store/store';
import { initHandlers } from '@/utilities/initHandler';
import useBestandsaufnahmeSockets from "@/composables/Bestandsaufnahme/useBestandsaufnahmeSockets";
import hzbaRouter from './hzba/index';
import propertyRouter from './property/index';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: "/properties"
    },
    {
        path: '/bas',
        name: 'bas',
        component: HzbasPage,
        meta: { requiresLoggedIn: true }
    },
    ...hzbaRouter,
    ...propertyRouter,
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { requiresLoggedOut: true }
    },
    {
        path: '/signup',
        name: 'signup',
        component: Signup,
        meta: { requiresLoggedOut: true}
    },
    {
        path: '/waiting-room',
        name: 'waitingRoom',
        component: WaitingRoom,
        meta: { requiresLoggedIn: true}
    },
    {
        path: '/project-selection',
        name: 'ProjectSelection',
        component: ProjectSelection,
        meta: { requiresLoggedIn: true}
    },
    {

        path: '/properties',
        name: 'propertiesList',
        component: PropertiesPage,
        meta: { requiresLoggedIn: true},
        children: [
            {
                path: 'map',
                name: 'propertiesMap',
                component: PropertiesPage,
                meta: { requiresLoggedIn: true }
            }
            ]
    },
    {
        path: '/create-property',
        name: 'createProperty',
        component: CreateProperty,
    },
    {
        path: '/demo',
        name: 'demo',
        component: Demo,
    },
    {
        path: '/matterport',
        name: 'matterport',
        component: Matterport,
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: { requiresLoggedIn: true}
    },
    {
        path: '/faq',
        name: 'faq',
        component: FaqPage,
        meta: { requiresLoggedIn: true}
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: ImpressumPage,
        meta: { requiresLoggedIn: true}
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: { requiresLoggedIn: true}
    },
    {
        path: '/logs',
        name: 'logs',
        component: LogsPage,
        meta: { requiresLoggedIn: true}
    },
];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});


router.beforeResolve(async (to, from, next) => {
    const user = useUser();
    const uStore = useStore();
    const loggedIn = await user.isLoggedIn();

    if (!loggedIn) {
        await removeCache("cachedUserProject")
    }
    await initHandlers( uStore )

    const userRole = 'gutachter'; // TODO extra field for user role

    const routeMeta = ((to.matched && to.matched.length > 0 && to.matched[0].meta) || {}) as any;

    store.commit('app/setRecentRoute', from);

    const requiresAuthorizedUserGroup = routeMeta.authorizedUserGroups;
    const isAuthorizedUserGroup =
        loggedIn && (!routeMeta.authorizedUserGroups || routeMeta.authorizedUserGroups.find((el: string) => el === userRole));

    if (user?.user?.value?.role?.id === 4 && to.fullPath !== '/waiting-room') {
        next('/waiting-room')
    }

    if ((routeMeta.requiresLoggedIn || routeMeta.authorizedUserGroups) && !loggedIn) {
        next('/login')
    } else if ((routeMeta.requiresLoggedOut && loggedIn) || (!isAuthorizedUserGroup && requiresAuthorizedUserGroup)) {
        next('/')
    } else {
        next()
    }
});

export default router