<template>
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>443010C9-6167-4253-BD64-EB9D84E6FAC7</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="00_MovingLayers_UI_Design-System" transform="translate(-654.000000, -4004.000000)">
        <g id="Icon/Progress/0-Copy" transform="translate(654.000000, 4004.000000)">
          <g id="Group" transform="translate(1.000000, 1.000000)">
            <circle id="Oval" stroke="var(--primary)" stroke-width="2" cx="10" cy="10" r="10"></circle>
            <path d="M17,10 C17,6.13400675 13.8659932,3 10,3 C10,3 10,10 10,10 C10,10 17,10 17,10 Z" id="Oval-Copy" fill="var(--primary)"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProgressQuarterIcon'
})
</script>

<style lang="scss">

</style>
