<template>
  <RecursiveForm 
    v-if="section && ba"
    :parent-fragenblock="section"
    :save-on-frage-input-changed="true"
  />
</template>

<script lang="ts">
import {defineComponent, computed, PropType} from "vue";
//Models
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
//Components
import RecursiveForm from "@/components/hzba/RecursiveForm.vue";
//Helpers
import { openHzbaModal } from "@/utilities/modal-helper";

export default defineComponent({
  name: "HzbaMainView",
  components: {
    RecursiveForm
  },
  props: {
    routeName: { type: String, default: undefined },
    ba: { type: Object as PropType<Bestandsaufnahme>, required: true }
  },
  setup(props: any) {
    const section = computed(() => {
      const section = props.ba.findSectionByIdentifier(props.routeName);
      return section;
    })

    return {
      section,
      openHzbaModal,
    }
  }
})
</script>

<style scoped>

</style>