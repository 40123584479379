<template>
  <div
    class="absolute bg-yellow-50 z-50 p-4 right-0 left-0 m-4"
    style="box-shadow: var(--black100) 4px 4px 7px 2px; border-radius: 5px;"
  >
    <strong>{{ fragenblock.getTitle() }}</strong> <a-button @click="$emit('close')">
      Close
    </a-button>
    <div>
      <span>{{ fragenblock.path }}</span>
    </div>

    <div class="mt-2 flex flex-wrap">
      <div class="debug-chips">
        <strong>Unlocked: </strong><span>{{ fragenblock.isUnlocked() }}</span>
      </div>
      <div class="debug-chips">
        <strong>isShown: </strong><span>{{ fragenblock.isShown() }}</span>
      </div>
      <div class="debug-chips">
        <strong>isShowDetectorSetup: </strong><span>{{ fragenblock.isShowDetectorSetup }}</span>
      </div>
      <div class="debug-chips">
        <strong>isUnlockDetectorSetup: </strong><span>{{ fragenblock.isUnlockDetectorSetup }}</span>
      </div>
      <div class="debug-chips">
        <strong>hasUnlockLogic: </strong><span>{{ fragenblock.freischaltunglogik && isEmptyObject(fragenblock.freischaltunglogik) }}</span>
      </div>
    </div>

    <a-button
      class="mt-2"
      @click="showJson = showJson === 'frage' ? undefined : 'frage'"
    >
      {{ showJson === 'frage' ? 'Hide ' : 'Show' }} Fragenblock Json
    </a-button>
    <pre
      v-if="showJson === 'frage'"
      style="max-height: 350px"
    >{{ fragenblockJson }}</pre>
  </div>
</template>

<script lang="ts">
import { isEmptyObject } from '@/utilities/helper';
import {defineComponent, PropType, ref, watch} from "vue";
import AButton from "@/components/Base/AButton";
import {Fragenblock} from "@/models/ba/Fragenblock";

export default defineComponent({
  name: "DebugFragenblock",
  components: {AButton},
  props: {
    fragenblock: {
      type: Object as PropType<Fragenblock>,
      required: true,
    },
  },
  setup(props) {
    const showJson = ref();


    const fragenblockJson = ref();
    watch(() => props.fragenblock, async () => {
      fragenblockJson.value = await props.fragenblock.toClassJson();
    }, { immediate: true })

    return {
      showJson,
      fragenblockJson,
      isEmptyObject,
    }
  }
})

</script>

<style scoped>

</style>