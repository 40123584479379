<template>
  <BaseModal
    data-cy="imageGalleryModal"
    :title="title"
    hide-save
    go-back-mode
    @close="() => cancel()"
  >
    <template #cta>
      <ion-buttons class="justify-end">
        <!-- <ion-button
          v-if="onAddMore && !isReadonly"
          data-cy="addResources"
          @click="onAddMore()"
        >
          <ion-icon
            slot="icon-only"
            :icon="addCircleOutline"
          />
        </ion-button> -->
        <ion-button
          data-cy="downloadResource"
          @click="downloadResources"
        >
          <ion-icon
            slot="icon-only"
            :icon="canShare ? share : download"
          />
        </ion-button>
      </ion-buttons>
    </template>

    <ion-content
      ref="content"
      data-cy="imageGalleryContent"
    >
      <div class="my-4">
        <div
          v-for="(photo, key) in images"
          :id="`photo-${key}`"
          :key="photo"
          class="px-4 pt-4"
          :data-cy="`photo-${key}`"
        >
          <div class="block lg:flex">
            <a-image
              :src="getPhotoUrl(photo)"
              :image-class="'w-full h-auto'"
              :data-cy="`image-${key}`"
            /> <!-- min-h-96 -->
            <!--            <ion-img :src="getPhotoUrl(photo)" />-->

            <ion-button
              v-if="onDelete && !isReadonly"
              data-cy="button-delete-photo"
              @click="onDelete(photo)"
            >
              <ion-icon :icon="trashOutline" />
            </ion-button>
            <ion-button
              v-if="downloadEnabled"
              class="lg:ml-2"
              @click="() => downloadResource(photo)"
            >
              <ion-icon
                slot="icon-only"
                :icon="canShare ? share : download"
              />
            </ion-button>
          </div>
          <!-- <ion-button
            v-if="!!allowAddMore"
          >Weitere Fotos hinzufügen
        </ion-button> -->
        </div>
      </div>
    </ion-content>
  </BaseModal>
</template>

<script lang="ts">

import { download, share, addCircleOutline } from "ionicons/icons";
import BaseModal from "@/components/Base/BaseModal";
import useBaseModal from "@/composables/useModal";
import { IonImg, IonContent, IonButtons, IonButton, IonIcon } from "@ionic/vue";
import { trashOutline } from "ionicons/icons";
import { getPhotoUrl } from "@/utilities/get-media-url";
import {onMounted, ref} from "vue";
import AImage from "@/components/Base/AImage";
import {getPdfUrl} from "../../utilities/get-media-url";
import useDownloadHelper from "@/composables/useDownloadHelper";

export default {
  name: "ImageGalleryModal",
  components: {AImage, BaseModal, IonImg, IonContent, IonButton, IonButtons, IonIcon },
  props: {
    title: {
      type: String,
      default: ''
    },
    images: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      default: undefined
    },
    onDelete: {
      type: Function,
      default: undefined,
    },
    allowAddMore: {
      type: Boolean,
      default: false
    },
    // addImmoPhoto: {
    //   type: Function,
    //   default: undefined
    // },
    isReadonly: {
      type: Boolean,
      default: false
    },
    downloadEnabled: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['close', 'save'],
  setup(props: any) {
    const content = ref();
    const { defaultCancel } = useBaseModal();


    const { canShare, downloadFile, isProcessing } = useDownloadHelper();


    async function scrollToIndex() {
      const scrollEl = await content.value.$el.getScrollElement();

      const el = document.getElementById(`photo-${props.index}`)
      scrollEl.scrollTo({ top: el?.offsetTop, behavior: 'smooth' })
    }


    onMounted(async () => {

      setTimeout(() => {
        if (props.index) {
          scrollToIndex();
        }
      }, 700)
    })

    function downloadResource(photo: any) {
      downloadFile(""+getPhotoUrl(photo), photo.name || 'image');
    }

    function downloadResources() {
      props.images.forEach((el: any) => {
        downloadResource(el);
      })
    }

    return {
      cancel: defaultCancel,
      canShare,
      getPhotoUrl,
      content,
      trashOutline,
      downloadResource,
      downloadResources,
      download,
      share,
      addCircleOutline
    }
  }
}
</script>

<style scoped lang="scss">

ion-icon {
  color: var(--white100);
}

ion-button {
  color: var(--white100);
}

</style>