<template>
  <svg
    width="33px"
    height="33px"
    viewBox="0 0 33 33"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>List Icon</title>
    <g
      id="Page"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="1.1-Übersicht-Allgemein"
        transform="translate(-672.000000, -101.000000)"
      >
        <g
          id="Icon-/-Events-/-Location-/-Background"
          transform="translate(672.000000, 101.000000)"
        >
          <circle
            id="Oval-3-Copy"
            :fill="active ? 'var(--primary)' : '#EBEFF5'"
            style="mix-blend-mode: multiply;"
            cx="16.5"
            cy="16.5"
            r="16.5"
          />
          <g
            id="Group-3"
            transform="translate(8.000000, 9.000000)"
          >
            <g id="Group-2">
              <circle
                id="Oval"
                :fill="active ? 'var(--secondary)' : 'var(--black100)'"
                cx="1"
                cy="1"
                r="1"
              />
              <line
                id="Path"
                x1="4"
                y1="1"
                x2="16.96"
                y2="1"
                :stroke="active ? 'var(--secondary)' : 'var(--black100)'"
              />
            </g>
            <g
              id="Group"
              transform="translate(0.000000, 6.000000)"
            >
              <circle
                id="Oval-Copy"
                :fill="active ? 'var(--secondary)' : 'var(--black100)'"
                cx="1"
                cy="1"
                r="1"
              />
              <line
                id="Path-Copy"
                x1="4"
                y1="1"
                x2="16.96"
                y2="1"
                :stroke="active ? 'var(--secondary)' : 'var(--black100)'"
              />
            </g>
            <g
              id="Group-Copy"
              transform="translate(0.000000, 12.000000)"
            >
              <circle
                id="Oval-Copy"
                :fill="active ? 'var(--secondary)' : 'var(--black100)'"
                cx="1"
                cy="1"
                r="1"
              />
              <line
                id="Path-Copy"
                x1="4"
                y1="1"
                x2="16.96"
                y2="1"
                :stroke="active ? 'var(--secondary)' : 'var(--black100)'"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'ListIcon',
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}

</script>