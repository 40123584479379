import { APhotoUploadTask } from "@/api/UploadApi";
import { usePhotoRecording } from "@/composables/usePhotoRecording";
import { AuswahlOption } from "@/models/ba/AuswahlOption.type";
import { LogikElement } from "@/models/ba/LogikElement";
import { Mangelzuordnung } from "@/models/ba/Mangelzuordnung";
import { AnzeigeLogik, BaNode, ToJsonSettings } from "@/models/ba/interfaces/IBaNode";
import { FrageConfig, FrageFlatJson, FrageJson } from "@/models/ba/interfaces/IFrage";
import Immobilie from "@/models/immobilie.model";
import Localization from '@/models/localization.model';
import CachedPhoto from "@/models/photo/cached-photo.model";
import getLocalization from "@/utilities/get-localization";
import { instanceOfPhoto } from "@/utilities/get-media-url";
import { getPhotoMinMaxLabel, isEmptyObject } from "@/utilities/helper";
import { generateUUID } from "@ionic/cli/lib/utils/uuid";
import { APhoto } from '../photo/a-photo.model';

export const TEXTBLOCK_ALLOWED_CHAR_LIMIT = 500;

export class Frage implements BaNode, FrageFlatJson {

    /**
     * Fields stored as primitives / json
     */
    public id?: number;
    public titel!: Localization;
    public identifier!: string;
    public eingabeTyp!: "Text" | "Textblock" | "Zahl" | "Boolean" | "Auswahl" | "MehrfachAuswahl" | "Medien" | "Uhrzeit" | "Datum";
    public einheit?: string | null;
    public eingabeText?: string;
    public eingabeJson?: any;
    public eingabeBoolean?: boolean;
    public eingabeMedien: APhoto[] = [];
    public auswahlOptions?: AuswahlOption[];
    public eingabeAuswahlWert?: string;
    public eingabeMehrfachAuswahlWerts?: string[];
    public optional?: boolean;
    public createdAt?: string;
    public platzhalter?: string;
    public updatedAt?: string;
    public arrayPosition?: number;
    public anzeigen?: boolean = undefined;
    public config: FrageConfig;

    /**
     * Fields stored as classes
     */
    public mangels: Mangelzuordnung[] = [];
    public anzeigelogik?: LogikElement;
    public berechnunglogik?: LogikElement;


    /**
     * Additional helper fields
     */
    public path: string;
    public showErrors = false;
    public computedValue?: ( {frages}: {frages: any} ) => {}; // TODO
    private showLogicElements: { currentEl: Frage; logic: AnzeigeLogik }[] = [];
    public isShowDetectorSetup = false;
    public isDirty = false;
    public isComputedDetectorSetup = false;



    constructor(json: FrageJson, private parentPath: string, public mode?: number) {
        const { mangels, anzeigelogik, ...jsonCopy} = json
        Object.assign(this, jsonCopy);

        // @ts-ignore
        if (!this.config) { this.config = {}}

        if (json.anzeigelogik && !isEmptyObject(json.anzeigelogik)) {
            this.anzeigelogik = new LogikElement(JSON.parse(JSON.stringify(json.anzeigelogik)))
        }
        if (json.berechnunglogik && !isEmptyObject(json.berechnunglogik)) {
            this.berechnunglogik = new LogikElement(JSON.parse(JSON.stringify(json.berechnunglogik)))
        }

        if (!this.eingabeMedien) { this.eingabeMedien = []}

        this.anzeigen = json.anzeigen === undefined ? true : json.anzeigen;

        this.path = parentPath + "." + json.identifier;

        if (json.mangels) {
            json.mangels.forEach(el => {
                const mangel = new Mangelzuordnung(el, this.path, mode)
                mangel.isFired(this.isShown());
                this.mangels.push(mangel);
            })
        }
    }

    public getTitle = () => getLocalization(this.titel)

    public setBerechnungRelatedFields(): void {
        const res = this.berechnunglogik?.calculateComputedValue();

        if ( this.eingabeTyp?.toLowerCase() === "auswahl" ) {
            if ( res !== undefined && res !== null && !this.eingabeAuswahlWert ) {
                this.eingabeAuswahlWert = res.toString();
            }
        } else {
            this.eingabeText = res !== undefined && res !== null ? res.toString() : undefined;
        }
        return res;
    }

    public getCurrentInput(): any {

        if (this.berechnunglogik) {
            return this.setBerechnungRelatedFields();
        }


        switch (this.eingabeTyp?.toLowerCase()) {
            case 'boolean': return this.eingabeBoolean;
            case 'textblock': return this.eingabeText;
            case 'medien': return this.eingabeMedien;
            case 'auswahl': return this.eingabeAuswahlWert;
            case 'mehrfachauswahl': return this.eingabeMehrfachAuswahlWerts;
            case 'text': return this.eingabeText;
            case 'map': return this.eingabeJson;
            case 'zahl': return this.eingabeText;
            case 'uhrzeit': return this.eingabeText;
            case 'datum': return this.eingabeText;
            default:
                console.error(`FrageClass --> getCurrentInput found undeclared eingabeTyp called ${this.eingabeTyp}. Please investigate in this error.`)
                return undefined;
        }
    }

    /** Get title of selected options of Mehrfachauswahl */
    public getSelectedAuswahlLabels(): Localization[] | undefined {
        let selectedValues = this.getCurrentInput();
        const mehrfachAuswahlObj = this.getSortedAuswahlOptions();

        if (this.eingabeTyp?.toLowerCase() !== "mehrfachauswahl" && this.eingabeTyp?.toLowerCase() !== "auswahl") {
            console.warn('getSelectedAuswahlLabels called but eingabeTyp is neither mehrfachauswahl nor auswahl.')
            
            return [];
        }

        if (this.eingabeTyp?.toLowerCase() === "auswahl") {
            selectedValues = [selectedValues];
        }

        if (!selectedValues || !Array.isArray(selectedValues)
            || !mehrfachAuswahlObj || !Array.isArray(mehrfachAuswahlObj)
        ) {
            return [];
        }

        const array: Localization[] = [];
        selectedValues?.forEach && selectedValues.forEach((el: any) => {
            const foundEl = mehrfachAuswahlObj.find((opt: any) => opt.wert === el)
            if (foundEl) {
                array.push(foundEl.label);
            } else {
                console.warn('Frage.ts: Could not find label for selected multiple choice value')
            }
        })

        return array;
    }

    /** Get title of selected options of Mehrfachauswahl */
    public getSelectedAuswahlLabelsString(): String {
        const selectedLabels = this.getSelectedAuswahlLabels();

        let arrayStr = '';
        selectedLabels?.forEach && selectedLabels.forEach(el => {
            if (arrayStr.length > 0) {
                arrayStr += ', ';
            }

            arrayStr += getLocalization(el);
        })

        return arrayStr;
    }


    public setDirty() {
        this.isDirty = true;
    }

    public setShowErrors() {
        this.showErrors = true;
        this.mangels && this.mangels.forEach(el => el.setShowErrors());
    }


    /**
     * Returns
     * 1 if the field is filled
     * 0 if there is no progress but this instance contains (currently) required fields
     * undefined if the instance is (currently) optional
     */
    public getProgress() {
        if (this.isRequired() && this.isValid()) return 1;
        if (this.isRequired() && !this.isValid()) return 0;
        return undefined;
    }


    public getSortedAuswahlOptions(): AuswahlOption[] | undefined {
        return this.auswahlOptions?.sort((a, b) => {
            const valA = Number.parseInt(a.wert);
            const valB = Number.parseInt(b.wert);

            if (isNaN(valA) || isNaN(valB)) {
                return a.wert.toLowerCase().localeCompare(b.wert.toLowerCase());
            } else {
                return valA - valB;
            }
        })
    }
    public getAuswahlOptionLabels() { return this.getSortedAuswahlOptions()?.map((el: any) => getLocalization(el.label)) }
    public getAuswahlOptionValues() { return this.getSortedAuswahlOptions()?.map((el: any) => el.wert) }



    public cleanInput() {
        this.eingabeBoolean = undefined;
        this.eingabeMedien = [];
        this.eingabeText = undefined;
        this.eingabeText = undefined;
        this.eingabeJson = undefined;
    }


    /**
     * Find all Elements referenced by the identifier to keep track on those variables when they have changed.
     *
     * For some reason, when this function is called within a .ts file, the store won't be initialized. That's why
     * we don't call it in the constructor, but quite similar after setup within a .vue file.
     */
    public setupComputedValues(): void {
        if (this.isComputedDetectorSetup) { return; }

        if (!this.berechnunglogik || isEmptyObject(this.berechnunglogik)) {
            // this.show = true
            // this.isComputedDetectorSetup = false;
        } else {
            this.berechnunglogik.setupFireDetector(this.path, this.mode)
            this.isComputedDetectorSetup = true;
        }
    }
    /**
     * Find all Elements referenced by the identifier to keep track on those variables when they have changed.
     *
     * For some reason, when this function is called within a .ts file, the store won't be initialized. That's why
     * we don't call it in the constructor, but quite similar after setup within a .vue file.
     */
    public setupShowDetector(): void {
        if (!this.anzeigelogik || isEmptyObject(this.anzeigelogik)) {
            this.anzeigen = true
            this.isShowDetectorSetup = false;
        } else {
            this.anzeigen = false;
            this.anzeigelogik.setupFireDetector(this.path, this.mode)
            this.isShowDetectorSetup = true;
        }
    }

    /**
     * Is this Frage shown?
     * "setupShowDetector" needs to be called in advance.
     */
    public isShown(): boolean | undefined {
        if (!this.isShowDetectorSetup) { return this.anzeigen; }

        if (this.anzeigelogik) {

            this.anzeigen = this.anzeigelogik.isFired();
            return this.anzeigen;
        } else {
            this.anzeigen = false;
            return false;
        }
    }

    /**
     * Checks if the field itself is valid, without any potentially attached mangel to it.
     */
    public isFieldValid( isModalFrage?: boolean ) {
        if (!this.isShown()) { return true; }
        if (this.config.optional) { return true; }
        
        switch (this.eingabeTyp?.toLowerCase()) {
            case 'boolean': return this.eingabeBoolean === true || this.eingabeBoolean === false || 
            ( isModalFrage && ( this.eingabeBoolean === undefined || this.eingabeBoolean === null ));
            case 'textblock': return !!this.eingabeText && (this.eingabeText && this.eingabeText.length <= TEXTBLOCK_ALLOWED_CHAR_LIMIT) || 
            ( isModalFrage && (this.eingabeText === undefined || this.eingabeText === null) );
            case 'map': {
                const geometriesLength = this.eingabeJson?.features?.length;
                const minMax = this.config.allowedNumberOfMedien;
                if (!minMax || (isModalFrage && !geometriesLength) ) { 
                    return true;
                }
                return (
                    (!minMax.min || (geometriesLength && geometriesLength >= minMax.min)) &&
                    (!minMax.max || (geometriesLength && geometriesLength <= minMax.max))
                )
            }
            case 'medien': {
                const medienLength = this.eingabeMedien?.length;
                const minMax = this.config.allowedNumberOfMedien;
                if (!minMax || (isModalFrage && !medienLength) ) { return true }
                return (
                    (!minMax.min || (medienLength && medienLength >= minMax.min)) &&
                    (!minMax.max || (medienLength && medienLength <= minMax.max))
                )
            }
            case 'text': return isModalFrage ? true : !!this.eingabeText;
            case 'zahl': {
                if (isModalFrage || this.eingabeText?.toString() === "0" ) { return true; }
                return !!this.eingabeText;
            }
            case 'uhrzeit': return isModalFrage ? true : !!this.eingabeText;
            case 'datum': return isModalFrage ? true : !!this.eingabeText;
            case 'auswahl': return isModalFrage ? true : !!this.eingabeAuswahlWert;
            case 'mehrfachauswahl': return isModalFrage ? true : !!this.eingabeMehrfachAuswahlWerts && this.eingabeMehrfachAuswahlWerts.length > 0;
            default:
                console.error(`FrageClass --> $isFieldValid found undeclared eingabeTyp called ${this.eingabeTyp}. Please investigate in this error.`)
                return false;
        }
    }

    public getMediaUploadTasks(immobilie: Immobilie, baIdentifier: string, localOnly?: boolean): APhotoUploadTask[] {
        if (!this.eingabeMedien || this.eingabeMedien?.length <= 0) { return []; }
        if (!this.config) this.config = {};
        this.config.photoUploadUid = generateUUID();

        return this.eingabeMedien.filter(el => !localOnly || instanceOfPhoto(el)).map(el => {
            const cachedPhoto = el as CachedPhoto;
            const fileName = `${immobilie?.strasse}_${immobilie?.plz}_${immobilie?.stadt}_${baIdentifier}_${this!.identifier}.${cachedPhoto.format}` 

            return {
                field: "eingabeMedien",
                image: el,
                ref: "frages",
                api: "api::frage.frage",
                uid: this.config.photoUploadUid,
                status: instanceOfPhoto(el) ? "preparing" : "success",
                instanceId: this.id,
                fileName: fileName,
            }
        });
    }

    public isPdfUploadType() {
        return this.config.mediaUploadType === "pdf";
    }

    /**
     * Checks if this field with it's mangel is valid
     */
    public isValid( isModalFrage = false) {

        if ((this.isShowDetectorSetup && !this.isShown()) || this.anzeigen === false) {
            return true;
        }

        let maengelValid = true;

        if (this.mangels) {
            this.mangels.forEach(el => {
                if (el.isFired(this.isShown()) && !el.isInputValid()) {
                    maengelValid = false;
                }
            })
        }

        return maengelValid && this.isFieldValid( isModalFrage );
    }

    public isRequired() {
        return !this.config.optional && this.eingabeTyp?.toLowerCase() !== 'medien' && this.isShown();
    }

    public isAlwaysOptional() {
        return this.config.optional || this.eingabeTyp?.toLowerCase() === 'medien';
    }

    public errors(t: any) {
        if (!this.showErrors) { return []; }
        if (!t) { console.error("Frage.errors() undefined t."); return []}

        // @ts-ignore
        // const { t } = i18n.global;

        const errors = [];
        if (!this.isFieldValid()) {
            const medienLength = this.eingabeMedien?.length;
            const geometriesLength = this.eingabeJson?.features?.length;
            const minMax = this.config.allowedNumberOfMedien;

            if (this.eingabeTyp?.toLowerCase() === 'textblock' && (this.eingabeText && this.eingabeText.length > TEXTBLOCK_ALLOWED_CHAR_LIMIT)) {
                errors.push({ $message: `${this.getTitle()} ` + t('hzba.hatLimitUeberschritten'), });
            } else if (this.eingabeTyp?.toLowerCase() === 'medien' && minMax && !(!minMax.min || (medienLength && medienLength >= minMax.min)) &&
                (!minMax.max || (medienLength && medienLength <= minMax.max))) {
                errors.push({ $message: this.getPhotoMinMaxLabel(t), });
            } else if ( this.eingabeTyp?.toLowerCase() === 'map' && minMax && !(!minMax.min || (geometriesLength && geometriesLength >= minMax.min)) && 
                (!minMax.max || (geometriesLength && geometriesLength <= minMax.max))) {
                errors.push({ $message: this.getPhotoMinMaxLabel(t) });
            } else {
                errors.push({ $message: `${this.getTitle()} ` + t('hzba.wirdBenoetigtInfo'), });
            }
        }
        return errors;
    }


    public submit() {
        this.anzeigen = this.isShown();
        this.mangels.forEach(el => el.submit())
    }

    public getFiredMaengel(settings: any): Mangelzuordnung[] | undefined {
        return this.mangels && this.mangels.filter(el => el.isFired(this.isShown()));
    }


    /**************************************
     *
     *
     * Copy, Print & Offline Methods
     *
     *
     *************************************/


    /**
     * Recursive Method to get the json of every subobject. Does not necessarily deep copy the whole object
     * as we still have nested objects here. Use copyJson() for deep copy.
     *
     * We do not copy variables that are used for state management,
     * We only copy those who are relevant for the backend.
     */
    public async toClassJson(settings: ToJsonSettings = {}): Promise<FrageJson> {
        const anzeigeLogik = settings.cleanup ? this.anzeigelogik?.cleanAndCopyJson() : this.anzeigelogik?.copyJson(settings)
        const mangels = await Promise.all(this.mangels?.map(async el => settings.cleanup ? await el.cleanAndCopyJson() : await el.copyJson(settings)))
        const berechnunglogik = settings.cleanup ? this.berechnunglogik?.cleanAndCopyJson() : this.berechnunglogik?.copyJson(settings)

        let copyOfEingabeMedien: APhoto[] = this.eingabeMedien;
        if (settings.cleanup) {
            copyOfEingabeMedien = await Promise.all(this.eingabeMedien.map(async el => {
                const dupPhoto = await usePhotoRecording().duplicatePhoto(el);
                if (dupPhoto.success && dupPhoto.data && dupPhoto.data.length > 0) {
                    return dupPhoto.data[0];
                }
                console.error('Duplicating photo was not successful', dupPhoto, el)
                return el;
            }))
        }

        return {
            id: settings.cleanup ? undefined : this.id,
            identifier: this.identifier,
            titel: this.titel,
            eingabeTyp: this.eingabeTyp,
            einheit: this.einheit,
            eingabeText: !this.anzeigen ? undefined : (this.eingabeText?.toString().trim ? this.eingabeText?.toString().trim() : this.eingabeText)?.toString(),
            eingabeBoolean: !this.anzeigen ? undefined : this.eingabeBoolean,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            platzhalter: this.platzhalter,
            optional: this.optional,
            anzeigelogik: anzeigeLogik,
            berechnunglogik: berechnunglogik,
            eingabeJson: this.eingabeJson,
            eingabeAuswahlWert: !this.anzeigen ? undefined : this.eingabeAuswahlWert,
            eingabeMehrfachAuswahlWerts: !this.anzeigen ? undefined : this.eingabeMehrfachAuswahlWerts,
            auswahlOptions: this.auswahlOptions,
            eingabeMedien: (!this.anzeigen || settings.prepareForSync) ? undefined : copyOfEingabeMedien,
            mangels: mangels,
            anzeigen: this.anzeigen,
            arrayPosition: this.arrayPosition,
            config: this.config
        }
    }

    public async toLeanJson(settings: ToJsonSettings = {}) {
        const mangels = await Promise.all(this.mangels?.map(async el => await el.copyLeanJson(settings)))

        return {
            id: this.id,
            identifier: this.identifier,
            eingabeTyp: this.eingabeTyp,
            eingabeText: !this.anzeigen ? undefined : (this.eingabeText?.toString().trim ? this.eingabeText?.toString().trim() : this.eingabeText)?.toString(),
            eingabeBoolean: !this.anzeigen ? undefined : this.eingabeBoolean,
            eingabeAuswahlWert: !this.anzeigen ? undefined : this.eingabeAuswahlWert,
            eingabeMehrfachAuswahlWerts: !this.anzeigen ? undefined : this.eingabeMehrfachAuswahlWerts,
            eingabeMedien: (!this.anzeigen || settings.prepareForSync) ? undefined : this.eingabeMedien,
            eingabeJson: this.eingabeJson,
            mangels: mangels,
            arrayPosition: this.arrayPosition,
            anzeigen: this.anzeigen,
            config: this.config
        }
    }

    /**
     * Deep copy this class with all of it's subobjects.
     */
    public async copyJson(settings: ToJsonSettings = {}): Promise<FrageJson> { return JSON.parse(JSON.stringify(await this.toClassJson(settings))); }

    public async copyLeanJson(settings: ToJsonSettings = {}): Promise<FrageJson> { return JSON.parse(JSON.stringify(await this.toLeanJson(settings))); }

    /**
     * Deep copy this class with all of it's subobjects.
     * Use this if you want an object that is like the original
     * but without specific id's that makes the original instance unique (e.g. copy from template, duplicate)
     */
    public async cleanAndCopyJson(): Promise<FrageJson> { return JSON.parse(JSON.stringify(await this.toClassJson({ cleanup: true }))); }


    /**
     * toString can be used to console log the json of this object.
     */
    public async toString(): Promise<string> {
        return JSON.stringify(await this.toClassJson());
    }


    public async print(): Promise<void> {
        console.log(`Print frage with identifier ${this.identifier} and mode ${this.mode}`, await this.toClassJson(),
            "Additional variables",
            `isFieldValid: ${this.isFieldValid()}`,
            this.eingabeJson.toString());
    }



    public filterPhotos(filterFctn: Function): APhoto[] {
        const photos: APhoto[] = [];
        this.eingabeMedien?.forEach(el => {
            if (filterFctn(el)) {
                photos.push(el)
            }
        })
        this.mangels?.forEach(el => photos.push(...el.filterPhotos(filterFctn)))

        return photos;
    }

    public getPhotoMinMaxLabel(t: any): string | undefined {
        return getPhotoMinMaxLabel(t, this.config.allowedNumberOfMedien);
    }
}