import {
  useIdentifierFrageFinder,
  useIdentifierFragenblockFinder,
} from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';

function getAuswahlWert(ba: Bestandsaufnahme, auswahlFragesIdentifier: string) {
  return useIdentifierFrageFinder(ba, auswahlFragesIdentifier)?.[
    'eingabeAuswahlWert'
  ];
}

function get1NModalAuswahlWert(
  ba: Bestandsaufnahme,
  modalIdentifier: string,
  auswahlFragesIdentifier: string
) {
  const modal = useIdentifierFragenblockFinder(ba, modalIdentifier);

  const modale = modal?.fragenblocks?.filter(
    (block) => !block.config.isTemplate
  );

  return modale
    ?.map((modal) => {
      return modal.fragenblocks.map((fragenBlock) => {
        return useIdentifierFrageFinder(
          ba,
          fragenBlock.path + '.' + auswahlFragesIdentifier
        )?.['eingabeAuswahlWert'];
      });
    })
    .flat(1);
}

export function useBauflaechen() {
  const getArbeitsFlaecheBewertungen = (ba: Bestandsaufnahme) => {
    return {
      allgemein: {
        'nutzungsart-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.allgemeine-punkte-group.nutzungsart-bewertung'
        ),
        'fremdobjekte-auf-flaeche-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.allgemeine-punkte-group.fremdobjekte-auf-flaeche-bewertung'
        ),
        'sonstiges-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.allgemeine-punkte-group.sonstiges-bewertung'
        ),
      },
      gelaende: {
        'gefaelle-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.gefaelle-group.gefaelle-bewertung'
        ),
        'ausholzung-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.ausholzung-group.ausholzung-bewertung'
        ),
        'rodung-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.rodung-group.rodung-bewertung'
        ),
        'graben-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.graben-group.graben-bewertung'
        ),
        'feuchtgebiete-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.feuchtgebiete-group.feuchtgebiete-bewertung'
        ),
        'naturdenkmaeler-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.naturdenkmaeler-group.naturdenkmaeler-bewertung'
        ),
        'kampfmittel-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.kampfmittel-group.kampfmittel-bewertung'
        ),
        'sonstiges-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.sonstiges-group.sonstiges-bewertung'
        ),
      },
      oekologie: {
        'ampelkarte-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.ampelkarte-bewertung'
        ),
        'ffh-gebiet-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.ffh-gebiet-bewertung'
        ),
        'biotop-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.biotop-bewertung'
        ),
        'vogelschutzgebiet-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.vogelschutzgebiet-bewertung'
        ),
        'wasserschutzgebiet-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.wasserschutzgebiet-bewertung'
        ),
        'sonstiges-bewertung': getAuswahlWert(
          ba,
          'rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.sonstiges-bewertung'
        ),
      },
    };
  };

  const getZuwegungBewertungen = (ba: Bestandsaufnahme) => {
    return {
      'beschaffenheit-bewertung': getAuswahlWert(
        ba,
        'rippistein-bauflaechen.zuwegung-mast-tab.beschaffenheit.beschaffenheit-bewertung'
      ),
      'gefaelle-bewertung': getAuswahlWert(
        ba,
        'rippistein-bauflaechen.zuwegung-mast-tab.gefaelle.gefaelle-bewertung'
      ),
      'ausholzung-bewertung': getAuswahlWert(
        ba,
        'rippistein-bauflaechen.zuwegung-mast-tab.ausholzung.ausholzung-bewertung'
      ),
      'rodung-bewertung': getAuswahlWert(
        ba,
        'rippistein-bauflaechen.zuwegung-mast-tab.rodung.rodung-bewertung'
      ),
      'zuwegung-breite-bewertung': getAuswahlWert(
        ba,
        'rippistein-bauflaechen.zuwegung-mast-tab.zuwegung.zuwegung-breite-bewertung'
      ),
      'beschraenkung-hoehe-bewertung': getAuswahlWert(
        ba,
        'rippistein-bauflaechen.zuwegung-mast-tab.beschraenkung.beschraenkung-hoehe-bewertung'
      ),
      'beschraenkung-gewicht-bewertung': getAuswahlWert(
        ba,
        'rippistein-bauflaechen.zuwegung-mast-tab.beschraenkung.beschraenkung-gewicht-bewertung'
      ),
      'ringverkehr-bewertung': getAuswahlWert(
        ba,
        'rippistein-bauflaechen.zuwegung-mast-tab.ringverkehr.ringverkehr-bewertung'
      ),
      'ausweichflaechen-bewertung': getAuswahlWert(
        ba,
        'rippistein-bauflaechen.zuwegung-mast-tab.ausweichflaechen.ausweichflaechen-bewertung'
      ),
      'alternative-zufahrt-bewertung': getAuswahlWert(
        ba,
        'rippistein-bauflaechen.zuwegung-mast-tab.alternative-zufahrt.alternative-zufahrt-bewertung'
      ),
      'sonstiges-bewertung': getAuswahlWert(
        ba,
        'rippistein-bauflaechen.zuwegung-mast-tab.sonstiges.sonstiges-bewertung'
      ),
      'graben-bewertung': get1NModalAuswahlWert(
        ba,
        'rippistein-bauflaechen.zuwegung-mast-tab.graben',
        'graben-bewertung'
      ),
      'bauwerk-bewertung': get1NModalAuswahlWert(
        ba,
        'rippistein-bauflaechen.zuwegung-mast-tab.bauwerke',
        'bauwerk-bewertung'
      ),
    };
  };

  const getFremdleitungenBewertungen = (ba: Bestandsaufnahme) => {
    return {
      'fremdleitung-bewertung': get1NModalAuswahlWert(
        ba,
        'rippistein-bauflaechen.fremdleitungen-tab.fremdleitungen-group',
        'fremdleitung-bewertung'
      ),
      'sonstiges-bewertung': getAuswahlWert(
        ba,
        'rippistein-bauflaechen.fremdleitungen-tab.fremdleitungen-group.sonstiges-bewertung'
      ),
    };
  };

  const summarize = (bewertungen: Array<string | undefined>) => {
    // Priority what comes first
    if (bewertungen.includes('x')) {
      return {
        color: "4",
        amount: bewertungen.filter((f) => f === 'x').length.toString(),
      };
    } else if (bewertungen.includes('minus')) {
      return {
        color: "3",
        amount: bewertungen.filter((f) => f === 'minus').length.toString(),
      };
    } else if (bewertungen.includes('o')) {
      return {
        color: "2",
        amount: bewertungen.filter((f) => f === 'o').length.toString(),
      };
    } else if (bewertungen.includes('plus')) {
      return {
        color: "1",
        amount: bewertungen.filter((f) => f === 'plus').length.toString(),
      };
    }
    //default value, to see something does not work
    return { color: "0", amount: '0' };
  };

  const summarizeFremdleitungenBewertung = (ba: Bestandsaufnahme) => {
    const bewertungen: Array<string | undefined> = Object.values(
      getFremdleitungenBewertungen(ba)
    ).flat(1);
    return summarize(bewertungen);
  };

  const summarizeZuwegungBewertung = (ba: Bestandsaufnahme) => {
    const bewertungen: Array<string | undefined> = Object.values(
      getZuwegungBewertungen(ba)
    ).flat(1);

    return summarize(bewertungen);
  };

  const summarizeArbeitsFlaechenBewertung = (ba: Bestandsaufnahme) => {
    const bewertungen: Array<string | undefined> = Object.values(
      getArbeitsFlaecheBewertungen(ba)
    )
      .flat(1)
      .map((f) => Object.values(f))
      .flat(1);
    return summarize(bewertungen);
  };

  const summarizeOverall = (ba: Bestandsaufnahme) => {
    const bewertungenArbeitsflaechen: Array<string | undefined> = Object.values(
      getArbeitsFlaecheBewertungen(ba)
    )
      .flat(1)
      .map((f) => Object.values(f))
      .flat(1);
    const bewertungenZuwegung: Array<string | undefined> = Object.values(
      getZuwegungBewertungen(ba)
    ).flat(1);
    const bewertungenFremdleitungen: Array<string | undefined> = Object.values(
      getFremdleitungenBewertungen(ba)
    ).flat(1);

    const a = bewertungenArbeitsflaechen.concat(
      bewertungenZuwegung,
      bewertungenFremdleitungen
    );

    return summarize(a);
  };

  const extractModalsFrom1NModals = (
    ba: Bestandsaufnahme,
    modalIdentifier: string
  ) => {
    const modal = useIdentifierFragenblockFinder(ba, modalIdentifier);

    const modale = modal?.fragenblocks?.filter(
      (block) => !block.config.isTemplate
    );

    return modale
      ?.map((modal) => {
        return modal.fragenblocks.map((fragenBlock) => {
          return useIdentifierFrageFinder(ba, fragenBlock.path);
        });
      })
      .flat(1);
  };

  const getAnmerkungenGutachter = (ba: Bestandsaufnahme) => {
    const anmerkungenModals = extractModalsFrom1NModals(
      ba,
      'rippistein-bauflaechen.allgemein-tab.anmerkung-des-gutachters-group'
    );
    const res = anmerkungenModals?.map((modal: any) => {
      const elems = modal?.frages?.map((f: any) => {
        if (f.eingabeTyp === 'Text' && f.identifier === 'custom-title') {
          return { title: f.eingabeText };
        } else if (f.identifier === 'custom-freitext') {
          return { anmerkung: f.eingabeText };
        } else if ( f.identifier === 'custom-bilder' ) {
          return { bilder: f.eingabeMedien };
        } else if (f.identifier === 'custom-nummer') {
          return { lfndnr: f.eingabeText };
        }
      });

      return Object.assign(elems[0], elems[1], elems[2], elems[3], elems[4]);
    });

    return res;
  };
  const getAnmerkungenPages = (ba: Bestandsaufnahme) => {
    const res = getAnmerkungenGutachter(ba);

    const maxSpace = 100;
    const spaceForSingleModal = 30;
    let currentOccupiedSpace = 0;

    const pages: any[] = [];
    let currentPage: any = {
      'anmerkungen': []
    };

    res?.forEach(anmerkung => {
      if(currentOccupiedSpace + spaceForSingleModal < maxSpace){
        currentPage['anmerkungen'].push(anmerkung);
        currentOccupiedSpace += spaceForSingleModal;
      }else{
        pages.push(currentPage);
        currentOccupiedSpace = 0;
        currentPage = {
          'anmerkungen': []
        };
        currentPage['anmerkungen'].push(anmerkung);
        currentOccupiedSpace += spaceForSingleModal;
      }
    });
    pages.push(currentPage);
    return pages;
  }

  const getGrabenquerungen = (ba: Bestandsaufnahme) => {
    const grabenModals = extractModalsFrom1NModals(
      ba,
      'rippistein-bauflaechen.zuwegung-mast-tab.graben'
    );
    const res = grabenModals?.map((modal: any) => {
      const elems = modal?.frages?.map((f: any) => {
        if (f.identifier === 'art') {
          return { 'art': f.eingabeAuswahlWert };
        } else if (f.identifier === 'abstand') {
          return { 'abstand': f.eingabeText };
        } else if (f.identifier === 'graben-beschreibung') {
          return { 'graben-beschreibung': f.eingabeText };
        } else if (f.identifier === 'graben-bewertung') {
          return { 'graben-bewertung': f.eingabeAuswahlWert };
        } else if (f.identifier === 'graben-bilder') {
          return { 'graben-foto': f.eingabeMedien };
        }

      });
      return Object.assign(elems[0], elems[1], elems[2], elems[3], elems[4]);
    } );
    return res;
  };
  const getGrabenquerungenPages = (ba: Bestandsaufnahme) => {
    const res = getGrabenquerungen(ba);

    const maxSpace = 100;
    const spaceForSingleModal = 30;
    let currentOccupiedSpace = 0;

    const pages: any[] = [];
    let currentPage: any = {
      'grabenquerungen': []
    };

    res?.forEach(grabenquerung => {
      if(currentOccupiedSpace + spaceForSingleModal < maxSpace){
        currentPage['grabenquerungen'].push(grabenquerung);
        currentOccupiedSpace += spaceForSingleModal;
      }else{
        pages.push(currentPage);
        currentOccupiedSpace = 0;
        currentPage = {
          'grabenquerungen': []
        };
        currentPage['grabenquerungen'].push(grabenquerung);
        currentOccupiedSpace += spaceForSingleModal;
      }
    });
    pages.push(currentPage);
    return pages;
  }

  const getBauwerke = (ba: Bestandsaufnahme) => {
    const grabenModals = extractModalsFrom1NModals(
      ba,
      'rippistein-bauflaechen.zuwegung-mast-tab.bauwerke'
    );
    const res = grabenModals?.map((modal: any) => {
      const elems = modal?.frages?.map((f: any) => {
        if (f?.identifier === 'freitext') {
          return { freitext: f?.eingabeText };
        } else if (f?.identifier === 'bauwerk-beschreibung') {
          return { beschreibung: f?.eingabeText };
        } else if (f?.identifier === 'tragfaehig') {
          return { tragfaehig: f?.eingabeAuswahlWert };
        } else if (f?.identifier === 'bauwerk-bewertung') {
          return { 'bauwerk-bewertung': f?.eingabeAuswahlWert };
        } else if (f?.identifier === 'bild') {
          return { bild: f?.eingabeMedien };
        }
      });
      return Object.assign(elems[0], elems[1], elems[2], elems[3], elems[4]);
    });
    return res;
  };
  const getBauwerkePages = (ba: Bestandsaufnahme) => {
    const res = getBauwerke(ba);

    const maxSpace = 100;
    const spaceForSingleModal = 9;
    let currentOccupiedSpace = 0;

    const pages: any[] = [];
    let currentPage: any = {
      'bauwerke': []
    };

    res?.forEach(bauwerk => {
      if(currentOccupiedSpace + spaceForSingleModal < maxSpace){
        currentPage['bauwerke'].push(bauwerk);
        currentOccupiedSpace += spaceForSingleModal;
      }else{
        pages.push(currentPage);
        currentOccupiedSpace = 0;
        currentPage = {
          'bauwerke': []
        };
        currentPage['bauwerke'].push(bauwerk);
        currentOccupiedSpace += spaceForSingleModal;
      }
    });
    pages.push(currentPage);
    return pages;
  }

  const getFremdleitungen = (ba: Bestandsaufnahme) => {
    const grabenModals = extractModalsFrom1NModals(
      ba,
      'rippistein-bauflaechen.fremdleitungen-tab.fremdleitungen-group'
    );
    const res = grabenModals?.map((modal: any) => {
      const elems = modal?.frages?.map( ( f: any ) => {
        if (f.identifier === 'typ') {
          return { typ: f.eingabeAuswahlWert };
        } else if (f.identifier === 'art-oberirdisch') {
          return { 'art-oberirdisch': f.eingabeAuswahlWert };
        } else if (f.identifier === 'art-unterirdisch') {
          return { 'art-unterirdisch': f.eingabeAuswahlWert };
        } else if ( f.identifier === 'betroffeneflaechen' ) {
          return { betroffeneflaechen: f.eingabeMehrfachAuswahlWerts };
        } else if ( f.identifier === 'betreiber-bekannt' ) {
          return { 'betreiber-bekannt': f.eingabeBoolean };
        } else if (f.identifier === 'auflagen') {
          return { 'auflagen': f.eingabeAuswahlWert };
        } else if ( f.identifier === 'betreiber' ) {
          return { 'betreiber': f.eingabeText };
        } else if ( f.identifier === 'fremdleitung-bewertung' ) {
          return { 'fremdleitung-bewertung': f.eingabeAuswahlWert };
        }
      } );

      return Object.assign(
        elems[0],
        elems[1],
        elems[2],
        elems[3],
        elems[4],
        elems[5],
        elems[6],
        elems[7],
        elems[8]
      );
    } );
    return res;
  };
  const getFremdleitungenPages = (ba: Bestandsaufnahme) => {
    const res = getFremdleitungen(ba);

    const maxSpace = 100;
    const spaceForSingleModal = 9;
    let currentOccupiedSpace = 0;

    const pages: any[] = [];
    let currentPage: any = {
      'fremdleitungen': []
    };

    res?.forEach(fremdleitung => {

      if(currentOccupiedSpace + spaceForSingleModal < maxSpace){
        currentPage['fremdleitungen'].push(fremdleitung);
        currentOccupiedSpace += spaceForSingleModal;
      }else{
        pages.push(currentPage);
        currentOccupiedSpace = 0;
        currentPage = {
          'fremdleitungen': []
        };
        currentPage['fremdleitungen'].push(fremdleitung);
        currentOccupiedSpace += spaceForSingleModal;
      }
    });
    pages.push(currentPage);

    return pages;
  }

  return {
    getZuwegungBewertungen,
    getArbeitsFlaecheBewertungen,
    getFremdleitungenBewertungen,
    summarizeFremdleitungenBewertung,
    summarizeZuwegungBewertung,
    summarizeArbeitsFlaechenBewertung,
    summarizeOverall,
    getAnmerkungenGutachter,
    getAnmerkungenPages,
    getGrabenquerungen,
    getGrabenquerungenPages,
    getBauwerke,
    getBauwerkePages,
    getFremdleitungen,
    getFremdleitungenPages
  };
}
