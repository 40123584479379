import { popoverController } from "@ionic/vue";
import UserPopover from "@/components/User/UserPopover.vue";
import ChangeProjectPopover from '@/components/User/ChangeProjectPopover.vue'
import AddElementPopover from "@/components/hzba/Base/AddElementPopover.vue";
import SortByPopover from "@/components/hzba/Base/SortByPopover.vue";

export const popoverHelper = async (ev: Event, name: any, props: any, callback: any) => {

    let comp: any;

    switch (name) {
        case 'user.profileImage': comp = UserPopover; break;
        case 'user.changeProject':comp = ChangeProjectPopover; break;
        case 'hzba.addElements': comp = AddElementPopover; break;
        case 'sortby': comp = SortByPopover; break;
        default: comp = undefined;
    }

    if (!comp) {
        console.error(`"${name}" is not registered as a modal in modal-helper.ts`)
        return;
    }

    const popover = await popoverController
        .create({
            component: comp,
            cssClass: 'my-custom-class',
            event: ev,
            componentProps: {
                ...props
            },
            translucent: true
        })
    await popover.present();

    popover.onDidDismiss().then((res) => {
        if (res.data) {
            callback(res.data);
        } else {
            // aborted
        }
    })

    // const { role } = await popover.onDidDismiss();
    // console.log('onDidDismiss resolved with role', role);

  };