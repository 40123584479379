import { useStore } from "@/composables/useTypedStore";
import { isPlatform } from "@ionic/vue";
import { App } from '@capacitor/app'

export enum AppPlatform {
  native = 'native',
  desktop = 'desktop'
}

export interface AppInfoInterface {
  name: string;
  id: string;
  build: string;
  version: string;
}

export default function useAppInfo() {
  const store = useStore()

  async function initAppHandlers() {
    //First handle appPlatform
    await handleAppPlatform()
    //Then handle appInfo
    await handleAppInfo()
  }

  async function handleAppPlatform() {
    const platform = isPlatform('capacitor') ? AppPlatform.native : AppPlatform.desktop
    await store.commit("app/setAppPlatform", platform)
  }

  async function handleAppInfo() {
    if(store.state.app.appPlatform === AppPlatform.native) {
      const appInfo = await App.getInfo();
      if (appInfo) await store.commit("app/setAppInfo", appInfo)
    }
  }

  return {
    initAppHandlers,
    handleAppPlatform,
    handleAppInfo
  }
}