<template>
  <ion-item class="appSelect">
    <ion-select
      :placeholder="props.placeholder"
      :value="initialValue"
      @ionChange="handleChange($event)"
    >
      <ion-select-option
        v-for="item in props.items" :key="item.id"
        :value="item.value"
      >
        {{ item.label }}
      </ion-select-option>
    </ion-select>
  </ion-item>
</template>

<script setup lang="ts">
import { PropType } from 'vue'
import { IonItem, IonSelect, IonSelectOption } from '@ionic/vue';

//Emits
const emit = defineEmits(['valueChanged', 'update:modelValue'])

//Props
const props = defineProps({
  placeholder: {
    type: String,
    required: false
  },
  items: {
    type: Array as PropType<Array<{id: string; label: string; value: string}>>,
    required: false
  },
  initialValue: {
    type: String,
    required: false
  }
})

//Methods
function handleChange(event: any) {
  emit('valueChanged', event)
  emit('update:modelValue', event.detail.value)
}
</script>

<style lang="scss">
ion-item.appSelect {
  --ion-background-color: transparent;
  --border-color: transparent;

  ion-select {
    width: 100%;
  }
}
</style>
