<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 382.5">
    <g>
      <path class="cls-1" d="m890.45,8.96h78.97l23.88,93.64,24.1-93.64h76.64v159.28h-54.21v-96.53l-24.66,96.53h-45.54l-24.99-94.97v94.97h-54.21V8.96Z"/>
      <path class="cls-1" d="m1111.24,88.38c0-58.87,40.32-83.42,90.3-83.42s90.31,23.88,90.31,83.42-38.54,83.86-90.31,83.86-90.3-25.77-90.3-83.86Zm90.3,47.21c21.11,0,26.55-21.66,26.55-47.21s-5.44-46.76-26.55-46.76-26.55,21.44-26.55,46.76,5.44,47.21,26.55,47.21Z"/>
      <path class="cls-1" d="m1292.64,8.96h60.87l21.66,115.74,21.66-115.74h60.09l-39.65,159.28h-85.2l-39.43-159.28Z"/>
      <path class="cls-1" d="m1469.3,8.96h58.87v159.28h-58.87V8.96Z"/>
      <path class="cls-1" d="m1555.93,8.96h73.2l39.21,85.31V8.96h55.09v159.28h-69.2l-43.21-93.41v93.41h-55.09V8.96Z"/>
      <path class="cls-1" d="m1741.98,90.05c0-59.76,39.65-85.08,89.53-85.08,43.76,0,79.53,17.99,81.86,57.65h-62.76c-.33-12.22-6.11-20.77-18.88-20.77-17.22,0-25.99,13.44-25.99,46.43s10.44,45.99,29.1,45.99c13.22,0,21.44-7.78,22.66-18.66h-36.1v-33.32h91.97v85.97h-49.43v-16.11c-10.22,13.77-27.88,20.33-48.1,20.33-43.54,0-73.87-25.33-73.87-82.42Z"/>
      <path class="cls-1" d="m890.45,217.25h54.97v115.79h66.38v43.25h-121.36v-159.04Z"/>
      <path class="cls-1" d="m1069.2,217.54h82.85l47.8,159.04h-61.44l-6.65-26.84h-43.25l-6.77,26.84h-60.78l48.24-159.04Zm54.01,92.27l-12.75-54.12-13.09,54.12h25.84Z"/>
      <path class="cls-1" d="m1215.36,322.9l-36.93-105.36h62.33l20.52,71.87,20.41-71.87h61.44l-35.93,105.36c-13.09,0-16.52,3.99-16.52,13.09v40.59h-58.78v-40.59c0-9.09-3.55-13.09-16.52-13.09Z"/>
      <path class="cls-1" d="m1355.19,217.54h131.09v37.49h-72.31v21.85h59.11v37.71h-59.11v24.29h72.31v37.71h-131.09v-159.04Z"/>
      <path class="cls-1" d="m1508.51,217.54h82.4c46.47,0,67.87,13.86,67.87,42.92,0,25.29-15.53,37.04-33.72,41.92,22.85,4.32,34.16,11.65,34.16,35.16v39.04h-59v-40.7c0-11.87-7.54-17.3-21.63-17.3h-11.31v58h-58.78v-159.04Zm70.65,68.21c12.87,0,21.74-4.44,21.74-17.63,0-11.87-8.32-16.19-21.74-16.19h-11.87v33.83h11.87Z"/>
      <path class="cls-1" d="m1675.05,330.67h60c0,11.31,5.99,16.53,15.19,16.53,7.43,0,12.75-3.33,12.75-10.76,0-7.99-5.88-11.53-17.3-15.53l-21.96-7.65c-26.73-9.32-47.13-20.3-47.13-48.24,0-36.49,33.27-51.02,73.64-51.02,34.82,0,70.98,13.31,70.98,48.36h-58.23c-.22-10.42-5.66-15.3-13.98-15.3-7.65,0-12.87,3.99-12.87,10.31,0,7.65,6.88,10.2,16.97,13.86l20.52,7.43c28.72,10.43,50.91,21.85,50.91,52.01,0,36.16-32.61,49.8-74.31,49.8-43.92,0-75.19-14.53-75.19-49.8Z"/>
    </g>
    <g>
      <rect class="cls-1" x="253.93" y="253.84" width="244.81" height="48.96"/>
      <rect class="cls-1" x="9.12" y="204.88" width="244.81" height="48.96"/>
      <rect class="cls-1" x="498.74" y="204.88" width="244.81" height="48.96"/>
      <polygon class="cls-1" points="498.74 57.99 253.93 57.99 253.93 9.03 9.12 9.03 9.12 106.95 253.93 106.95 253.93 155.91 498.74 155.91 498.74 106.95 743.55 106.95 743.55 9.03 498.74 9.03 498.74 57.99"/>
      <rect class="cls-1" x="9.12" y="351.76" width="734.43" height="24.48"/>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MovingLayersLogo'
})
</script>

<style lang="scss">
.cls-1{
  fill:var(--primary);
}
</style>
