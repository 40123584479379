import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfHzbaTemplate = _resolveComponent("PdfHzbaTemplate")!
  const _component_RippisteinBauflaechen = _resolveComponent("RippisteinBauflaechen")!
  const _component_RippisteinSeilzugflaechen = _resolveComponent("RippisteinSeilzugflaechen")!
  const _component_LEWMassnahme = _resolveComponent("LEWMassnahme")!
  const _component_Baumkataster = _resolveComponent("Baumkataster")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.immobilie && _ctx.ba && _ctx.ba.identifier === 'hzba')
      ? (_openBlock(), _createBlock(_component_PdfHzbaTemplate, {
          key: 0,
          ba: _ctx.ba,
          immobilie: _ctx.immobilie,
          lang: _ctx.lang
        }, null, 8, ["ba", "immobilie", "lang"]))
      : _createCommentVNode("", true),
    (_ctx.immobilie && _ctx.ba && _ctx.ba.identifier === 'rippistein-bauflaechen')
      ? (_openBlock(), _createBlock(_component_RippisteinBauflaechen, {
          key: 1,
          ba: _ctx.ba,
          immobilie: _ctx.immobilie,
          lang: _ctx.lang
        }, null, 8, ["ba", "immobilie", "lang"]))
      : _createCommentVNode("", true),
    (_ctx.immobilie && _ctx.ba && _ctx.ba.identifier === 'rippistein-seilzugflaechen')
      ? (_openBlock(), _createBlock(_component_RippisteinSeilzugflaechen, {
          key: 2,
          ba: _ctx.ba,
          immobilie: _ctx.immobilie,
          lang: _ctx.lang
        }, null, 8, ["ba", "immobilie", "lang"]))
      : _createCommentVNode("", true),
    (_ctx.immobilie && _ctx.ba && _ctx.ba.identifier === 'lew-massnahme')
      ? (_openBlock(), _createBlock(_component_LEWMassnahme, {
          key: 3,
          ba: _ctx.ba,
          immobilie: _ctx.immobilie,
          lang: _ctx.lang
        }, null, 8, ["ba", "immobilie", "lang"]))
      : _createCommentVNode("", true),
    (_ctx.immobilie && _ctx.ba && _ctx.ba.identifier === 'baumkataster')
      ? (_openBlock(), _createBlock(_component_Baumkataster, {
          key: 4,
          ba: _ctx.ba,
          immobilie: _ctx.immobilie,
          lang: _ctx.lang
        }, null, 8, ["ba", "immobilie", "lang"]))
      : _createCommentVNode("", true)
  ], 64))
}