

<template>
  <section class="pdf-content">
    <div>
      <PageCounterStart/>
      <PageCounter
        :displayCounter="false"
        :displayMaxPages="false"
      />
      <cover-page :ba="ba" :immobilie="immobilie" :lang="lang"></cover-page>
      <anmerkungen :ba="ba" :immobilie="immobilie" :lang="lang"></anmerkungen>
      <seilzugflaechen :ba="ba" :immobilie="immobilie" :lang="lang"/>
      <fremdleitung :ba="ba" :immobilie="immobilie" :lang="lang"></fremdleitung>

      <PageCounter
        :displayCounter="false"
        :displayMaxPages="false"
      />
      <PageCounterEnd/>
    </div>
  </section>
</template>


<script lang="ts">
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import CoverPage from './CoverPage.vue';
import Seilzugflaechen from './Seilzugflaechen.vue';

import Fremdleitung from './Fremdleitung.vue';
import Anmerkungen from './Anmerkungen.vue';
import PageCounter from '@/components/Pdf/Components/PageCounter';
import PageCounterStart from '@/components/Pdf/Components/PageCounterStart';
import PageCounterEnd from '@/components/Pdf/Components/PageCounterEnd';


export default defineComponent({
  name: 'PdfRippisteinSeilzugflaechenTemplate',
  components: {
    CoverPage,
    Anmerkungen,
    Seilzugflaechen,
    Fremdleitung,
    PageCounter,
    PageCounterStart,
    PageCounterEnd
  },
  props: {
     ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();
    return {
      user: uUser.user,
      t,
      PageCounter,
      PageCounterStart,
      PageCounterEnd
    };
  },
});
</script>

<style scoped>
.pdf-content {
  width: 800px;
  margin: 0 auto;
  color: white;
}

* {
  color: black;
  font-family: 'Avenir-Next-LT-Pro-Regular';
}
</style>