<template color="red">
  <ion-header class="header">
    <!-- Mobile toolbar -->
    <ion-toolbar
      color="red"
      class="mobile-header hzba-toolbar text-white flex items-end ml-background ion-no-border"
      :class="isDesktopNavEnabled ? 'lg:hidden' : ''"
    >
      <div
        v-if="routeName === 'login' && routeName === 'ProjectSelection'"
        class="container flex items-end"
      >
        <router-link
          class="flex items-center cursor-pointer ml-white-text flex-1"
          to="/properties"
        >
          <img
            src="../../../public/assets/mlLogos/logo-white-font.png"
            alt="ml_icon"
            class="logo mr-4"
          >
        </router-link>
      </div>
      <slot name="leading" />
      <ion-title
        v-if="title"
        class="ml-white-text tracking-wider md:tracking-widest text-lg"
      >
        {{ title ?? t("toolbar.kurzTitel") }}
      </ion-title>
      <slot name="trailing" />
    </ion-toolbar>

    <!-- Desktop toolbar -->
    <ion-toolbar
      v-if="isDesktopNavEnabled"
      color="red"
      class="hzba-toolbar hidden items-center text-white ml-background ion-no-border"
      :class="isDesktopNavEnabled ? 'lg:flex' : ''"
    >
      <div class="container flex items-end">
        <router-link
          class="flex items-center cursor-pointer ml-white-text flex-1"
          to="/properties"
        >
          <img
            src="../../../public/assets/mlLogos/logo-white-font.png"
            alt="ml_icon"
            class="logo mr-4"
          >
          <ion-title class="tracking-widest ml-white-text">
            MOVERS SURVEY
          </ion-title>
        </router-link>

        <div
          v-if="routeName != 'login' && routeName != 'ProjectSelection'"
          slot="secondary"
          class="desktop-links flex"
        >
          <router-link
            to="/properties"
            class="desktop-link"
          >
            <ImmobilienIcon
              class="p-1 mr-1"
              :is-active="routeName === 'propertiesList'"
            />
            <ion-label
              class="ml-white-text"
              :class="{ 'ml-forest-text': routeName === 'propertiesList' }"
            >
              {{ t(`${organisationPrefix}toolbar.immobilien`) }}
            </ion-label>
          </router-link>

          <router-link
            v-if="!user.isImmobilienverwalter()"
            to="/bas"
            class="desktop-link"
            router-direction="back"
            data-cy="desk-toolbar-bas"
          >
            <ToolbarListIcon
              class="p-1 mr-1"
              :is-active="routeName === 'bas'"
            />
            <ion-label
              class="ml-white-text"
              :class="{ 'ml-forest-text': routeName === 'bas' }"
            >
              {{ t(`${organisationPrefix}toolbar.bestandsaufnahmen`) }}
            </ion-label>
          </router-link>
          <UserDialogButton />
        </div>
      </div>
    </ion-toolbar>
  </ion-header>
  <div class="secondary-header ml-background">
    <div class="ml-background h-5" />
  </div>
</template>

<script>
import AButton from "@/components/Base/AButton";
import useUser from "@/composables/useUser";
import User from "@/models/user";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { popoverHelper } from "@/utilities/popover-helper";
import {
IonBackButton,
IonButton,
IonButtons,
IonHeader,
IonIcon,
IonImg,
IonLabel,
IonSplitPane,
IonTitle,
IonToolbar,
isPlatform,
} from "@ionic/vue";
import { home, reader, settings } from "ionicons/icons";
import { computed } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";
import ImmobilienIcon from "../../../public/assets/mlIcons/ImmobilienIcon.vue";
import ToolbarListIcon from "../../../public/assets/mlIcons/ToolbarListIcon.vue";
import UserDialogButton from '../User/UserDialogButton.vue';

export default {
  name: "Toolbar",
  components: {
    AButton,
    ImmobilienIcon,
    ToolbarListIcon,
    IonIcon,
    IonButton,
    IonLabel,
    IonBackButton,
    IonButtons,
    IonHeader,
    IonImg,
    IonTitle,
    IonToolbar,
    IonSplitPane,
    UserDialogButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    enableDesktopNav: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })
    const user = useUser();
    const router = useRouter();
    const routeName = computed(() => { return router.currentRoute.value.name; });

    const isDesktopToolbarForbidden = computed(() => {
      return !(isPlatform("ios") || isPlatform("ipad"));
    });

    const isDesktopNavEnabled = computed(() => {
      return isDesktopToolbarForbidden.value && props.enableDesktopNav;
    });

    const organisationPrefix = computed(() => {
      const currentUser = User.query().first();

      return currentUser ? currentUser.organisationPrefix : "";
    });

    return {
      home,
      settings,
      reader,
      user: user,
      openPopover: popoverHelper,
      getPhotoUrl,
      isDesktopNavEnabled,
      routeName,
      organisationPrefix,
      t
    };
  },
};
</script>

<style>
ion-toolbar {
  --padding-top: 6px;
  --padding-bottom: 6px;
}
</style>

<style scoped lang="scss">
.header {
  z-index: 4;
  background-color: var(--primary);
  padding-top: var(--ion-safe-area-top);
}

.ml-white-background {
  background: var(--white100);
}

.secondary-header {
  position: relative;
  margin: auto;
  width: calc(33.33vw);
  z-index: 4;    
  transform: translateY(50%);
  height: 0px;
}
.secondary-header-cheat{
  height: 30px;
}
.hzba-toolbar {
  height: $navigationHeight;
}

//@supports(padding: unquote('max(0px)')) {
ion-toolbar {
  padding-top: unquote('constant(safe-area-inset-top))') !important;
  padding-top: env(safe-area-inset-top) !important;
}

//}

.logo {
  height: 36px;
  filter: brightness(0) invert(1);
}

.title,
ion-title {
  font-weight: 550;
  text-transform: uppercase;
}

.desktop-link,
.title {
  color: var(--white100);
  text-decoration: none;
}

.desktop-link {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-right: 24px;
  text-transform: uppercase;
  font-weight: 550;
  font-size: 16px;
  line-height: 39px;
  padding: 0 8px;

  &.router-link-active {
    border-bottom: 2px solid var(--secondary);
  }

  ion-icon {
    margin-right: 6px;
  }
}
</style>