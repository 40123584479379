export enum ImmobilieStatus {
  ANGELEGT = 'ANGELEGT',
  GEPLANT = 'GEPLANT',
  IN_DURCHFUEHRUNG = 'IN_DURCHFUEHRUNG',
  ABGESCHLOSSEN = 'ABGESCHLOSSEN',
  FREIGEGEBEN = 'FREIGEGEBEN',
  ARCHIVIERT = 'ARCHIVIERT'
}

export const translatedPropertyStatus = (key: any, t: any) => {
  switch (key) {
    case ImmobilieStatus.ANGELEGT:
      return t('immobilie.statusOptions.statusAngelegt');
    case ImmobilieStatus.GEPLANT:
      return t('immobilie.statusOptions.statusGeplant');
    case ImmobilieStatus.IN_DURCHFUEHRUNG:
      return t('immobilie.statusOptions.statusInDurchführung');
    case ImmobilieStatus.ABGESCHLOSSEN:
      return t('immobilie.statusOptions.statusAbgeschlossen');
    case ImmobilieStatus.FREIGEGEBEN:
      return t('immobilie.statusOptions.statusFreigegeben');
    case ImmobilieStatus.ARCHIVIERT:
      return t('immobilie.statusOptions.statusArchiviert');
  }
};