import { useProperties } from "@/composables/Property/useProperties";
import useAlert from "@/composables/useAlert";
import { useMetaData } from "@/composables/useMetaData";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import Immobilie from "@/models/immobilie.model";
import { setLocalMetaData } from "@/models/local-meta-data";
import { setCache } from "@/store/storage/ionicStorage";
import { useBestandsaufnahmens } from "./Bestandsaufnahme/useBestandsaufnahmens";
import { useCreateSurvey } from "./useCreateSurvey";
import { useFaqs } from "./useFaqs";


export default function useFetchData() {

    const { fetchMetaData } = useMetaData();
    const { loadImmobilienPreviews } = useProperties();
    const { loadBestandsaufnahmens } = useBestandsaufnahmens();
    const { loadFaqs } = useFaqs();

    const store = useStore();
    const alert = useAlert();


    async function fetch(alertWhenOffline = false) {

        const uUser = await useUser();

        if (!await uUser.isLoggedIn() || !uUser.isApproved.value) {
            return;
        }

        if(alertWhenOffline && store.getters['app/isOffline']){
            alert.show("Sie sind offline.", "Um aktuelle Daten zu erhalten, stellen Sie bitte eine Internetverbindung her.")
        }

        if (!store.getters['app/isOffline']){
            await setLocalMetaData('lastBackendFetch', (new Date()).toISOString())
        }
        try {
            const templates = await useCreateSurvey().fetchTemplates()
            await setCache('cachedSurveyTemplates', JSON.stringify(templates))
        } catch(err) {
            console.log(err)
        }
        await Promise.all([
            loadImmobilienPreviews(),
            fetchMetaData(),
            loadFaqs(),
            loadBestandsaufnahmens()
        ]);

    }


    /**
     * Discard current loaded data and load new
     */
    const doRefresh = async (clickEvent?: any) => {
        console.log('doRefresh ...');
        
        BestandsaufnahmeModel.dispatch("setPage", 0);
        BestandsaufnahmeModel.deleteAll();
        Immobilie.dispatch("setPage", 0);
        Immobilie.deleteAll();

        await fetch(clickEvent ? true : false);
        clickEvent?.target?.complete();
    };

    return {
        fetch,
        doRefresh,
    }
}