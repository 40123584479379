<template>
  <ion-card
    v-if="ba && immobilie"
    class="hzbaCard"
    :class="`${
      !user.isImmobilienverwalter() ? 'cursor-pointer' : ''
    } m-0 rounded-sm bg-white acon-card-shadow`"
    @click="goToBa($event)"
  >
    <div class="">
      <a-image
        :image-class="'w-full h-44 object-cover md:block'"
        :src="immobilie && getPhotoUrl(immobilie.vorschaubild, { small: true })"
      />
      <div class="ml-4 mt-4 flex-1">
        <div class="md:flex">
          <div class="flex-1 text-left">
            <div>{{ ba.getName && ba.getName() }}</div>
            <h3
              ion-text
              class="mt-0 mb-0"
              color="primary"
            >
              {{ immobilie && immobilie.externeObjektNr }} ({{
                moment(ba.begehungsdatum).format("YYYY")
              }})
            </h3>

            <p class="mb-0 flex items-center">
              <IonIcon
                :icon="locationOutline"
                class="mr-1"
              />
              {{ immobilie && ( immobilie.strasse ? immobilie.strasse + "," : "" ) }}
              {{ immobilie && immobilie.plz }}
              {{ immobilie && immobilie.stadt }}
            </p>

            <div>
              <p class="mt-2 mb-0 flex items-center">
                <IonIcon
                  :icon="calendarOutline"
                  class="mr-1"
                />
                {{ moment(ba.begehungsdatum).format("DD.MM.YYYY") }}
                <IonIcon
                  :icon="timeOutline"
                  class="ml-3 mr-1"
                />
                {{ moment(ba.begehungsdatum).format("HH:mm") }}
              </p>
            </div>
          </div>
          <!--          <div class="text-right hidden md:block ">-->
          <!--            <h5 class="mt-0">-->
          <!--              Begehung am-->
          <!--              {{ new Date(ba.begehungsdatum).toLocaleDateString("de-De") }}-->
          <!--            </h5>-->
          <!--            <h6>-->
          <!--              um-->
          <!--              {{-->
          <!--                new Date(ba.begehungsdatum).toLocaleTimeString("de-De", {-->
          <!--                  timeStyle: "short",-->
          <!--                })-->
          <!--              }}-->
          <!--            </h6>-->
          <!--          </div>-->
        </div>
      </div>
    </div>

    <div class="items-center md:justify-between m-4 mt-4">
      <div class="flex items-end flex-wrap">
        <PdfChip
          v-if="ba.malus"
          class="mr-2"
          :value="ba.malus"
          :color="malusColors[ba.malusColor]"
          :big="true"
        />

        <span
          class="
            btn-secondary
            inline-flex
            items-center
            mr-2.5
            px-4
            py-1.5
            rounded-full
            text-sm
            font-medium
            text-blue-600
            statusbutton
          "
        >
          <span
            v-if="!HzbaStatus[ba.status]"
            class="mr-2"
          >
            Status unbekannt
          </span>
          <span
            v-else
            class="mr-2"
          >
            {{ translatedHzbaStatus(ba.status, t) }}
          </span>

          <ion-icon
            v-if="HzbaStatus.ANGELEGT == HzbaStatus[ba.status]"
            :icon="ellipseOutline"
            size="small"
          />
          <ion-icon
            v-if="
              HzbaStatus.GEPLANT == HzbaStatus[ba.status] ||
                HzbaStatus.IN_DURCHFUEHRUNG == HzbaStatus[ba.status]
            "
            :icon="ellipsisHorizontalCircle"
            size="small"
          />
          <ion-icon
            v-if="
              HzbaStatus.ABGESCHLOSSEN == HzbaStatus[ba.status] ||
                HzbaStatus.FREIGEGEBEN == HzbaStatus[ba.status] || 
                HzbaStatus.ARCHIVIERT == HzbaStatus[ba.status]
            "
            :icon="checkmarkCircle"
            size="small"
          />
          <ion-icon
            v-if="!HzbaStatus[ba.status]"
            :icon="alertCircleOutline"
            size="small"
          />
        </span>
        <div
          v-if="alreadyInIndexDB"
          class="alreadyInIndexDB"
          @mouseover="openToast()"
          @mouseout="closeToast()"
          @click="$event.stopPropagation(); closeToast(); removeFromIndexDB()"
        >
          <ion-icon :icon="arrowDownCircle" />
        </div>
      </div>
      <div class="downloadPart my-4">
        <DownloadSurveyButton
          v-if="featureFlags.downloadSurvey && !alreadyInIndexDB"
          :is-downloading="isDownloading"
          @onClick="downloadBestandsaufnahme"
        />
      </div>

      <div class="text-left mt-2 sm:mb-0">
        <a-button
          v-if="recommendedBericht(ba)"
          :btn-secondary="true"
          class="w-full mb-1 p-4 text-base"
          shape="round"
          data-cy="button-berichtAnzeigen"
          @click="openBericht($event, recommendedBericht(ba))"
        >
          {{ t("hzba.buttons.berichtAnzeigen") }}
        </a-button>

        <a-button
          v-if="relevantMaengellist && relevantMaengellist.length > 0"
          :btn-secondary="true"
          class="w-full mb-1 text-base"
          shape="round"
          data-cy="button-mangellisteAnzeigen"
          @click="openMaengelInImmobilie($event)"
        >
          {{ t("immobilie.buttons.mangellisteAnzeigen") }}
        </a-button>
      </div>
    </div>
  </ion-card>
</template>

<script lang="ts">
import AButton from "@/components/Base/AButton";
import AImage from "@/components/Base/AImage.vue";
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";
import DownloadSurveyButton from '@/components/v2/General/DownloadSurveyButton.vue';

import { useBestandsaufnahmens } from "@/composables/Bestandsaufnahme/useBestandsaufnahmens";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import {
HzbaStatus,
translatedHzbaStatus,
} from "@/models/ba/interfaces/IBestandsaufnahme";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import Immobilie from "@/models/immobilie.model";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { logger } from "@/utilities/logging";
import { openBaseModal } from "@/utilities/modal-helper";
import {
IonBadge,
IonButton,
IonCard,
IonCardHeader,
IonCardSubtitle,
IonCardTitle,
IonChip,
IonIcon,
IonLabel,
toastController
} from "@ionic/vue";
import {
alertCircleOutline,
arrowDownCircle,
calendarOutline,
checkmarkCircle,
cloudOfflineOutline,
ellipseOutline,
ellipsisHorizontalCircle,
locationOutline,
timeOutline,
} from "ionicons/icons";
import moment from "moment";
import { PropType, computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "HzbaCard",
  components: {
    AButton,
    PdfChip,
    DownloadSurveyButton,
    AImage,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonButton,
    IonIcon,
    IonLabel,
    IonBadge,
    IonChip,
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const { t } = useI18n({ useScope: "global" });
    const user = useUser();
    const router = useRouter();
    const featureFlags = computed(() => user.user.value?.organisation?.featureFlags);

    const immobilie = computed(() => {
      return Immobilie.getters('getPropOrFallbackProp')(props.ba.immobilie);
    });
    
    const malusColors = computed(() => {
      return store.getters["app/malusColors"];
    });

    const openBericht = (e: any, pdf: any) => {
      e.stopImmediatePropagation();
      openBaseModal("pdf.view", { pdf: pdf });
    };

    const openMaengel = (e: any) => {
      e.stopImmediatePropagation();
      openBaseModal("maengel.uebersicht", { ba: props.ba });
    };

    const relevantMaengellist = computed(() => {
      if (props.ba && Array.isArray(props.ba.mangellists)) {
        return props.ba.mangellists.filter((el: any) => el.eingabeRelevant);
      }
      return [];
    })

    const openMaengelInImmobilie = (e: any) => {
      e.stopImmediatePropagation();
      openBaseModal("maengel.immobilie.uebersicht", { ba: props.ba });
    };

    const recommendedBericht = (ba: any) => {
      if (ba.berichts && ba.berichts.length > 0) {
        const bericht = ba.berichts.find((el: any) =>
          el.kennzeichen.endsWith("-de")
        );
        return bericht && bericht.data;
      }
      return ba.bericht;
    };

    const recommendedMangelliste = (ba: any) => {
      if (ba.mangellists && ba.mangellists.length > 0) {
        // console.log('ba.mangellists: ', ba.mangellists[0].mangelvorlage.kategorie);
      }
      return ba.mangellists;
    };

    const goToBa = async (e: any) => {
      e.stopImmediatePropagation();
      if (!user.isImmobilienverwalter()) {
        await router.push("/ba/" + props.ba.id );
        logger.defaultMeta.currentBa = props.ba.id
        logger.info(`User opened BA ${props.ba.id}`);
      }
    };

    return {
      arrowDownCircle,
      immobilie,
      getPhotoUrl,
      openBericht,
      alertCircleOutline,
      checkmarkCircle,
      relevantMaengellist,
      ellipseOutline,
      ellipsisHorizontalCircle,
      cloudOfflineOutline,
      HzbaStatus,
      calendarOutline,
      moment,
      locationOutline,
      timeOutline,
      recommendedBericht,
      recommendedMangelliste,
      translatedHzbaStatus,
      t,
      openMaengel,
      openMaengelInImmobilie,
      goToBa,
      user,
      malusColors,
      featureFlags,
      persistedBestandsaufnahmes: computed(() => BestandsaufnahmeModel.getters("persistedBestandsaufnahmes"))
    };
  },
  data() {
    return {
      toast: null,
      isDownloading: false
    }
  },
  computed: {
    alreadyInIndexDB() {
      if(this.persistedBestandsaufnahmes?.some((item: any) => item.id === this.ba.id)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    alreadyInIndexDB() {
      if(this.toast) {
        this.closeToast()
      }
    }
  },
  methods: {
    async downloadBestandsaufnahme() {
      this.isDownloading = true

      try {
        const { downloadBa } = useBestandsaufnahmens()
        await downloadBa(this.ba)
        this.isDownloading = false
      } catch(err: any) {
        console.log(err)
        this.isDownloading = false
      }
    },

    async removeFromIndexDB() {
      await useBestandsaufnahmens().removeDownloadedBa(this.ba)
    },

    async openToast() {
      this.toast = await toastController.create({
        message: this.t("hzba.toast.downloadedInfo"),
        duration: 500,
        position: 'bottom',
        cssClass: 'downloadInfoToast'
      });
      await this.toast.present();
    },

    closeToast() {
      this.toast?.dismiss();
    },
  }
});
</script>

<style lang="scss">
.hzbaCard {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--black100);
  }
  
  p {
    color: var(--black100);
    font-size: 16px;
    font-weight: 400;
  }
  
  ion-card {
    box-shadow: $boxShadow;
  }
  
  .alreadyInIndexDB {
    width: 32px;
    height: 32px;
  
    ion-icon {
      width: 100%;
      height: 100%;
    }
  }
  
}
ion-toast.downloadInfoToast {
  --background: var(--white100);
  --color: var(--primary);
}
.statusbutton{
  border: 1px solid var(--primary);
}
</style>
