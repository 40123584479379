import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateHzbaCard = _resolveComponent("CreateHzbaCard")!
  const _component_hzba_card = _resolveComponent("hzba-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["grid grid-cols-1 lg:p-0 sm:grid-cols-1 md:grid-cols-2 gap-5", [
      _ctx.propertyView ? 'lg:grid-cols-2' : 'lg:grid-cols-3',
      _ctx.propertyView ? 'xl:grid-cols-3' : 'xl:grid-cols-4',
    ]
    ])
  }, [
    (_ctx.propertyView && _ctx.featureFlags?.createSurvey)
      ? (_openBlock(), _createBlock(_component_CreateHzbaCard, {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openAufnahmenModal()))
        }))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hzbas, (ba) => {
      return (_openBlock(), _createBlock(_component_hzba_card, {
        key: ba.id,
        ba: ba
      }, null, 8, ["ba"]))
    }), 128))
  ], 2))
}