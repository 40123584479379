import { getCache, setCache } from "@/store/storage/ionicStorage";
import { Network } from "@capacitor/network";

export function isEmptyObject(obj: any) {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype
}


/**
 * @param t
 * @param minMax
 * @param currentPhotoLength skip this parameter if you want the label regardless of the current input
 */
export function getPhotoMinMaxLabel(t: any, minMax?: { min?: any, max?: any}, currentPhotoLength?: number): string | undefined {
    if (!minMax || (minMax.min === undefined && minMax.max === undefined)) {
        return undefined;
    }
    if (currentPhotoLength && (currentPhotoLength >= minMax.min || currentPhotoLength <= minMax.max)) {
        return undefined;
    }

    const min = minMax.min;
    const max = minMax.max;

    if (!max && min !== 1) { return t('hzba.benoetigtMinXMedien', { amount: min }); }
    if (!max && min === 1) { return t('hzba.benoetigtMax1Medien') }

    if (!min && max !== 1) { return t('hzba.benoetigtMaxXMedien', { amount: max }); }
    if (!min && max === 1) { return t('hzba.benoetigtMax1Medien') }

    if (min !== max) { return t('hzba.benoetigtZwischenXYMedien', { min, max })}
    if (min === max && min !== 1) { return t('hzba.benoetigtGenauXMedien', { amount: min })}
    if (min === max && min === 1) { return t('hzba.benoetigtGenau1Medium');}
    return `${min}/${max}`
}

export function findNestedObjectByTargetKeyValue (parentObject: any, targetKey: string, targetValue: string) {
    //Just return null if it's not an object
    if (typeof parentObject !== 'object' || parentObject === null) {
        return null;
    }
    
    // Check do we have correct object before for loop
    if (Object.prototype.hasOwnProperty.call(parentObject, targetKey) && parentObject[targetKey] === targetValue) {
        return parentObject;
    }

    //Search through every object until we found correct one
    for (const key of Object.keys(parentObject)) {
        const result = findNestedObjectByTargetKeyValue(parentObject[key], targetKey, targetValue) as any
        // When founded just return it
        if (result !== null) {
            return result;
        }
    }

    //If not founded return null
    return null;
}

//This function will update nested object and when updated it will return updated parentObject//
export function updateNestedObject(parentObject: any, targetId: number, targetIdentifier: string, newValue: any) {
    let foundAndReplaced = false;
    const visited = new Set();

    function traverse(obj: any): void {
        if (!obj || foundAndReplaced || visited.has(obj)) {
            return;
        }

        // Add the current object to the set of visited objects
        visited.add(obj);

        // If object is found, replace it with newValue
        if (obj.id === targetId && obj.identifier === targetIdentifier) {
            foundAndReplaced = true;
            obj.eingabeJson = newValue.eingabeJson,
            obj.eingabeMehrfachAuswahlWerts = newValue.eingabeMehrfachAuswahlWerts,
            obj.eingabeText = newValue.eingabeText,
            obj.eingabeZahl = newValue.eingabeZahl,
            obj.eingabeBoolean = newValue.eingabeBoolean,
            obj.eingabeAuswahlWert = newValue.eingabeAuswahlWert
            return;
        }

        // If the current property is an object or an array, traverse it
        if (typeof obj === 'object') {
            for (const key in obj) {
                if (Object.hasOwnProperty.call(obj, key)) {
                    traverse(obj[key]);
                }
            }
        }
    }

    traverse(parentObject);

    return parentObject;
}

export function handleOfflineSurveyData(offlineSurvey: any, onlineSurvey: any) {
    function traverseBlocks(blocksA: any, blocksB: any, surveyId: any) {
        for (let i = 0; i < blocksA.length; i++) {
            const blockA = blocksA[i];
            const blockB = blocksB[i];
            if (blockA && blockB && Object.prototype.hasOwnProperty.call(blockA, 'fragenblocks') && 
                Object.prototype.hasOwnProperty.call(blockB, 'fragenblocks') &&
                Array.isArray(blockA.fragenblocks) && Array.isArray(blockB.fragenblocks)) {
                traverseBlocks(blockA.fragenblocks, blockB.fragenblocks, surveyId);
            } else {
                blocksB.push(blockA)
            }
            if (Object.prototype.hasOwnProperty.call(blockA, 'frages') && 
                Object.prototype.hasOwnProperty.call(blockB, 'frages') &&
                Array.isArray(blockA.frages) && Array.isArray(blockB.frages)) {

                for (let j = 0; j < blockA.frages.length; j++) {
                    const frageA = blockA.frages[j];
                    const frageB = blockB.frages[j];
                    if (frageB) {
                        Object.keys(frageB).forEach(key => {
                            if (key.startsWith('eingabe') && Object.prototype.hasOwnProperty.call(frageA, key)) {
                                frageB[key] = frageA[key];
                            }
                        });
                    }
                }
            }
        }
    }

    traverseBlocks(offlineSurvey.fragenblocks, onlineSurvey.fragenblocks, onlineSurvey.id);

    return onlineSurvey;
}

export async function cacheOfflineSurvey(survey: any) {
    const networkStatus = await Network.getStatus();
    if(!networkStatus.connected) {
        if(survey.createdAt) {
            cacheOnlineCreatedSurvey(survey)
        } else {
            cacheOfflineCreatedSurvey(survey)
        }
    }
}

async function cacheOnlineCreatedSurvey(survey: any) {
    const jsonData = await getCache('onlineSurveys')
    let dataFromStorage = JSON.parse(jsonData)
    // survey = await survey?.copyLeanJson({prepareForSync: true});
    if(dataFromStorage) {
        const index = dataFromStorage.findIndex((item: any) => item.id === survey.id);
        if (index !== -1) {
            dataFromStorage[index] = survey;
        } else {
            dataFromStorage.push(survey);
        }
    } else {
        dataFromStorage = [survey]
    }

    await setCache('onlineSurveys', JSON.stringify(dataFromStorage))
}

async function cacheOfflineCreatedSurvey(survey: any) {
    const jsonData = await getCache('offlineSurveys')
    const selectedTemplate = await getCache('selectedSurveyTemplate')
    const parsedTemplate = JSON.parse(selectedTemplate)
    // survey = await survey?.copyLeanJson({prepareForSync: true});
    survey.surveyType = parsedTemplate.attributes.uid
    let dataFromStorage = JSON.parse(jsonData)

    if(dataFromStorage) {
        const index = dataFromStorage.findIndex((item: any) => item.id === survey.id);
    
        if (index !== -1) {
            dataFromStorage[index] = survey;
        } else {
            dataFromStorage.push(survey);
        }
    } else {
        dataFromStorage = [survey]
    }

    await setCache('offlineSurveys', JSON.stringify(dataFromStorage))
}
