<template>
  <ion-button
    v-if="!isReadonly"
    fill="clear"
    color="danger"
    class="my-0"
    data-cy="delete-entity-button"
    :size="largeIcons ? 'default' : 'small'"
    @click="displayAlert()"
  >
    <ion-icon
      :icon="trashOutline"
      :size="largeIcons ? 'large' : 'small'"
    />
  </ion-button>
  <ion-button
    v-if="!isReadonly"
    fill="clear"
    color="dark"
    data-cy="duplicate-entity-button"
    class="my-0"
    :size="largeIcons ? 'default' : 'small'"
    @click="$emit('duplicate')"
  >
    <ion-icon
      :icon="duplicateOutline"
      :size="largeIcons ? 'large' : 'small'"
    />
  </ion-button>

  <button
    v-if="!isReadonly && !hideAddButton"
    class="btn-no-styling my-0"
    data-cy="button-add-items"
    fill="clear"
    color="dark"
    :size="largeIcons ? 'default' : 'small'"
    @click="$emit('add')"
  >
    <ion-icon
      :icon="addOutline"
      :size="largeIcons ? 'large' : 'small'"
    />
  </button>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { IonIcon, IonButton } from "@ionic/vue";
import { store } from "@/store/store";
import { useI18n } from 'vue-i18n';
//Composables
import useAlert from "@/composables/useAlert";
import useScreenSize from "@/composables/useScreenSize";
//Icons
import { duplicateOutline, trashOutline , addOutline } from 'ionicons/icons';
//Helpers
import { popoverHelper } from "@/utilities/popover-helper";

export default defineComponent({
  name: "HzbaGroupCtas",
  components: {
    IonIcon,
    IonButton
  },
  props: {
    hideAddButton: {
      type: Boolean,
      default: false
    },
    onAdd: {
      type: Function,
      required: false
    },
  },
  emits: ['add', 'duplicate', 'delete'],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: 'global' })
    const alert = useAlert();
    const screenSize = useScreenSize();

    const largeIcons = computed(() => {
      return screenSize.screenWidth.value >= screenSize.screenTypes.SCREEN_LG_START
    })

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    function displayAlert() {
      alert.showAdv(
        t("alerts.deleteTitle"),
        t("alerts.deleteDescription"),
        [
          { text: t("alerts.deleteCancel"), },
          { text: t("alerts.deleteConfirm"), handler: () => { emit('delete')} },
        ]
      )
    }

    return {
      displayAlert,
      t,
      isReadonly,
      openPopover: popoverHelper,
      duplicateOutline,
      trashOutline,
      addOutline,
      screenSize,
      largeIcons
    }
  }
})
</script>

<style>

.button-native {
  padding-left: 0 !important;
  padding-inline-start: 0 !important;
}

</style>