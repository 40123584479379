<template>
  <ion-item
    lines="none"
    data-cy="select-input-item"
  >
    <ion-grid
      class="p-0"
      :class="separator ? 'border-bottom-1-light' : ''"
    >
      <ion-row class="d-flex items-center">
        <input-label>{{ label }}</input-label>
        <ion-col class="ion-align-self-end ion-float-right">
          <ion-select
            class="select-full-width"
            data-cy="select-item"
            :value="modelValue"
            :placeholder="multiple ? t('hzba.mehrfachAuswahlPlatzhalter') : t('hzba.auswahlPlatzhalter')"
            :multiple="multiple ?? false"
            cancel-text="abbrechen"
            :disabled="readonly"
            ok-text="Ok"
            @ionChange="onChanged"
          >
            <ion-select-option
              v-for="(option, index) in options"
              :key="option"
              style="flex-grow: 1"
              :value="option"
              :data-cy="`option-${option}`"
            >
              {{ labels && labels[index] ? labels[index] : option }}
            </ion-select-option>
          </ion-select>
        </ion-col>
        <unit-chip
          :show-unit="showUnit"
          :unit="unit"
        />
      </ion-row>


      <ion-row
        v-if="dependentReason"
        class="ion-wrap d-flex items-center"
      >
        <ion-col>
          <ion-text class="text-gray-600 text-sm">
            {{ dependentReason }}
          </ion-text>
        </ion-col>
      </ion-row>


      <input-error :errors="errors" />
    </ion-grid>
  </ion-item>
</template>


<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n';
import { IonItem, IonCol, IonRow, IonGrid, IonText, IonInput, IonLabel, IonSelectOption, IonSelect } from "@ionic/vue";
//Components
import UnitChip from "@/components/hzba/Base/Input/InputElements/UnitChip";
import InputLabel from "@/components/hzba/Base/Input/InputElements/InputLabel";
import InputError from "@/components/hzba/Base/Input/InputElements/InputError";

export default defineComponent({
  name: "HzbaSelectInput",
  components: {
    InputError,
    InputLabel,
    UnitChip,
    IonItem,
    IonCol,
    IonRow,
    IonGrid,
    IonText,
    IonInput,
    IonLabel,
    IonSelect,
    IonSelectOption
  },
  props: {
    modelValue: { type: [Array, String], default: undefined, },
    label: { type: String, required: true },
    dependentReason: { type: String, default: '' },
    errors: { type: Array, default: () => [] },
    labels: { type: Array },
    options: { type: Array, required: true },
    multiple: { type: Boolean, default: true },
    unit: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    showUnit: { type: Boolean, default: false },
    separator: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
     const { t } = useI18n({ useScope: 'global' })

    async function onChanged(e) {
      emit('update:modelValue', e.target.value);
      emit('updateSocket')
      if (e.target.value !== props.modelValue) {
        emit('change', e.target.value);
      }
    }

    return {
      onChanged,
      t
    }
  }
})
</script>

<style scoped>

.select-full-width {
  max-width: 100% !important;
  width: 100% !important;
  padding-left: 0 !important;
}

ion-label {
  max-width: unset !important;
}

ion-select {
  max-width: unset;
  text-align: right;
}

</style>

<style>

.select-placeholder {
  text-align: right;
}
</style>