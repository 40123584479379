<template>
  <div>
    <ion-item
      :class="hasError ? 'input-error' : ''"
      style="padding: 0px !important;"
      class="p-0"
    >
      <ion-label position="floating">
        <div class="mb-4">
          {{ label }}
        </div>
      </ion-label>
      <ion-input
        :type="inputType"
        inputmode="email"
        name="email"
        pattern="email"
        :disabled="readonly"
        :value="modelValue"
        @input="onChanged"
      />
    </ion-item>
    <ion-text v-if="hasError">
      {{ errors[0].$message }}
    </ion-text>
  </div>
</template>

<script>

import { IonItem, IonInput, IonText, IonLabel } from '@ionic/vue';
import {defineComponent} from "vue";
import {computed} from "vue";

export default defineComponent ({
  name: "AInput",
  components: {
    IonText,
    IonInput,
    IonItem,
    IonLabel
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    inputType: {
      type: String,
      default: 'text'
    },
    readonly: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    function onChanged(e) {
      emit('update:modelValue', e.target.value);
    }

    const hasError = computed(() => {
      return props.errors && props.errors.length > 0 && props.errors[0].$message
    })
    return {
      onChanged,
      hasError
    }
  }
})

</script>

<style lang="scss" scoped>

ion-label {
  color: var(--black100);
}

ion-item {
  --ion-background-color: none;
  --padding-end: 0px;
  --inner-padding-end: 0px;
  margin-left: -16px;
  // here other custom CSS from documentation
}

ion-item.item-has-focus > ion-label{
  color: var(--black100) !important;
}

ion-item.item-has-focus {
  --highlight-background: linear-gradient(101deg, #6395fc, var(--error100));
}
ion-item.input-error {
  --highlight-background: var(--error100) !important;
}

ion-text {
  color: var(--error100);
  padding: 0.5rem 0;
  font-size: 12px;

  align-items: center;
  text-align: end;
  display: block;
}
</style>