import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b95ef71"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flex-1"
}
const _hoisted_2 = {
  key: 1,
  class: "flex-1"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { class: "mx-4 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_AFlatButton = _resolveComponent("AFlatButton")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { "data-cy": "selector-add-item" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_ion_item, {
          key: item,
          lines: "none",
          class: "cursor-pointer select-none",
          onClick: ($event: any) => (_ctx.chooseItem(item))
        }, {
          default: _withCtx(() => [
            (_ctx.currentItem && _ctx.currentItem.label === item.label)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("strong", null, _toDisplayString(item.label), 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(item.label), 1)),
            (_ctx.currentItem && _ctx.currentItem.label === item.label && _ctx.currentItem.orderBy === 'asc')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_IonIcon, {
                    icon: _ctx.chevronUpOutline,
                    size: "default"
                  }, null, 8, ["icon"])
                ]))
              : (_ctx.currentItem && _ctx.currentItem.label === item.label && _ctx.currentItem.orderBy === 'desc')
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_IonIcon, {
                      icon: _ctx.chevronDownOutline,
                      size: "default"
                    }, null, 8, ["icon"])
                  ]))
                : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128)),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_AFlatButton, {
          expand: true,
          onClick: _ctx.dismiss
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("hzba.buttons.filterAnwenden")), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }))
}