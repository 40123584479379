import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 lg:p-0 sm:grid-cols-1 md:grid-cols-2 gap-5 lg:grid-cols-3 xl:grid-cols-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PropertyCard = _resolveComponent("PropertyCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.properties, (property) => {
      return (_openBlock(), _createBlock(_component_PropertyCard, {
        key: property.id,
        property: property,
        class: "m-0"
      }, null, 8, ["property"]))
    }), 128))
  ]))
}