import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d34519c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-0 mb-0" }
const _hoisted_2 = {
  class: "panel",
  "data-cy": "faq-item-antwort"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { class: "rounded-xl bg-white" }, {
    default: _withCtx(() => [
      _createElementVNode("button", {
        class: "accordion",
        "data-cy": "button-faq-item",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.headerClicked && _ctx.headerClicked(...args)))
      }, [
        _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.getCleanedFaq().frage), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Markdown, {
          source: _ctx.getCleanedFaq().antwort
        }, null, 8, ["source"])
      ])
    ]),
    _: 1
  }))
}