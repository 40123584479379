import {
  useIdentifierFrageFinder,
  useIdentifierFragenblockFinder,
} from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import { Fragenblock } from '@/models/ba/Fragenblock';

function getAuswahlWert(ba: Bestandsaufnahme, auswahlFragesIdentifier: string) {
  return useIdentifierFrageFinder(ba, auswahlFragesIdentifier)?.[
    'eingabeAuswahlWert'
  ];
}

function get1NModalAuswahlWert(
  ba: Bestandsaufnahme,
  modalIdentifier: string,
  auswahlFragesIdentifier: string
) {
  const modal = useIdentifierFragenblockFinder(ba, modalIdentifier);

  const modale = modal?.fragenblocks?.filter(
    (block) => !block.config.isTemplate
  );

  return modale
    ?.map((modal) => {
      return modal.fragenblocks.map((fragenBlock) => {
        return useIdentifierFrageFinder(
          ba,
          fragenBlock.path + '.' + auswahlFragesIdentifier
        )?.['eingabeAuswahlWert'];
      });
    })
    .flat(1);
}

export function useSeilzugflaechen() {
  const getGrabenquerungenBewertungen = (ba: Bestandsaufnahme, fragenblock: Fragenblock) => {
    const grabenModals = extractModalsFrom1NModalsMehrfach(
      ba,
      fragenblock,
      'zuwegung-seilzugflaeche-modal.grabenquerung-group'
    );

    const res = grabenModals?.map((modal: any) => {
      const elems = modal?.frages?.map((f: any) => {
        if (f.identifier === 'graben-bewertung') {
          return { 'graben-bewertung': f.eingabeAuswahlWert };
        }
      });
      return Object.assign(elems[4]);
    });
    return res;
  }
  const getSeilzugflaecheBewertungen = (ba: Bestandsaufnahme, fragenblock: Fragenblock) => {
    return {
      seilzugflaeche: {
        //Algemein
        'entfernung-mast-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.allgemein.entfernung-mast-bewertung')?.['eingabeAuswahlWert'],
        'nutzungsart-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.allgemein.nutzungsart-bewertung')?.['eingabeAuswahlWert'],
        'fremdobjekte-auf-flaeche-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.allgemein.fremdobjekte-auf-flaeche-bewertung')?.['eingabeAuswahlWert'],
        'sonstiges-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.allgemein.sonstiges-bewertung')?.['eingabeAuswahlWert'],
        //Gelände
        'gefaelle-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.gefaelle-group.gefaelle-bewertung')?.['eingabeAuswahlWert'],
        'ausholzung-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.ausholzung-group.ausholzung-bewertung')?.['eingabeAuswahlWert'],
        'rodung-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.rodung-group.rodung-bewertung')?.['eingabeAuswahlWert'],
        'graben-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.graben-group.graben-bewertung')?.['eingabeAuswahlWert'],
        'feuchtgebiete-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.feuchtgebiete-group.feuchtgebiete-bewertung')?.['eingabeAuswahlWert'],
        'naturdenkmaeler-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.naturdenkmaeler-group.naturdenkmaeler-bewertung')?.['eingabeAuswahlWert'],
        'kampfmittel-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.kampfmittel-group.kampfmittel-bewertung')?.['eingabeAuswahlWert'],
        'gelaende-sonstiges-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.sonstiges-group.sonstiges-bewertung')?.['eingabeAuswahlWert'],
        //Ökologie
        'ampelkarte-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.oekologie-group.ampelkarte-bewertung')?.['eingabeAuswahlWert'],
        'ffh-gebiet-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.oekologie-group.ffh-gebiet-bewertung')?.['eingabeAuswahlWert'],
        'biotop-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.oekologie-group.biotop-bewertung')?.['eingabeAuswahlWert'],
        'vogelschutzgebiet-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.oekologie-group.vogelschutzgebiet-bewertung')?.['eingabeAuswahlWert'],
        'wasserschutz-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.oekologie-group.wasserschutzgebiet-bewertung')?.['eingabeAuswahlWert'],
        'oekologie-sonstiges-bewertung': fragenblock.findFrageByRelativePath(ba, 'seilzugflaeche-modal.oekologie-group.sonstiges-bewertung')?.['eingabeAuswahlWert'],
      },
      zuwegungSeilzugflaeche: {
        //Algemein
        'fremdobjekte-auf-flaeche-bewertung': fragenblock.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.allgemein.fremdobjekte-auf-flaeche-bewertung')?.['eingabeAuswahlWert'],
        'sonstiges-bewertung': fragenblock.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.allgemein.sonstiges-bewertung')?.['eingabeAuswahlWert'],
        //Gelände
        'beschaffenheit-bewertung': fragenblock.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.beschaffenheit-group.beschaffenheit-bewertung')?.['eingabeAuswahlWert'],
        'gefaelle-bewertung': fragenblock.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.gefaelle-group.gefaelle-bewertung')?.['eingabeAuswahlWert'],
        'ausholzung-bewertung': fragenblock.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.ausholzung-group.ausholzung-bewertung')?.['eingabeAuswahlWert'],
        'rodung-bewertung': fragenblock.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.rodung-group.rodung-bewertung')?.['eingabeAuswahlWert'],
        'zuwegung-breite-bewertung': fragenblock.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.zuwegung.zuwegung-breite-bewertung')?.['eingabeAuswahlWert'],
        'gelaende-sonstiges-bewertung': fragenblock.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.sonstiges-group.sonstiges-bewertung')?.['eingabeAuswahlWert'],
        //Ökologie
        'ampelkarte-bewertung': fragenblock.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.oekologie-group.ampelkarte-bewertung')?.['eingabeAuswahlWert'],
        'ffh-gebiet-bewertung': fragenblock.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.oekologie-group.ffh-gebiet-bewertung')?.['eingabeAuswahlWert'],
        'biotop-bewertung': fragenblock.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.oekologie-group.biotop-bewertung')?.['eingabeAuswahlWert'],
        'vogelschutzgebiet-bewertung': fragenblock.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.oekologie-group.vogelschutzgebiet-bewertung')?.['eingabeAuswahlWert'],
        'wasserschutzgebiet-bewertung': fragenblock.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.oekologie-group.wasserschutzgebiet-bewertung')?.['eingabeAuswahlWert'],
        'oekologie-sonstiges-bewertung': fragenblock.findFrageByRelativePath(ba, 'zuwegung-seilzugflaeche-modal.oekologie-group.sonstiges-bewertung')?.['eingabeAuswahlWert'],
      },
      flaecheZwischenMastUndSeilzug: {
        // Allgemein
        'nutzungsart-bewertung': fragenblock.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.allgemein.nutzungsart-bewertung')?.['eingabeAuswahlWert'],
        'fremdobjekte-auf-flaeche-bewertung': fragenblock.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.allgemein.fremdobjekte-auf-flaeche-bewertung')?.['eingabeAuswahlWert'],
        'sonstiges-bewertung': fragenblock.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.allgemein.sonstiges-bewertung')?.['eingabeAuswahlWert'],
        // Gelände
        'ausholzung-bewertung': fragenblock.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.ausholzung-group.ausholzung-bewertung')?.['eingabeAuswahlWert'],
        'rodung-bewertung': fragenblock.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.rodung-group.rodung-bewertung')?.['eingabeAuswahlWert'],
        'feuchtgebiete-bewertung': fragenblock.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.feuchtgebiete-group.feuchtgebiete-bewertung')?.['eingabeAuswahlWert'],
        'naturdenkmaeler-bewertung': fragenblock.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.naturdenkmaeler-group.naturdenkmaeler-bewertung')?.['eingabeAuswahlWert'],
        'kampfmittel-bewertung': fragenblock.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.kampfmittel-group.kampfmittel-bewertung')?.['eingabeAuswahlWert'],
        'gelaende-sonstiges-bewertung': fragenblock.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.sonstiges-group.sonstiges-bewertung')?.['eingabeAuswahlWert'],
        // Ökologie
        'ampelkarte-bewertung': fragenblock.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.ampelkarte-bewertung')?.['eingabeAuswahlWert'],
        'ffh-gebiet-bewertung': fragenblock.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.ffh-gebiet-bewertung')?.['eingabeAuswahlWert'],
        'biotop-bewertung': fragenblock.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.biotop-bewertung')?.['eingabeAuswahlWert'],
        'vogelschutzgebiet-bewertung': fragenblock.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.vogelschutzgebiet-bewertung')?.['eingabeAuswahlWert'],
        'wasserschutzgebiet-bewertung': fragenblock.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.wasserschutzgebiet-bewertung')?.['eingabeAuswahlWert'],
        'oekologie-sonstiges-bewertung': fragenblock.findFrageByRelativePath(ba, 'flaeche-zwischen-mast-und-seilzugflaeche-modal.oekologie-group.sonstiges-bewertung')?.['eingabeAuswahlWert'],
      },
    };
  };


  const getFremdleitungenBewertungen = (ba: Bestandsaufnahme) => {
    return {
      'fremdleitung-bewertung': get1NModalAuswahlWert(
        ba,
        'rippistein-seilzugflaechen.fremdleitungen-tab.fremdleitungen-group',
        'fremdleitung-bewertung'
      ),
      'sonstiges-bewertung': getAuswahlWert(
        ba,
        'rippistein-seilzugflaechen.fremdleitungen-tab.fremdleitungen-group.sonstiges-bewertung'
      ),
    };
  };

  const summarize = (bewertungen: Array<string | undefined>) => {
    // Priority what comes first
    if (bewertungen.includes('x')) {
      return {
        color: '4',
        amount: bewertungen.filter((f) => f === 'x').length.toString(),
      };
    } else if (bewertungen.includes('minus')) {
      return {
        color: '3',
        amount: bewertungen.filter((f) => f === 'minus').length.toString(),
      };
    } else if (bewertungen.includes('o')) {
      return {
        color: '2',
        amount: bewertungen.filter((f) => f === 'o').length.toString(),
      };
    } else if (bewertungen.includes('plus')) {
      return {
        color: '1',
        amount: bewertungen.filter((f) => f === 'plus').length.toString(),
      };
    }
    //default value, to see something does not work
    return { color: '0', amount: '0' };
  };

  const summarizeFremdleitungenBewertung = (ba: Bestandsaufnahme) => {
    const bewertungen: Array<string | undefined> = Object.values(
      getFremdleitungenBewertungen(ba)
    ).flat(1);
    return summarize(bewertungen);
  };

  // const summarizeZuwegungBewertung = (ba: Bestandsaufnahme) => {
  //   const bewertungen: Array<string | undefined> = Object.values(
  //     getZuwegungBewertungen(ba)
  //   ).flat(1);

  //   return summarize(bewertungen);
  // };

  const summarizeSeilzugflaecheBewertungen = (ba: Bestandsaufnahme, fragenblock: Fragenblock) => {

    let bewertungen: Array<string | undefined> = Object.values(
      getSeilzugflaecheBewertungen(ba, fragenblock)
    )
      .flat(1)
      .map((f) => Object.values(f))
      .flat(1);

    const bewertungenGraben: any = Object.values(
      getGrabenquerungenBewertungen(ba, fragenblock) as any
    )
      .flat(1)
      .map((f) => Object.values(f as Record<string, unknown>))
      .flat(1);

    bewertungen = bewertungen.concat(bewertungenGraben);

    return summarize(bewertungen);
  };

  // todo summarize overall
  const summarizeOverallSeilzugflaechen = (ba: Bestandsaufnahme) => {
    const seilzugflaechen = getSeilzugflaechen(ba);
    let seilzugflaechenBewertung: any[] = [];
    seilzugflaechen?.forEach((seilzugflaeche, idx) => {
      const bewertung = Object.values(getSeilzugflaecheBewertungen(ba, seilzugflaeche)
      )
        .flat(1)
        .map((f) => Object.values(f))
        .flat(1);
      seilzugflaechenBewertung = seilzugflaechenBewertung.concat(bewertung);

      const bewertungenGraben: any = Object.values(
        getGrabenquerungenBewertungen(ba, seilzugflaeche) as any
      )
        .flat(1)
        .map((f) => Object.values(f as Record<string, unknown>))
        .flat(1);

      seilzugflaechenBewertung = seilzugflaechenBewertung.concat(bewertungenGraben);
    });

    const bewertungenFremdleitungen: Array<string | undefined> = Object.values(
      getFremdleitungenBewertungen(ba)
    ).flat(1);

    const a = seilzugflaechenBewertung.concat(
      bewertungenFremdleitungen
    );

    return summarize(a);
  };

  const extractModalsFrom1NModals = (
    ba: Bestandsaufnahme,
    modalIdentifier: string
  ) => {
    const modal = useIdentifierFragenblockFinder(ba, modalIdentifier);

    const modale = modal?.fragenblocks?.filter(
      (block) => !block.config.isTemplate
    );

    return modale
      ?.map((modal) => {
        return modal.fragenblocks.map((fragenBlock) => {
          return useIdentifierFrageFinder(ba, fragenBlock.path);
        });
      })
      .flat(1);
  };
  const extractModalsFrom1NModalsMehrfach = (
    ba: Bestandsaufnahme,
    fragenblock: Fragenblock,
    modalIdentifier: string,
  ) => {
    // const modal = useIdentifierFragenblockFinder(ba, modalIdentifier);
    const modal = fragenblock?.findFragenblockByRelativePath(ba, modalIdentifier);
    const modale = modal?.fragenblocks?.filter(
      (block) => !block.config.isTemplate
    );

    return modale
      ?.map((modal) => {
        return modal.fragenblocks.map((fragenBlock) => {
          return useIdentifierFrageFinder(ba, fragenBlock.path);
        });
      })
      .flat(1);
  };
  const extract1NModals = (
    ba: Bestandsaufnahme,
    modalIdentifier: string
  ) => {
    const modal = useIdentifierFragenblockFinder(ba, modalIdentifier);

    const modale = modal?.fragenblocks?.filter(
      (block) => !block.config.isTemplate
    );

    return modale;
  };

  const getAnmerkungenGutachter = (ba: Bestandsaufnahme) => {
    const anmerkungenModals = extractModalsFrom1NModals(
      ba,
      'rippistein-seilzugflaechen.allgemein-tab.anmerkung-des-gutachters-group'
    );
    const res = anmerkungenModals?.map((modal: any) => {
      const elems = modal?.frages?.map((f: any) => {
        if (f.eingabeTyp === 'Text' && f.identifier === 'custom-title') {
          return { title: f.eingabeText };
        } else if (f.identifier === 'custom-freitext') {
          return { anmerkung: f.eingabeText };
        } else if (f.identifier === 'custom-bilder') {
          return { bilder: f.eingabeMedien };
        } else if (f.identifier === 'custom-nummer') {
          return { lfndnr: f.eingabeText };
        }
      });

      return Object.assign(elems[0], elems[1], elems[2], elems[3], elems[4]);
    });

    return res;
  };
  const getAnmerkungenPages = (ba: Bestandsaufnahme) => {
    const res = getAnmerkungenGutachter(ba);

    const maxSpace = 100;
    const spaceForSingleModal = 30;
    let currentOccupiedSpace = 0;

    const pages: any[] = [];
    let currentPage: any = {
      'anmerkungen': []
    };

    res?.forEach(anmerkung => {
      if (currentOccupiedSpace + spaceForSingleModal < maxSpace) {
        currentPage['anmerkungen'].push(anmerkung);
        currentOccupiedSpace += spaceForSingleModal;
      } else {
        pages.push(currentPage);
        currentOccupiedSpace = 0;
        currentPage = {
          'anmerkungen': []
        };
        currentPage['anmerkungen'].push(anmerkung);
        currentOccupiedSpace += spaceForSingleModal;
      }
    });
    pages.push(currentPage);
    return pages;
  }
  const getGrabenquerungen = (ba: Bestandsaufnahme, fragenblock: Fragenblock) => {
    const grabenModals = extractModalsFrom1NModalsMehrfach(
      ba,
      fragenblock,
      'zuwegung-seilzugflaeche-modal.grabenquerung-group'
    );

    const res = grabenModals?.map((modal: any) => {
      const elems = modal?.frages?.map((f: any) => {

        if (f.identifier === 'grabenquerung-erforderlich') {
          return { 'grabenquerung-erforderlich': f.eingabeText };
        } else if (f.identifier === 'graben-anzahl') {
          return { 'graben-anzahl': f.eingabeText };
        } else if (f.identifier === 'graben-beschreibung') {
          return { 'graben-beschreibung': f.eingabeText };
        } else if (f.identifier === 'graben-bewertung') {
          return { 'graben-bewertung': f.eingabeAuswahlWert };
        } else if (f.identifier === 'graben-bilder') {
          return { 'graben-bilder': f.eingabeMedien };
        }

      });

      return Object.assign(elems[0], elems[1], elems[2], elems[3], elems[4]);
    });
    return res;
  };
  const getGrabenquerungenPages = (ba: Bestandsaufnahme) => {
    // const res = getGrabenquerungen(ba);
    // console.log(res?.length);

    // const maxSpace = 100;
    // const spaceForSingleModal = 30;
    // let currentOccupiedSpace = 0;

    // const pages: any[] = [];
    // let currentPage: any = {
    //   'grabenquerungen': []
    // };

    // res?.forEach(grabenquerung => {
    //   console.log(grabenquerung);
    //   if(currentOccupiedSpace + spaceForSingleModal < maxSpace){
    //     currentPage['grabenquerungen'].push(grabenquerung);
    //     currentOccupiedSpace += spaceForSingleModal;
    //   }else{
    //     pages.push(currentPage);
    //     currentOccupiedSpace = 0;
    //     currentPage = {
    //       'grabenquerungen': []
    //     };
    //     currentPage['grabenquerungen'].push(grabenquerung);
    //     currentOccupiedSpace += spaceForSingleModal;
    //   }
    // });
    // pages.push(currentPage);
    // console.log(pages);
    // return pages;
  }
  const getBauwerke = (ba: Bestandsaufnahme) => {
    const grabenModals = extractModalsFrom1NModals(
      ba,
      'rippistein-seilzugflaechen.zuwegung-mast-tab.bauwerke'
    );
    const res = grabenModals?.map((modal: any) => {
      const elems = modal?.frages?.map((f: any) => {
        if (f?.identifier === 'freitext') {
          return { freitext: f?.eingabeText };
        } else if (f?.identifier === 'bauwerk-beschreibung') {
          return { beschreibung: f?.eingabeText };
        } else if (f?.identifier === 'tragfaehig') {
          return { tragfaehig: f?.eingabeAuswahlWert };
        } else if (f?.identifier === 'bauwerk-bewertung') {
          return { 'bauwerk-bewertung': f?.eingabeAuswahlWert };
        } else if (f?.identifier === 'bild') {
          return { bild: f?.eingabeMedien };
        }
      });
      return Object.assign(elems[0], elems[1], elems[2], elems[3], elems[4]);
    });
    return res;
  };
  const getBauwerkePages = (ba: Bestandsaufnahme) => {
    const res = getBauwerke(ba);

    const maxSpace = 100;
    const spaceForSingleModal = 9;
    let currentOccupiedSpace = 0;

    const pages: any[] = [];
    let currentPage: any = {
      'bauwerke': []
    };

    res?.forEach(bauwerk => {
      if (currentOccupiedSpace + spaceForSingleModal < maxSpace) {
        currentPage['bauwerke'].push(bauwerk);
        currentOccupiedSpace += spaceForSingleModal;
      } else {
        pages.push(currentPage);
        currentOccupiedSpace = 0;
        currentPage = {
          'bauwerke': []
        };
        currentPage['bauwerke'].push(bauwerk);
        currentOccupiedSpace += spaceForSingleModal;
      }
    });
    pages.push(currentPage);
    return pages;
  }

  const getFremdleitungen = (ba: Bestandsaufnahme) => {
    const grabenModals = extractModalsFrom1NModals(
      ba,
      'rippistein-seilzugflaechen.fremdleitungen-tab.fremdleitungen-group'
    );
    const res = grabenModals?.map((modal: any) => {
      const elems = modal?.frages?.map((f: any) => {
        if (f.identifier === 'typ') {
          return { typ: f.eingabeAuswahlWert };
        } else if (f.identifier === 'art-oberirdisch') {
          return { 'art-oberirdisch': f.eingabeAuswahlWert };
        } else if (f.identifier === 'art-unterirdisch') {
          return { 'art-unterirdisch': f.eingabeAuswahlWert };
        } else if (f.identifier === 'betroffeneflaechen') {
          return { 'betroffeneflaechen': f.eingabeText };
        } else if (f.identifier === 'betreiber-bekannt') {
          return { 'betreiber-bekannt': f.eingabeBoolean };
        } else if (f.identifier === 'auflagen') {
          return { 'auflagen': f.eingabeAuswahlWert };
        } else if (f.identifier === 'betreiber') {
          return { 'betreiber': f.eingabeText };
        } else if (f.identifier === 'fremdleitung-bewertung') {
          return { 'fremdleitung-bewertung': f.eingabeAuswahlWert };
        }
      });

      return Object.assign(
        elems[0],
        elems[1],
        elems[2],
        elems[3],
        elems[4],
        elems[5],
        elems[6],
        elems[7],
        elems[8],
        elems[9]
      );
    });
    return res;
  };
  const getFremdleitungenPages = (ba: Bestandsaufnahme) => {
    const res = getFremdleitungen(ba);

    const maxSpace = 100;
    const spaceForSingleModal = 12;
    let currentOccupiedSpace = 0;

    const pages: any[] = [];
    let currentPage: any = {
      'fremdleitungen': []
    };

    res?.forEach(fremdleitung => {

      if (currentOccupiedSpace + spaceForSingleModal < maxSpace) {
        currentPage['fremdleitungen'].push(fremdleitung);
        currentOccupiedSpace += spaceForSingleModal;
      } else {
        pages.push(currentPage);
        currentOccupiedSpace = 0;
        currentPage = {
          'fremdleitungen': []
        };
        currentPage['fremdleitungen'].push(fremdleitung);
        currentOccupiedSpace += spaceForSingleModal;
      }
    });
    pages.push(currentPage);

    return pages;
  }
  const getSeilzugflaechen = (ba: Bestandsaufnahme) => {
    const seilzugflaechenTab = ba?.findFragenblockByRelativePath('seilzugflaeche-tab');
    const seilzugflaechen = seilzugflaechenTab?.getMultipleFragenblockInstances('seilzugflaeche');
    return seilzugflaechen;
  }
  // ################################
  // not in use?! start
  const getGrabenquerungenAnkommend = (ba: Bestandsaufnahme) => {
    const grabenModals = extractModalsFrom1NModals(
      ba,
      'rippistein-seilzugflaechen.seilzugflaeche-tab.seilzugflaeche-ankommend.zuwegung-seilzugflaeche-modal.grabenquerung-group'
    );

    const res = grabenModals?.map((modal: any) => {
      const elems = modal?.frages?.map((f: any) => {
        if (f.identifier === 'graben-anzahl') {
          return { 'graben-anzahl': f.eingabeAuswahlWert };
        } else if (f.identifier === 'graben-beschreibung') {
          return { 'graben-beschreibung': f.eingabeText };
        } else if (f.identifier === 'grabenquerung-erforderlich') {
          return { 'grabenquerung-erforderlich': f.eingabeBoolean };
        } else if (f.identifier === 'graben-bewertung') {
          return { 'graben-bewertung': f.eingabeAuswahlWert };
        } else if (f.identifier === 'graben-bilder') {
          return { 'graben-foto': f.eingabeMedien };
        }

      });
      return Object.assign(elems[1], elems[2], elems[3], elems[4]);
    });
    return res;
  }
  const getGrabenquerungenAnkommendPages = (ba: Bestandsaufnahme) => {
    const res = getGrabenquerungenAnkommend(ba);

    const maxSpace = 100;
    const spaceForSingleModal = 30;
    let currentOccupiedSpace = 0;

    const pages: any[] = [];
    let currentPage: any = {
      'grabenquerungen': []
    };

    res?.forEach(grabenquerung => {
      if (currentOccupiedSpace + spaceForSingleModal < maxSpace) {
        currentPage['grabenquerungen'].push(grabenquerung);
        currentOccupiedSpace += spaceForSingleModal;
      } else {
        pages.push(currentPage);
        currentOccupiedSpace = 0;
        currentPage = {
          'grabenquerungen': []
        };
        currentPage['grabenquerungen'].push(grabenquerung);
        currentOccupiedSpace += spaceForSingleModal;
      }
    });
    pages.push(currentPage);
    return pages;
  }
  const getGrabenquerungenAbgehend = (ba: Bestandsaufnahme) => {
    const grabenModals = extractModalsFrom1NModals(
      ba,
      'rippistein-seilzugflaechen.seilzugflaeche-tab.seilzugflaeche-abgehend.zuwegung-seilzugflaeche-modal.grabenquerung-group'
    );
    const res = grabenModals?.map((modal: any) => {
      const elems = modal?.frages?.map((f: any) => {
        if (f.identifier === 'graben-anzahl') {
          return { 'graben-anzahl': f.eingabeText };
        } else if (f.identifier === 'graben-beschreibung') {
          return { 'graben-beschreibung': f.eingabeText };
        } else if (f.identifier === 'graben-bewertung') {
          return { 'graben-bewertung': f.eingabeAuswahlWert };
        } else if (f.identifier === 'graben-bilder') {
          return { 'graben-foto': f.eingabeMedien };
        } else if (f.identifier === 'graben-vorhanden') {
          return { 'graben-vorhanden': f.eingabeText };
        }

      });
      return Object.assign(elems[1], elems[2], elems[3], elems[4]);
    });
    return res;
  }
  const getGrabenquerungenAbgehendPages = (ba: Bestandsaufnahme) => {
    const res = getGrabenquerungenAbgehend(ba);

    const maxSpace = 100;
    const spaceForSingleModal = 30;
    let currentOccupiedSpace = 0;

    const pages: any[] = [];
    let currentPage: any = {
      'grabenquerungen': []
    };

    res?.forEach(grabenquerung => {
      if (currentOccupiedSpace + spaceForSingleModal < maxSpace) {
        currentPage['grabenquerungen'].push(grabenquerung);
        currentOccupiedSpace += spaceForSingleModal;
      } else {
        pages.push(currentPage);
        currentOccupiedSpace = 0;
        currentPage = {
          'grabenquerungen': []
        };
        currentPage['grabenquerungen'].push(grabenquerung);
        currentOccupiedSpace += spaceForSingleModal;
      }
    });
    pages.push(currentPage);
    return pages;
  }
  const getSeilzugflaechenJSON = (ba: Bestandsaufnahme) => {

    const seilzugflaechen = JSON.parse(JSON.stringify(useIdentifierFragenblockFinder(ba, 'rippistein-seilzugflaechen.seilzugflaeche-tab')?.fragenblocks));
    seilzugflaechen?.shift();
    return seilzugflaechen;
  }
  const getSeilzugflaechenWrappers = (ba: Bestandsaufnahme) => {
    const seilzugflaechen: any[] = getSeilzugflaechenJSON(ba)!;
    const retVal: any = [];

    //wip fko start
    const seilzugflaechenTab = ba?.findFragenblockByRelativePath('seilzugflaeche-tab');
    const seilzugflaechen_raw = seilzugflaechenTab?.getMultipleFragenblockInstances('seilzugflaeche');

    seilzugflaechen_raw?.forEach(seilzugflaeche_raw => {
      const entfernungMastBewertung = ba && seilzugflaeche_raw.findFrageByRelativePath(ba, 'seilzugflaeche-modal.allgemein.entfernung-mast-bewertung')?.getCurrentInput();
    });
    // wip end fko
    seilzugflaechen?.forEach(seilzugflaeche => {

      //Object consisting of SZF Pages[] and Grabenquerungen (these consist of Grabenquerungen Pages[])
      const wrappedSZF = {
        'seilzugflaechenPages': <any>undefined,
        'grabenquerungenPages': <any>undefined,
        'ergebnisSeilzugflaeche': <any>undefined,
        'ergebnisZuwegung': <any>undefined
      };

      wrappedSZF['seilzugflaechenPages'] = [];

      // Allgemeine info page
      const firstPage = {
        'typ': <any>undefined,
        'mastnummer': <any>undefined,
        'betroffeneflurstuecke': <any>undefined,
        'mastbild': <any>undefined,
        'masthoehe': <any>undefined,
        'flaeche-vorhanden': <any>undefined,
        'flaeche-notwendig': <any>undefined
      };
      // Seilzugflaeche Page 1
      const secondPage = {
        'entfernung-mast-soll': <any>undefined,
        'entfernung-mast-ist': <any>undefined,
        'entfernung-mast-bewertung': <any>undefined,
        'betroffeneflurstuecke': <any>undefined,
        'allgemein.nutzungsart-der-flaeche': <any>undefined,
        'temptodothing': <any>undefined,
        'nutzungsart-bewertung': <any>undefined,
        'fremdobjekte-vorhanden': <any>undefined,
        'fremdobjekte-auf-flaeche-bewertung': <any>undefined,
        'sonstiges-vorhanden': <any>undefined,
        'sonstiges-bewertung': <any>undefined
      };

      // --------------------------------------------------------- Allgemein --------------------------------------------------------------------------------------
      const allgemein = JSON.parse(JSON.stringify(useIdentifierFragenblockFinder(ba, seilzugflaeche.path + '.allgemein-modal')?.fragenblocks));
      console.log(allgemein);
      allgemein[0]?.frages?.forEach((frage: any) => {
        switch (frage.identifier) {
          case 'typ':
            firstPage['typ'] = frage.eingabeAuswahlWert; //TODO change to label
            break;
          case 'mastnummer':
            firstPage['mastnummer'] = frage.eingabeText;
            break;
          case 'betroffeneflurstuecke':
            firstPage['betroffeneflurstuecke'] = frage.eingabeText;
            break;
          case 'mastbild':
            firstPage['mastbild'] = frage.eingabeAuswahlWert; //TODO change to label;
            break;
          case 'masthoehe':
            firstPage['masthoehe'] = frage.eingabeText;
            break;
          case 'flaeche-vorhanden':
            firstPage['flaeche-vorhanden'] = frage.eingabeBoolean;
            break;
          case 'flaeche-notwendig':
            firstPage['flaeche-notwendig'] = frage.eingabeBoolean;
            break;
          default:
            break;
        }
      });

      // --------------------------------------------------------- Seilzugfläche ----------------------------------------------------------------------------------
      const seilzugflaecheFb = JSON.parse(JSON.stringify(useIdentifierFragenblockFinder(ba, seilzugflaeche.path + '.seilzugflaeche-modal')?.fragenblocks));


      const thirdPage = {
        'gefaelle-vorhanden': <any>undefined,
        'gefaelle-bewertung': <any>undefined
      };

      const szfAllgemein = JSON.parse(JSON.stringify(useIdentifierFragenblockFinder(ba, seilzugflaeche.path + '.seilzugflaeche-modal.allgemein')));

      szfAllgemein?.frages?.forEach((frage: any) => {
        switch (frage.identifier) {
          case 'entfernung-mast-soll':
            secondPage['entfernung-mast-soll'] = frage.eingabeText;
            break;
          case 'entfernung-mast-ist':
            secondPage['entfernung-mast-ist'] = frage.eingabeText;
            break;
          case 'entfernung-mast-bewertung':
            secondPage['entfernung-mast-bewertung'] = frage.eingabeAuswahlWert;
            break;
          case 'betroffeneflurstuecke':
            secondPage['betroffeneflurstuecke'] = frage.eingabeText;
            break;
          case 'allgemein.nutzungsart-der-flaeche':
            secondPage['allgemein.nutzungsart-der-flaeche'] = frage.eingabeAuswahlWerts;
            break;
          case 'nutzungsart-bewertung':
            secondPage['nutzungsart-bewertung'] = frage.eingabeAuswahlWert;
            break;
          case 'fremdobjekte-vorhanden':
            secondPage['fremdobjekte-vorhanden'] = frage.eingabeBoolean;
            break;
          case 'fremdobjekte-auf-flaeche-bewertung':
            secondPage['fremdobjekte-auf-flaeche-bewertung'] = frage.eingabeAuswahlWert;
            break;
          case 'sonstiges-vorhanden':
            secondPage['sonstiges-vorhanden'] = frage.eingabeBoolean;
            break;
          case 'sonstiges-bewertung':
            secondPage['sonstiges-bewertung'] = frage.eingabeAuswahlWert;
            break;

          default:
            break;
        }
      });

      const szfGefaelleGroup = JSON.parse(JSON.stringify(useIdentifierFragenblockFinder(ba, seilzugflaeche.path + '.seilzugflaeche-modal.gefaelle-group')));
      szfGefaelleGroup?.frages?.forEach((frage: any) => {
        switch (frage.identifier) {
          case 'gefaelle-vorhanden':
            thirdPage['gefaelle-vorhanden'] = frage.eingabeBoolean;
            break;
          case 'gefaelle-bewertung':
            thirdPage['gefaelle-bewertung'] = frage.eingabeAuswahlWert;
            break;

          default:
            break;
        }
      });


      // --------------------------------------------------------- Zuwegung Seilzugfläche -------------------------------------------------------------------------
      const zuwegungSeilzugflaeche = JSON.parse(JSON.stringify(useIdentifierFragenblockFinder(ba, seilzugflaeche.path + '.zuwegung-seilzugflaeche-modal')?.fragenblocks));

      const fourthPage = {
        'betroffeneflurstuecke': <any>undefined,
        'fremdobjekte-vorhanden': <any>undefined,
        'fremdobjekte-auf-flaeche': <any>undefined,
        'weitere-fremdobjekte-auf-flaeche': <any>undefined,
        'fremdobjekte-auf-flaeche-bewertung': <any>undefined,
        'sonstiges-vorhanden': <any>undefined,
        'sonstiges-beschreibung': <any>undefined,
        'sonstiges-bewertung': <any>undefined,

        'beschaffenheit': <any>undefined,
        'beschaffenheit-bewertung': <any>undefined,

        'gefaelle-vorhanden': <any>undefined,
        'gefaelle-bewertung': <any>undefined,
        'gefaelle-bilder': <any>undefined,

        /*

        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,

        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,

        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,

        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,

        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,
        'todotodo': <any>undefined,

        'todotodo': <any>undefined
        */

      };

      const zuwegungAllgemein = JSON.parse(JSON.stringify(useIdentifierFragenblockFinder(ba, seilzugflaeche.path + '.zuwegung-seilzugflaeche-modal.allgemein')));

      zuwegungAllgemein?.frages?.forEach((frage: any) => {
        switch (frage.identifier) {
          case 'betroffeneflurstuecke':
            fourthPage['betroffeneflurstuecke'] = frage.eingabeText;
            break;
          case 'fremdobjekte-vorhanden':
            fourthPage['fremdobjekte-vorhanden'] = frage.eingabeBoolean;
            break;
          case 'fremdobjekte-auf-flaeche':
            fourthPage['fremdobjekte-auf-flaeche'] = frage.eingabeText;
            break;
          case 'weitere-fremdobjekte-auf-flaeche':
            fourthPage['weitere-fremdobjekte-auf-flaeche'] = frage.eingabeText;
            break;
          case 'fremdobjekte-auf-flaeche-bewertung':
            fourthPage['fremdobjekte-auf-flaeche-bewertung'] = frage.eingabeAuswahlWert;
            break;
          case 'sonstiges-vorhanden':
            fourthPage['sonstiges-vorhanden'] = frage.eingabeBoolean;
            break;
          case 'sonstiges-beschreibung':
            fourthPage['sonstiges-beschreibung'] = frage.eingabeText;
            break;
          case 'sonstiges-bewertung':
            fourthPage['sonstiges-bewertung'] = frage.eingabeAuswahlWert;
            break;

          default:
            break;
        }
      });

      const zuewgungBeschaffenheit = JSON.parse(JSON.stringify(useIdentifierFragenblockFinder(ba, seilzugflaeche.path + '.zuwegung-seilzugflaeche-modal.beschaffenheit-group')));

      zuewgungBeschaffenheit?.frages?.forEach((frage: any) => {
        switch (frage.identifier) {
          case 'beschaffenheit':
            fourthPage['beschaffenheit'] = frage.eingabeText;
            break;
          case 'beschaffenheit-bewertung':
            fourthPage['beschaffenheit-bewertung'] = frage.eingabeBoolean;
            break;

          default:
            break;
        }
      });

      const zuewgungGefaelle = JSON.parse(JSON.stringify(useIdentifierFragenblockFinder(ba, seilzugflaeche.path + '.zuwegung-seilzugflaeche-modal.gefaelle-group')));

      zuewgungGefaelle?.frages?.forEach((frage: any) => {
        switch (frage.identifier) {
          case 'gefaelle-vorhanden':
            fourthPage['gefaelle-vorhanden'] = frage.eingabeBoolean;
            break;
          case 'gefaelle-bewertung':
            fourthPage['gefaelle-bewertung'] = frage.eingabeAuswahlWert;
            break;
          case 'gefaelle-bilder':
            fourthPage['gefaelle-bilder'] = frage.eingabeMedien;
            break;

          default:
            break;
        }
      });

      // --------------------------------------------------------- Fläche zwischen Mast und Seilzugfläche ---------------------------------------------------------
      const flaecheZwischenMastUndSeilzugflaeche = JSON.parse(JSON.stringify(useIdentifierFragenblockFinder(ba, seilzugflaeche.path + '.flaeche-zwischen-mast-und-seilzugflaeche-modal')?.fragenblocks));
      // --------------------------------------------------------- Ergebnis für Seilzugfläche ---------------------------------------------------------------------
      const ergebnis = JSON.parse(JSON.stringify(useIdentifierFragenblockFinder(ba, seilzugflaeche.path + '.ergebnis-modal')?.fragenblocks));


      wrappedSZF['seilzugflaechenPages'].push(firstPage);
      wrappedSZF['seilzugflaechenPages'].push(secondPage);
      wrappedSZF['seilzugflaechenPages'].push(thirdPage);
      wrappedSZF['seilzugflaechenPages'].push(fourthPage);


      // --------------------------------------------------------- Grabenquerungen --------------------------------------------------------------------------------

      let zuwegungPath = zuwegungSeilzugflaeche[0].path;
      zuwegungPath = zuwegungPath.substring(0, zuwegungPath.lastIndexOf('.'));
      const grabenquerungenPath = zuwegungPath + '.grabenquerung-group';

      const grabenQuerungenFbs = JSON.parse(JSON.stringify(useIdentifierFragenblockFinder(ba, grabenquerungenPath))).fragenblocks;
      grabenQuerungenFbs.shift();

      const grabenquerungenPages: any[] = [];

      let currentPage = {
        'grabenquerungen': <any>[]
      };
      const maxSpace = 100;
      const modalSize = 45;
      let usedSpace = 0;

      grabenQuerungenFbs.forEach((grabenQuerungFb: Fragenblock) => {
        const grabenQuerung = {
          'erforderlich': <any>undefined,
          'anzahl': <any>undefined,
          'beschreibung': <any>undefined,
          'bilder': <any>undefined,
          'bewertung': <any>undefined
        };

        const fragenContainer = JSON.parse(JSON.stringify(useIdentifierFragenblockFinder(ba, grabenQuerungFb.path + '.grabenquerung')));
        fragenContainer?.frages?.forEach((frage: any) => {
          switch (frage.identifier) {
            case 'grabenquerung-erforderlich':
              grabenQuerung['erforderlich'] = frage.eingabeAuswahlWert; //TODO change to label
              break;
            case 'graben-anzahl':
              grabenQuerung['anzahl'] = frage.eingabeText;
              break;
            case 'graben-beschreibung':
              grabenQuerung['beschreibung'] = frage.eingabeText;
              break;
            case 'graben-bilder':
              grabenQuerung['bilder'] = frage.eingabeMedien;
              break;
            case 'graben-bewertung':
              grabenQuerung['bewertung'] = frage.eingabeAuswahlWert; //TODO change to label
              break;
            default:
              break;
          }
        });

        if (usedSpace + modalSize < maxSpace) {

          currentPage['grabenquerungen'].push(grabenQuerung);
          usedSpace += modalSize;

        } else {
          grabenquerungenPages.push(currentPage);
          currentPage = {
            'grabenquerungen': <any>[]
          };

          currentPage['grabenquerungen'].push(grabenQuerung);
          usedSpace = modalSize;

        }

      });
      grabenquerungenPages.push(currentPage);


      wrappedSZF['grabenquerungenPages'] = grabenquerungenPages;

      wrappedSZF['ergebnisSeilzugflaeche'] = "Test";
      wrappedSZF['ergebnisZuwegung'] = "Test";

      retVal.push(wrappedSZF);
    });


    return retVal;
  }
  // not in use?! end
  // ################################

  return {
    getSeilzugflaecheBewertungen,
    getFremdleitungenBewertungen,
    summarizeFremdleitungenBewertung,
    summarizeSeilzugflaecheBewertungen,
    summarizeOverallSeilzugflaechen,
    getAnmerkungenGutachter,
    getAnmerkungenPages,
    getBauwerke,
    getBauwerkePages,
    getFremdleitungen,
    getFremdleitungenPages,
    getGrabenquerungen,
    getGrabenquerungenPages,
    getSeilzugflaechenWrappers,
    getSeilzugflaechen
  };
}
