<template>
  <ion-page>
    <toolbar :title="t('toolbar.impressum')">
      <template #trailing>
        <UserDialogButton />
      </template>

      <template #leading>
        <ion-buttons slot="start">
          <ion-button
            fill="clear"
            color="light"
            class="px-0 -ml-4"
            size="small"
            @click="() => goBackToLandingPage()"
          >
            <ion-icon
              :icon="arrowBack"
              size="large"
            />
          </ion-button>
        </ion-buttons>
      </template>
    </toolbar>

    <ion-card>
      <ion-card-header class="py-0">
        <ion-card-title>
          <ion-text>
            <h1 class="pl-6 mb-0">
              {{ t("libraries.titel") }}
            </h1>
          </ion-text>
        </ion-card-title>
      </ion-card-header>
      <ion-card-content class="container">
        <ion-grid>
          <ion-row>
            <ion-col
              v-for="(library, index) in licenseReport"
              :key="library"
              size="6"
              size-xs="12"
              size-sm="12"
              size-md="6"
              size-lg="6"
              size-xl="6"
            >
              <ion-card class="adjust-size">
                <ion-card-title class="pt-5 pl-5 ion-text-left">
                  {{ library.name }}
                </ion-card-title>
                <ion-card-content>
                  <ul>
                    <li>
                      <strong>Version: </strong>
                      {{ library.installedVersion }}
                    </li>
                    <li><strong>Lizenz: </strong> {{ library.licenseType }}</li>
                    <li>
                      <strong>Link: </strong>
                      <a :href="licenseReport[index].link">{{
                        library.link
                      }}</a>
                    </li>
                  </ul>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </ion-page>
</template>

<script lang="ts">
import AButton from "@/components/Base/AButton";
import licenseReport from "@/components/Impressum/licenseReport.json";
import { arrowBack } from "ionicons/icons";
import { useI18n } from "vue-i18n";
import Toolbar from "@/components/Navigation/Toolbar";
import useUser from "@/composables/useUser";
import { useRouter } from "vue-router";

// import Bibliothek from "@/models/bibliothek.model";
import {
  IonContent,
  IonPage,
  onIonViewWillEnter,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonText,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/vue";
import { defineComponent, PropType } from "vue";
import UserDialogButton from "@/components/User/UserDialogButton.vue";
import { useStore } from "@/composables/useTypedStore";

export default defineComponent({
  name: "ImpressumPage",
  components: {
    AButton,
    Toolbar,
    IonPage,
    IonContent,
    UserDialogButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonText,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButtons,
    IonButton,
    IonIcon,
  },
  // props: {
  //   bibliothek: {
  //     type: Object as PropType<Bibliothek>,
  //       required: true
  //   }
  // },
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const { user } = useUser();
    const store = useStore();

    onIonViewWillEnter(() => {
      store.commit("app/showBottomNavigation");
    });

    const router = useRouter();
    const goBackToLandingPage = () => {
      router.push("/properties");
    };

    return {
      user,
      t,
      licenseReport,
      arrowBack,
      goBackToLandingPage,
    };
  },
});
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}

h1 {
  color: var(--black100);
  @media (max-width: 460px) {
    font-size: 22px;
    font-weight: bold;
    padding-left: 0;
    text-align: left;
  }
}

a {
  text-decoration: none;
}
.container {
  padding: 0;
}
ion-card {
  box-shadow: $boxShadow;
  overflow-y: scroll;
  margin-top: 0;
  @media (max-width: 460px) {
    padding: 0;
  }
}
// ion-card-content {
//   padding: 0;
// }
ion-card-title {
  font-size: 1.2em;
  padding-top: 25px;
  padding-left: 25px;
}
ion-card.adjust-size {
  @media (max-width: 760px) {
    height: auto;
  }
  @media (min-width: 761px) {
    height: 200px;
  }
  @media (min-width: 1200px) {
    height: auto;
  }
}
ul {
    padding-left: 25px;
    margin: 0;
}

li {
  @media (max-width: 460px) {
    font-size: .95em;
  }
}

</style>