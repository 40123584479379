<template>
  <pdf-a4-page
    no-margin
    :pdf-key="lang"
    v-for="(fremdleitungPage, key) in getFremdleitungenPages(ba)"
    :key="key"
  >
    <div class="landingpage-background">
      <div class="flex flex-col justify-between" style="height: 1020px">
        <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>
        <div class="text-xl md:text-xl">Fremdleitungen</div>
        <div
          v-if="key === 0"
          class="mt-2 grid grid-cols-8 justify-items-stretch items-center gap-y-3"
        >
          <div
            class="col-span-2 text-sm font-bold"
            style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
          >
            Frage
          </div>
          <div
            class="text-sm font-bold col-span-3"
            style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
          >
            Beschreibung/Eingabewerte
          </div>
          <div
            class="col-span-2 text-sm font-bold"
            style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
          >
            Weitere Eingaben
          </div>
          <div
            class="col-span- text-sm font-bold"
            style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
          >
            Bewertung
          </div>
          <!-- Sonstiges start-->
          <is-vorhanden-char-and-label
            :ba="ba"
            frages-path="rippistein-seilzugflaechen.fremdleitungen-tab.fremdleitungen-group.sonstiges-vorhanden"
            label="Sonstiges"
            class="col-span-2"
          />
          <div class="text-sm text-gray-500 col-span-5">
            {{
              useIdentifierFrageFinder(
                ba,
                'rippistein-seilzugflaechen.fremdleitungen-tab.fremdleitungen-group.sonstiges-beschreibung'
              )['eingabeText']
            }}
          </div>
          <bewertung-chip
              frages-path="rippistein-seilzugflaechen.fremdleitungen-tab.fremdleitungen-group.sonstiges-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            />
          <!-- Sonstiges Ende-->
        </div>
        <div class="content h-full max-h-full">
            <div class="grid grid-cols-8 justify-items-stretch items-center">
              <div
                class="col-span-2 text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
              >
                Fremdleitungen
              </div>
              <div
                class="text-sm font-bold col-span-3"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Beschreibung/Eingabewerte
              </div>
              <div
                class="col-span-2 text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Weitere Eingaben
              </div>
              <div
                class="col-span- text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Bewertung
              </div>
            </div>
            <div v-if="fremdleitungPage['fremdleitungen'].length > 0">
            <div
              v-for="(fremdleitung, key) in fremdleitungPage['fremdleitungen']"
              :key="key"
              class="ml-2 mt-2 grid grid-cols-8 justify-items-stretch items-center gap-y-3"
              style="
                border-style: solid;
                border-color: white white white rgb(216, 215, 26);
                border-radius: 5px;
              "
            >
              <div class="ml-2 text-xs col-span-">Typ:</div>
              <div class="text-xs text-gray-500">
                {{ fremdleitung['typ'] }}
              </div>
              <div class="text-xs md:text-sm">Art:</div>
              <div
                v-if="fremdleitung['art-oberirdisch']"
                class="text-xs text-gray-500 col-span-2"
              >
                {{ fremdleitung['art-oberirdisch'] }}
              </div>
              <div
                v-else-if="fremdleitung['art-unterirdisch']"
                class="text-xs text-gray-500 col-span-2"
              >
                {{ fremdleitung['art-unterirdisch'] }}
              </div>
              <div
                v-else
                class="text-xs text-gray-500 col-span-2"
              >&nbsp;
              </div>
              <div
                v-if="fremdleitung['betreiber-bekannt']"
                class="text-xs col-span-2"
              >
                <span class="ml-2 text-xs text-gray-500">{{ '\u{2611}' }}</span>
                Betreiber bekannt
              </div>
              <div v-else class="ml-2 text-xs col-span-2">
                <span class="text-xs text-gray-500">{{ '\u{2610}' }}</span>
                Betreiber unbekannt
              </div>
              <div class="justify-self-end">
                <PdfChip
                  v-if="'plus' === fremdleitung['fremdleitung-bewertung']"
                  color="green"
                  mangel-amount-mode
                  value="1"
                  pdf-mode
                />
                <PdfChip
                  v-if="'o' === fremdleitung['fremdleitung-bewertung']"
                  color="blue"
                  mangel-amount-mode
                  value="1"
                  pdf-mode
                />
                <PdfChip
                  v-if="'minus' === fremdleitung['fremdleitung-bewertung']"
                  color="yellow"
                  mangel-amount-mode
                  value="1"
                  pdf-mode
                />
                <PdfChip
                  v-if="'x' === fremdleitung['fremdleitung-bewertung']"
                  color="red"
                  mangel-amount-mode
                  value="1"
                  pdf-mode
                />
              </div>
              <div class="ml-2 text-xs md:text-sm col-span-2">Betreiber:</div>
              <div class="text-xs text-gray-500 col-span-3">
                {{ fremdleitung['betreiber'] }}
              </div>
              <div class="text-xs"> betroffene Fläche</div>
              <div class="text-xs col-span-2">
                {{ fremdleitung['betroffeneflaechen'] }}
              </div>
            </div>
          </div>
          <div v-else>
            <div
              class="mt-2 grid grid-cols-8 justify-items-stretch items-center"
            >
              <div
                class="justify-center items-center col-span-8"
                style="
                  background-color: rgb(235, 239, 240);
                  border-style: solid;
                  border-color: rgb(235, 239, 240) rgb(235, 239, 240)
                    rgb(235, 239, 240) rgb(235, 239, 240);
                  border-radius: 5px;
                "
              >
                <p class="text-xs text-center">
                  Keine Fremdleitungen aufgenommen
                </p>
              </div>
            </div>
          </div>
        </div>
        <seilzugflaechen-footer
          :ba="ba"
          :immobilie="immobilie"
          :lang="lang"
        ></seilzugflaechen-footer>
      </div>
    </div>
  </pdf-a4-page>
</template>

<script lang="ts">
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useIdentifierFrageFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import { getPhotoUrl } from '@/utilities/get-media-url';
import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';

import PdfChip from '../Components/PdfChip.vue';
import { useSeilzugflaechen } from './useSeilzugflaechen';
import TngHeader from './TngHeader.vue';
import SeilzugflaechenFooter from './SeilzugflaechenFooter.vue';

import isVorhandenCharAndLabel from './isVorhandenCharAndLabel.vue';
import BewertungChip from './BewertungChip.vue';

export default defineComponent({
  name: 'FremdleitungPage',
  components: {
    PdfA4Page,
    PdfChip,
    TngHeader,
    SeilzugflaechenFooter,
    isVorhandenCharAndLabel,
    BewertungChip
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();
    const { getFremdleitungenPages } = useSeilzugflaechen();
    return {
      user: uUser.user,
      t,
      useIdentifierFrageFinder,
      moment,
      getPhotoUrl,
      getFremdleitungenPages,
    };
  },
});
</script>
<style scoped>
* {
  color: rgb(23, 68, 81);
  font-family: 'Avenir-Next-LT-Pro-Regular';
}

.landingpage-background {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 700px;
  height: 1050px;
  color: black;
}
.content {
  height: 770px;
}

.trenner {
  height: 1px;
  border-width: 0;
  color: gray;
  background-color: gray;
}
</style>