import CreatePropertyApi from "@/api/CreatePropertyApi";
import { useProperties } from "@/composables/Property/useProperties";
import Immobilie from "@/models/immobilie.model";
import { Network } from "@capacitor/network";
import QueryString from "qs";
import { computed } from "vue";
import { useStore } from "@/composables/useTypedStore";
import useImmobilieHelper from "@/composables/Property/useImmobilieHelper";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";

export async function handleOfflineProperties() {
  const store = useStore();
  const offlineProperties = computed(() => Immobilie.getters("persistedProperties"))
  const networkState = await Network.getStatus()
  const currentProject = computed(() => store.state.user.currentUserProject)
  const { loadAllProperties, uploadPropertyVorschaubild } = useProperties();

  if(networkState.connected && offlineProperties.value && offlineProperties.value.length ) {
    for(let i=0; i<offlineProperties.value.length; i++ ) {
      if (offlineProperties.value[i].hasUnsavedChanges ) {
        const body = {
          name: offlineProperties.value[i].name,
          strasse: offlineProperties.value[i].strasse,
          plz: offlineProperties.value[i].plz,
          stadt: offlineProperties.value[i].stadt,
          stadtbezirk: offlineProperties.value[i].stadtbezirk,
          externeObjektNr: offlineProperties.value[i].externeObjektNr,
          baujahr: offlineProperties.value[i].baujahr,
          geolocationLat: offlineProperties.value[i].geolocationLat ? offlineProperties.value[i].geolocationLat : null,
          geolocationLon: offlineProperties.value[i].geolocationLon ? offlineProperties.value[i].geolocationLon : null,
          projects: {
            disconnect: [],
            connect: [
              {
                id: currentProject.value.id,
                position: {
                  end: true
                }
              }
            ]
          }
        }
        const response = await CreatePropertyApi.postProperty(body)
        if(response.status === 200) {
          await handleSurveys(offlineProperties.value[i].id, response.data.data.id)
          //First upload images
          if (offlineProperties.value[i].vorschaubild) {
            await uploadPropertyVorschaubild(response.data.data, offlineProperties.value[i].vorschaubild)
          }
          //Then update indexDB
          const immobilieObject = response.data.data
          immobilieObject.hasUnsavedChanges = false
          await Immobilie.dispatch('$deleteFromLocal', offlineProperties.value[i].id);
          await Immobilie.dispatch('$updateLocally', { data: immobilieObject });
        }
      }
    }

    await loadAllProperties()
  }
}

async function handleSurveys(offlinePropertyId: any, onlinePropertyId: any) {
  const offlineSurveys = BestandsaufnahmeModel.getters("persistedBestandsaufnahmes")
  const filteredSurveys = offlineSurveys.filter((item: Bestandsaufnahme) => !isFullNonDecimalNumber(item.id))
  for(let i=0; i<filteredSurveys.length;i++) {
    if(filteredSurveys[i].immobilie === offlinePropertyId) {
      const updatedBa = filteredSurveys[i]
      updatedBa.immobilie = onlinePropertyId
      await BestandsaufnahmeModel.insertOrUpdate({ data: updatedBa });
      await BestandsaufnahmeModel.dispatch('$updateLocally', { data: updatedBa })
    }
  }
}

function isFullNonDecimalNumber(id: any) {
  const isDigitsOnly = /^\d+$/.test(id);
  const isInteger = Number.isInteger(Number(id));
  return isDigitsOnly && isInteger;
}
