import { Popup } from "maplibre-gl";

export function createPopup(geodata: any, propertyNamesToInclude: any, lnglat: any) {
    const featurePropertiesByLayer: { [key: string]: any } = {};
    if (!geodata.length) {
        return null;
    }
    geodata.forEach((feature: any) => {
        const layerId = feature.layer.id;
        if (!featurePropertiesByLayer[layerId]) {
            featurePropertiesByLayer[layerId] = [];
        }
        const properties: { [key: string]: any } = {};
        propertyNamesToInclude[layerId]["attributes"]?.forEach((propertyName: any) => {
            if (propertyName in feature.properties) {
                properties[propertyName] = feature.properties[propertyName];
            }
        });

        featurePropertiesByLayer[layerId].push(properties);
    });

    let popupHTML = "";
    for (const layerId in featurePropertiesByLayer) {
        if (layerId in featurePropertiesByLayer) {
            popupHTML += `<b>${propertyNamesToInclude[layerId]["displayName"]}</b><br>`;
            featurePropertiesByLayer[layerId].forEach((properties: any) => {
                popupHTML += `<div style="padding-top:2.5px">`;

                for (const propertyName in properties) {
                    if (propertyName in properties) {
                        popupHTML += `${propertyName}: ${properties[propertyName]}<br>`;
                    }
                }
                popupHTML += `</div>`;
            });
        }
    }

    const popup = new Popup()
        .setLngLat(lnglat)
        .setHTML(popupHTML)

    return popup;
}