import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.toolbarMode ? 'margin-top: 0; margin-bottom: 0;' : ''),
    class: _normalizeClass(["searchbar-row row", _ctx.toolbarMode ? '' : '' ])
  }, [
    _createVNode(_component_ion_searchbar, {
      slot: "primary",
      class: "ion-searchbar",
      "data-cy": "searchbar",
      value: _ctx.searchTerm,
      placeholder: _ctx.t(`${_ctx.organisationPrefix}immobilie.suchePlatzhalter`),
      onIonChange: _cache[0] || (_cache[0] = e => _ctx.$emit('update:searchTerm', e.target.value))
    }, null, 8, ["value", "placeholder"])
  ], 6))
}