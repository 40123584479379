<template>
  <ion-header class="mb-8">
    <toolbar
      :title="t('hzba.pdf.vorschauTitel')"
      :enable-desktop-nav="false"
    >
      <template #leading>
        <ion-buttons slot="start">
          <ion-button
            data-cy="photo-preview-close"
            @click="onClose()"
          >
            <ion-icon
              slot="icon-only"
              :icon="close"
            />
          </ion-button>
        </ion-buttons>
      </template>
      <template #trailing>
        <ion-buttons
          v-if="!deleteMode"
          slot="end"
        >
          <ion-spinner
            v-if="isProcessing"
            color="light"
          />
          <ion-button
            v-else
            data-cy="button-downloadResource"
            @click="downloadResource"
          >
            <ion-icon
              slot="icon-only"
              :icon="canShare ? share : download"
            />
          </ion-button>
        </ion-buttons>
        <ion-buttons
          v-else
          slot="end"
        >
          <ion-button @click="onDelete(pdf)">
            <ion-icon
              slot="icon-only"
              :icon="trashOutline"
            />
          </ion-button>
        </ion-buttons>
      </template>
    </toolbar>
  </ion-header>
  <ion-content>
    <ion-card
      v-if="isError"
      class="p-8"
    >
      <h2 style="color: var(--black100)">
        {{ 'hzba.pdf.fehlgeschlagen' }}
      </h2>
    </ion-card>

    <pdf-reader :url="getPdfUrl(pdf)"  />
  </ion-content>
</template>

<script lang="ts">
import { trashOutline } from "ionicons/icons";
import { useI18n } from 'vue-i18n';
import { strapiApiUrl } from '@/api/api';
import {
  IonContent,
  IonCard,
  IonHeader,
  IonTitle,
  IonToolbar,
  modalController,
  IonButton,
  IonSpinner,
  IonButtons,
  IonIcon,
} from '@ionic/vue';
import { close, download, share } from 'ionicons/icons';
import { defineComponent, onMounted, PropType, ref, watch } from 'vue';
import PDFViewer from '@/components/Base/PdfViewer';
import Toolbar from '@/components/Navigation/Toolbar';
import StrapiMedia from '@/models/photo/strapi-media.model';
import { getPdfUrl } from '@/utilities/get-media-url';
import PdfPageSkeleton from '@/components/PdfPageSkeleton.vue';
import useDownloadHelper from '@/composables/useDownloadHelper';
import PdfReader from "@/components/PdfReader/PdfReader.vue";

export default defineComponent({
  name: 'PdfModal',
  components: {
    PdfReader,
    PdfPageSkeleton,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonCard,
    IonButton,
    IonButtons,
    IonIcon,
    IonSpinner,
    PDFViewer,
    Toolbar,
  },
  props: {
    pdf: {
      type: Object as PropType<StrapiMedia>,
      required: true,
    },
    deleteMode: {
      type: Boolean,
      default: false,
    },
    onDelete: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' });
    const onClose = async () => {
      await modalController.dismiss();
    };

    const pdfsrc = ref();
    const numPages = ref();
    const isLoaded = ref();
    const isError = ref();

    const { canShare, downloadFile, isProcessing } = useDownloadHelper();

    function downloadResource() {
      const url: string | undefined = getPdfUrl(props.pdf);
      url && downloadFile(url, props.pdf.name);
    }

    return {
      close,
      strapiApiUrl,
      canShare,
      onClose,
      getPdfUrl,
      pdfsrc,
      numPages,
      download,
      downloadResource,
      isLoaded,
      isProcessing,
      isError,
      trashOutline,
      t,
      share,
    };
  },
});
</script>

<style scoped lang="scss">
.padding {
  margin: 8px;
}

.hide {
  display: none !important;
}

ion-icon {
  color: var(--white100);
}

ion-button {
  color: var(--white100);
}
</style>
