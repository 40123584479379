import { SortTermActive } from "@/composables/Sort/SortTerms";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import Immobilie from "@/models/immobilie.model";
import moment from "moment/moment";

export const sortByDate = (a: string, b: string, sortTerm: SortTermActive, failOnInvalid = true): number | 'invalid' => {
    // Date sort
    const aDate = moment(a, moment.ISO_8601, true);
    const bDate = moment(b, moment.ISO_8601, true);

    const aValid = aDate.isValid();
    const bValid = bDate.isValid();

    if (!failOnInvalid && (!aValid || !bValid)) {
        if (!aValid) { return sortTerm.orderBy === 'asc' ? -1 : 1 }
        if (!bValid) { return sortTerm.orderBy === 'asc' ? 1 : -1 }
    }

    if (aValid && bValid) {
        if (sortTerm.orderBy === 'asc') {
            return aDate.isBefore(bDate) ? -1 : 1;
        }
        return aDate.isBefore(bDate) ? 1 : -1;
    }

    return 'invalid';
}

function compareMalus( malusA: string, malusB: string, orderBy: string) {
    const aMalus = malusA ? Number(malusA) : 0;
    const bMalus = malusB ? Number(malusB) : 0;

    return orderBy === 'asc' ? aMalus - bMalus : bMalus - aMalus;
}

export const sortArrayByProperty = (a: any, b: any, sortTerm: SortTermActive) => {
    if (!sortTerm) {
        return false;
    }

    if (sortTerm.localSubobjectSortFunction) {
        return sortTerm.localSubobjectSortFunction(a, b, sortTerm);
    }

    const aWrapperObj: any = (sortTerm.subObject ? a[sortTerm.subObject] : a);
    const bWrapperObj: any = (sortTerm.subObject ? b[sortTerm.subObject] : b);

    const aVal: any = aWrapperObj[sortTerm.fieldName as keyof typeof a] || '';
    const bVal: any = bWrapperObj[sortTerm.fieldName as keyof typeof b] || '';
    
    if (sortTerm.fieldName === 'malusColor') {
        const aIsFalsy = !aVal || aVal === "0";
        const bIsFalsy = !bVal || bVal === "0";

        if (aIsFalsy && bIsFalsy) return compareMalus(a.malus, b.malus, sortTerm.orderBy);
        // if (aIsFalsy) return 1; // Falsy values are always placed last
        // if (bIsFalsy) return -1; / Falsy values are always placed last

        if (aVal === bVal) return compareMalus(a.malus, b.malus, sortTerm.orderBy);

        return sortTerm.orderBy === 'asc' ? Number(aVal) - Number(bVal) : Number(bVal) - Number(aVal);
    }

    const sortByDateVar = sortByDate(aVal, bVal, sortTerm);
    if (sortByDateVar !== 'invalid') {
        return sortByDateVar;
    }

    // Num Sort
    const aNum = Number.parseFloat(aVal);
    const bNum = Number.parseFloat(bVal);

    if (isNaN(aNum) || isNaN(bNum)) {
        return sortTerm.orderBy === 'asc' ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
    }

    // String sort
    return sortTerm.orderBy === 'asc' ? aNum - bNum : bNum - aNum;
}

export const copyBaAndMergeWithImmobilie = (ba: BestandsaufnahmeModel) => {
    const immo = Immobilie.getters('getPropOrFallbackProp')(ba.immobilie);

    if (immo) {
        const baCopied = JSON.parse(JSON.stringify(ba));
        baCopied.immobilie = immo;
        return baCopied;
    }
    // console.error(`copyBaAndMergeWithImmobilie could not find immobilie with id ${ba.immobilie} for ba with id ${ba.id}`, ba.$toJson(), !ba.immobilie ? 'Immobilie is null and might not be set on backend site.' : '')
    return ba;
}