<template>
  <svg width="1em" height="1em" viewBox="0 0 22 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>30682375-97FD-4A16-B013-9222B3A39065</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="00_MovingLayers_UI_Design-System" transform="translate(-207.000000, -2678.000000)" stroke="var(--primary)" stroke-width="1.1">
        <g id="BTN_Default-plus-icon_38px" transform="translate(190.000000, 2668.000000)">
          <g id="Group" transform="translate(17.000000, 9.961538)">
            <g id="Icon-/-Cloud-!-Copy" transform="translate(0.000000, 0.038462)">
              <g id="Group" transform="translate(0.785714, 0.800000)">
                <polygon id="Stroke-1" points="20.4285714 15.2 -2.00995173e-12 15.2 -2.00995173e-12 1.64058284 6.41092772 1.64058284 7.99189636 0 12.5293926 0 14.1482234 1.64058284 20.4285714 1.64058284"></polygon>
                <path d="M14.1428571,8 C14.1428571,10.2092101 12.3837461,12 10.2142857,12 C8.04452577,12 6.28571429,10.2092101 6.28571429,8 C6.28571429,5.79078987 8.04452577,4 10.2142857,4 C12.3837461,4 14.1428571,5.79078987 14.1428571,8 Z" id="Stroke-3"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CameraIcon'
})
</script>

<style lang="scss">

</style>
