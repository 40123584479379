import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_label = _resolveComponent("input-label")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_AppMap = _resolveComponent("AppMap")!
  const _component_input_error = _resolveComponent("input-error")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    lines: "none",
    "data-cy": "text-input-item"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_grid, { class: "p-0 mb-8" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "ion-wrap d-flex items-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_input_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.label), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, { class: "ion-float-right" })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, { class: "mb-4" }, {
            default: _withCtx(() => [
              _createVNode(_component_AppMap, {
                class: "hzbaMap",
                identifier: _ctx.identifier,
                inputJson: _ctx.modelValue,
                mapSettings: _ctx.mapSettings,
                readonly: _ctx.readonly,
                "onUpdate:inputJson": _ctx.onChanged
              }, null, 8, ["identifier", "inputJson", "mapSettings", "readonly", "onUpdate:inputJson"])
            ]),
            _: 1
          }),
          _createVNode(_component_input_error, { errors: _ctx.errors }, null, 8, ["errors"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}