import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-95d0c98c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "text-xs",
  "data-cy": "pdf-page-header"
}
const _hoisted_2 = { class: "flex justify-between py-1" }
const _hoisted_3 = {
  class: "font-semibold",
  "data-cy": "header-titel"
}
const _hoisted_4 = { "data-cy": "header-seite" }
const _hoisted_5 = {
  class: "top-header mb-1 pb-1",
  "data-cy": "header-data"
}
const _hoisted_6 = {
  key: 0,
  class: "chapter mt-2",
  "data-cy": "header-breadcrumbs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t("hzba.pdf.acon", 1, { locale: _ctx.lang })) + " " + _toDisplayString(_ctx.t("hzba.pdf.kopfzeile")) + " " + _toDisplayString(_ctx.t("hzba.pdf.heizzentrale")), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t("hzba.pdf.seite", 1, { locale: _ctx.lang })) + " " + _toDisplayString(_ctx.page) + " / " + _toDisplayString(_ctx.maxPages), 1)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t("hzba.pdf.objektnummer", 1, { locale: _ctx.lang })) + ": " + _toDisplayString(_ctx.immobilie.externeObjektNr) + " / " + _toDisplayString(_ctx.t("hzba.pdf.datum", 1, { locale: _ctx.lang })) + ": " + _toDisplayString(_ctx.moment(_ctx.hzba.begehungsdatum).format("DD.MM.YYYY")) + " / " + _toDisplayString(_ctx.t("hzba.pdf.auftraggeber", 1, { locale: _ctx.lang })) + ": " + _toDisplayString(_ctx.getVerwalterLabel() || "-"), 1),
    (_ctx.showChapters)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.techData && _ctx.techData, (entry, key) => {
            return (_openBlock(), _createElementBlock("span", {
              key: key,
              class: _normalizeClass(_ctx.active === entry.getTitle(_ctx.lang) ? 'active' : '')
            }, _toDisplayString((key > 0 ? " > " : "") + entry.getTitle(_ctx.lang)), 3))
          }), 128)),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.isMangelUebersicht === true ? 'active' : '')
          }, " > " + _toDisplayString(_ctx.t("hzba.pdf.mangeluebersicht", 1, { locale: _ctx.lang })), 3),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.isHinweisUebersicht === true ? 'active' : '')
          }, " > " + _toDisplayString(_ctx.t("hzba.pdf.weitereHinweise", 1, { locale: _ctx.lang })), 3)
        ]))
      : _createCommentVNode("", true)
  ]))
}