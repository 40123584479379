<template>
  <pdf-a4-page no-margin :pdf-key="lang" class="pdf-content">
    <PageCounter/>
    <div class="landingpage-background w-screen h-screen">
      <div class="flex flex-col justify-between" style="height: 1020px">
        <div class="flex justify-between">
          <div class="text-3xl md:text-3xl">Seilzug Checkliste</div>
          <div>
            <img
              src="../../../../public/assets/img/pdf/transnetBW/tng-logo.png"
              width="200"
            />
          </div>
        </div>
        <div class="content">
          <div class="h-full max-h-full">
            <div
              class="mt-2 grid grid-cols-8 justify-items-stretch items-center"
            >
              <!-- Baumaßnahme Ende-->
              <div
                class="col-span-3 text- font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
              >
                Vorhaben
              </div>
              <div
                class="text-sm font-bold col-span-3"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Aufnahmedaten
              </div>
              <div
                class="col-span-2 text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Mast-Foto
              </div>

              <div class="text-xs ml-2">Vorhaben:</div>
              <div class="text-gray-500 col-span-2 text-xs">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-seilzugflaechen.allgemein-tab.allgemein-group.vorhaben'
                  )['eingabeText']
                }}
              </div>
              <div class="text-xs ml-2">Bearbeiterin:</div>
              <div
                v-if="ba?.bearbeitenderNutzer"
                class="text-gray-500 text-xs col-span-2"
              >
                {{ ba?.bearbeitenderNutzer?.username }}
              </div>
              <div v-else class="col-span-2"></div>
              <div
                v-if="getPhotoUrl(immobilie?.vorschaubild)"
                class="mt-2 row-span-5 col-span-2"
              >
                <img
                  :src="getPhotoUrl(immobilie?.vorschaubild)"
                  style="max-height: 150px"
                  height="150"
                  width="180"
                  data-cy="generalData-vorschaubild"
                />
              </div>
              <div
                v-else
                class="row-span-5 col-span-2 flex item-stretch justify-center items-center mt-2"
                style="
                  height: 150px;
                  max-height: 150px;
                  background-color: rgb(235, 239, 240);
                  border-radius: 5px;
                  border-style: solid;
                  border-color: rgb(235, 239, 240) rgb(235, 239, 240)
                    rgb(235, 239, 240) rgb(235, 239, 240);
                "
              >
                <p class="text-xs text-center self-center">
                  Kein Bild hinzugefügt
                </p>
              </div>
              <div class="text-xs ml-2">Anlagennummer:</div>
              <div class="text-gray-500 text-xs col-span-2">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-seilzugflaechen.allgemein-tab.allgemein-group.anlage'
                  )['eingabeText']
                }}
              </div>
              <div class="text-xs ml-2">Firma:</div>
              <div class="text-gray-500 col-span-2 text-xs">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-seilzugflaechen.allgemein-tab.allgemein-group.firma'
                  )['eingabeText']
                }}
              </div>
              <div class="text-xs ml-2">Mastnummer:</div>
              <div class="text-gray-500 text-xs col-span-2">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-seilzugflaechen.allgemein-tab.allgemein-group.mastnummer'
                  )['eingabeText']
                }}
              </div>
              <div class="text-xs col-span-2 ml-2">Begehungsdatum:</div>
              <div class="text-gray-500 text-xs">
                {{ moment(ba?.begehungsdatum).format('DD-MM-YYYY') }}
              </div>
              <div class="col-span-3"></div>
              <div class="text-xs col-span-2 ml-2">Bearbeitungsstatus:</div>
              <div class="text-gray-500 text-xs">
                <div v-if="ba?.status === 'ANGELEGT'" class="text-gray-500">
                  angelegt
                </div>
                <div
                  v-else-if="ba?.status === 'IN_DURCHFUEHRUNG'"
                  class="text-gray-500"
                >
                  in Durchführung
                </div>
                <div
                  v-else-if="ba?.status === 'ABGESCHLOSSEN'"
                  class="text-gray-500"
                >
                  abgeschlossen
                </div>
                <div
                  v-else-if="ba?.status === 'FREIGEGEBEN'"
                  class="text-gray-500"
                >
                  freigegeben
                </div>
                <div
                  v-else-if="ba?.status === 'ARCHIVIERT'"
                  class="text-gray-500"
                >
                  archiviert
                </div>
              </div>
              <div class="col-span-3"></div>
              <div class="text-xs col-span-2 ml-2">Aktualisierungsdatum:</div>
              <div class="text-gray-500 text-xs">
                {{ moment(ba?.updatedAt).format('DD-MM-YYYY') }}
              </div>
              <div class="text-xs">Unterzeichnet von:</div>
              <div class="col-span-2">
                {{ ba?.unterschrifts[0]?.name || '' }}
              </div>
              <div
                v-if="getPhotoUrl(ba?.unterschrifts[0]?.bild) != undefined"
                class="h-2 text-xs ml-2 col-span-5"
              >
                <img
                  :src="getPhotoUrl(ba?.unterschrifts[0]?.bild)"
                  style="
                    max-height: 50px;
                    max-width: 180px;
                    background: #efefef;
                  "
                  height="50"
                  width="180"
                />
              </div>
              <div
                v-else
                class="h-2 text-xs ml-2 col-span-3 flex item-stretch justify-center items-center"
                style="
                  height: 50px;
                  max-height: 50px;
                  background-color: rgb(235, 239, 240);
                  border-radius: 5px;
                  border-style: solid;
                  border-color: rgb(235, 239, 240) rgb(235, 239, 240)
                    rgb(235, 239, 240) rgb(235, 239, 240);
                "
              >
                <p class="text-xs text-center self-center">
                  keine Unterschrift hinzugefügt
                </p>
              </div>
            </div>

            <!-- RESULTS -->
                     <div
              class="mt-6 flex justify-between"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              <div class="text-xl md:text-xl font-bold">Gesamt-Bewertung</div>
              <div>
                <PdfChip
                  :color="summarizeOverallSeilzugflaechen(ba)['color']"
                  mangel-amount-mode
                  :value="summarizeOverallSeilzugflaechen(ba)['amount']"
                  pdf-mode
                />
              </div>
            </div>
            <div class="mt-2 ml-2 text-xs">
              Bewertung der Bearbeiterin:
              <span class="text-xs text-gray-500">{{ ba?.fazit }}</span>
            </div>
        <!-- RESULTS Seilzugfläche-->
            <div
                v-for="(seilzugflaeche, szfKey) in getSeilzugflaechen(ba)"
                :key="szfKey"
                >
              <div

                class="mt-6 flex justify-between"
                style="border-style: solid; border-color: white white rgb(216, 215, 26) white; border-radius: 5px;"
              >
                <div class="text-lg md:text-lg">Seilzugfl&auml;che {{szfKey + 1}}</div>

              <div class="text-xs" >{{seilzugflaeche.findFrageByRelativePath(ba,'ergebnis-modal.ergebnis.seilzugflaeche')?.getSelectedAuswahlLabelsString()}}</div>
              <div class="text-xs" >Zuwegung: {{seilzugflaeche.findFrageByRelativePath(ba,'ergebnis-modal.ergebnis.ergebniszuwegung')?.getSelectedAuswahlLabelsString()}}</div>
              <div class="justify-self-end">
                <PdfChip
                  :color="summarizeSeilzugflaecheBewertungen(ba,seilzugflaeche)['color']"
                  mangel-amount-mode
                  :value="summarizeSeilzugflaecheBewertungen(ba,seilzugflaeche)['amount']"
                  pdf-mode
                />
              </div>
              </div>

              <div class="p-2 grid grid-cols-8 gap-1 justify-item-stretch">
              <div class="col-span-4 text-sm ml-1">Seilzugfl&auml;che:</div>
              <div class="justify-self-end">
                <PdfChip
                  color="green"
                  mangel-amount-mode
                  :value="
                    Object?.values(getSeilzugflaecheBewertungen(ba,seilzugflaeche)['seilzugflaeche'])
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'plus';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="blue"
                  mangel-amount-mode
                  :value="
                    Object?.values(getSeilzugflaecheBewertungen(ba,seilzugflaeche)['seilzugflaeche'])
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'o';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="yellow"
                  mangel-amount-mode
                  :value="
                    Object?.values(getSeilzugflaecheBewertungen(ba,seilzugflaeche)['seilzugflaeche'])
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'minus';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="red"
                  mangel-amount-mode
                  :value="
                    Object?.values(getSeilzugflaecheBewertungen(ba,seilzugflaeche)['seilzugflaeche'])
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'x';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <!-- jfklads-->
              <div class="col-span-4 text-sm ml-1" v-if="seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.zuwegung-vorhanden')?.getCurrentInput() ||
              seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.zuwegung-notwendig')?.getCurrentInput() "
              >Zuwegung Seilzugfläche:</div>
              <span style="display: contents" v-if="seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.zuwegung-vorhanden')?.getCurrentInput() ||
              seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.zuwegung-notwendig')?.getCurrentInput() "
              >              
              <div class="justify-self-end">
                <PdfChip
                  color="green"
                  mangel-amount-mode
                  :value="
                    Object?.values(getSeilzugflaecheBewertungen(ba,seilzugflaeche)['zuwegungSeilzugflaeche'])
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'plus';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="blue"
                  mangel-amount-mode
                  :value="
                    Object?.values(getSeilzugflaecheBewertungen(ba,seilzugflaeche)['zuwegungSeilzugflaeche'])
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'o';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="yellow"
                  mangel-amount-mode
                  :value="
                    Object?.values(getSeilzugflaecheBewertungen(ba,seilzugflaeche)['zuwegungSeilzugflaeche'])
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'minus';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="red"
                  mangel-amount-mode
                  :value="
                    Object?.values(getSeilzugflaecheBewertungen(ba,seilzugflaeche)['zuwegungSeilzugflaeche'])
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'x';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              </span>
              <div class="col-span-8 text-sm ml-1" v-else>Zuwegung zu Seilzugfläche nicht vorhanden und nicht benötigt</div>    
              
              
              <!-- jfklads-->
      
              <div class="col-span-4 text-sm ml-1" v-if="seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.flaeche-vorhanden')?.getCurrentInput() ||
              seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.flaeche-notwendig')?.getCurrentInput() "
              >Fläche zwischen Mast und Seilzugfläche:</div>
              <span style="display: contents" v-if="seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.flaeche-vorhanden')?.getCurrentInput() ||
              seilzugflaeche.findFrageByRelativePath(ba, 'allgemein-modal.allgemein-group.flaeche-notwendig')?.getCurrentInput() "
              >
              <div class="justify-self-end">
                <PdfChip
                  color="green"
                  mangel-amount-mode
                  :value="
                    Object?.values(getSeilzugflaecheBewertungen(ba,seilzugflaeche)?.['flaecheZwischenMastUndSeilzug'])
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'plus';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="blue"
                  mangel-amount-mode
                  :value="
                    Object?.values(getSeilzugflaecheBewertungen(ba,seilzugflaeche)?.['flaecheZwischenMastUndSeilzug'])
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'o';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="yellow"
                  mangel-amount-mode
                  :value="
                    Object?.values(getSeilzugflaecheBewertungen(ba,seilzugflaeche)?.['flaecheZwischenMastUndSeilzug'])
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'minus';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="red"
                  mangel-amount-mode
                  :value="
                    Object?.values(getSeilzugflaecheBewertungen(ba,seilzugflaeche)?.['flaecheZwischenMastUndSeilzug'])
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'x';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              </span>  
              <div class="col-span-8 text-sm ml-1" v-else>Fläche zwischen Mast und Seilzugfläche nicht vorhanden und nicht benötigt</div>          
              </div>
            </div>
<br>
                        <!-- RESULTS Fremdleitungen-->
            <div
              class="flex justify-between"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              <div class="text-lg md:text-lg">Fremdleitungen</div>
              <div>
                <PdfChip
                  :color="colorFremdleitung"
                  mangel-amount-mode
                  :value="amountFremdleitung"
                  
                  pdf-mode
                />
              </div>
            </div>
            <div class="p-2 grid grid-cols-8 gap-2 w-fit">
              <div class="col-span-4 ml-2 text-sm">Fremdleitungen:</div>
              <div class="justify-self-end">
                <PdfChip
                  color="green"
                  mangel-amount-mode
                  :value="
                    Object?.values(getFremdleitungenBewertungen(ba))
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'plus';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="blue"
                  mangel-amount-mode
                  :value="
                    Object?.values(getFremdleitungenBewertungen(ba))
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'o';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="yellow"
                  mangel-amount-mode
                  :value="
                    Object?.values(getFremdleitungenBewertungen(ba))
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'minus';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="red"
                  mangel-amount-mode
                  :value="
                    Object?.values(getFremdleitungenBewertungen(ba))
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'x';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
            </div>
          </div>
        </div>
        <seilzugflaechen-footer
          :ba="ba"
          :immobilie="immobilie"
          :lang="lang"
          class=""
        ></seilzugflaechen-footer>
      </div>
    </div>
  </pdf-a4-page>
</template>


<script lang="ts">
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import {
  useIdentifierFrageFinder,
  getAuswahlLabel,
} from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import { getPhotoUrl } from '@/utilities/get-media-url';
import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';
import { useSeilzugflaechen } from './useSeilzugflaechen';

import PdfChip from '../Components/PdfChip.vue';
import SeilzugflaechenFooter from './SeilzugflaechenFooter.vue';
import PageCounter from '@/components/Pdf/Components/PageCounter';

export default defineComponent({
  name: 'CoverPage',
  components: {
    PdfA4Page,
    PdfChip,
    SeilzugflaechenFooter,
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();
    const {
      getSeilzugflaechen,
      getSeilzugflaechenWrappers,
      summarizeSeilzugflaecheBewertungen,
      getSeilzugflaecheBewertungen,
      getFremdleitungenBewertungen,
      summarizeFremdleitungenBewertung,
      summarizeOverallSeilzugflaechen,
    } = useSeilzugflaechen();

    return {
    items: [
            {
              colorArbeitsFlaechen: 'red',
              amountArbeitsFlaechen: 5,
            },
                 {
              colorArbeitsFlaechen: 'red',
              amountArbeitsFlaechen: 5,
            },
            {
              colorArbeitsFlaechen: 'blue',
              amountArbeitsFlaechen: 8,
            },
          ],
      user: uUser.user,
      t,
      getSeilzugflaechenWrappers,
      getSeilzugflaechen,
      useIdentifierFrageFinder,
      moment,
      getPhotoUrl,
      getSeilzugflaecheBewertungen,
      getFremdleitungenBewertungen,
      summarizeSeilzugflaecheBewertungen,
      amountFremdleitung: summarizeFremdleitungenBewertung(props.ba)['amount'],
      colorFremdleitung: summarizeFremdleitungenBewertung(props.ba)['color'],
      summarizeOverallSeilzugflaechen,
      getAuswahlLabel,
      PageCounter
    };
  },
});
</script>

<style scoped>
* {
  color: rgb(23, 68, 81);
  font-family: 'Avenir-Next-LT-Pro-Regular';
}
.pdf-content {
  width: 800px;
  margin: 0 auto;
  color: white;
}

.landingpage-background {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 700px;
  height: 1050px;
  color: black;
}
.trenner {
  height: 1px;
  border-width: 0;
  color: gray;
  background-color: gray;
}
.content {
  height: 800px;
}
</style>