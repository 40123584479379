<template>
  <div>
    <!--    <div class="mx-4 my-2 md:flex md:m-8">-->
    <!--      <status-progress-item-->
    <!--        :text="t('hzba.vorschau.statusAngelegt')"-->
    <!--        :current-class="getClassForStage('ANGELEGT')"-->
    <!--        :step="1"-->
    <!--      />-->
    <!--      <status-progress-item-->
    <!--        :text="t('hzba.vorschau.statusGeplant')"-->
    <!--        :current-class="getClassForStage('GEPLANT')"-->
    <!--        :step="2"-->
    <!--      />-->
    <!--      <status-progress-item-->
    <!--        :text="t('hzba.vorschau.statusInDurchführung')"-->
    <!--        :current-class="getClassForStage('IN_DURCHFUEHRUNG')"-->
    <!--        :step="3"-->
    <!--      />-->
    <!--      <status-progress-item-->
    <!--        :text="t('hzba.vorschau.statusAbgeschlossen')"-->
    <!--        :current-class="getClassForStage('ABGESCHLOSSEN')"-->
    <!--        :step="4"-->
    <!--      />-->
    <!--      <status-progress-item-->
    <!--        :text="t('hzba.vorschau.statusFreigegeben')"-->
    <!--        :current-class="getClassForStage('FREIGEGEBEN')"-->
    <!--        :step="5"-->
    <!--      />-->
    <!--    </div>-->

    <div class="m-4">
      <div class="md:flex">
        <div class="flex-1">
          <h2 class="text-2xl mb-2">
            {{ t("hzba.vorschau.synchronisierungStatus") }}
          </h2>
        </div>
        <div>
          <div
            v-if="!hzba.isLocal"
            class="px-4 py-2 flex items-center"
          >
            {{ t("hzba.vorschau.synchronisiert") }}

            <div class="ml-2 synced">
              <ion-icon
                :icon="checkmark"
                color="white"
                size="medium"
              />
            </div>
          </div>
        </div>
      </div>

      <info-banner
        v-if="(!isOffline) && (hzba?.isLocal || uploadImageStatus.totalSuccessfulUploads() !== uploadImageStatus.totalImages())"
        no-extra-margin
      >
        <div class="block md:flex items-center">
          <div class="mb-2 mt-2 flex-1">
            <span>
              {{ hzba?.isLocal ? t("hzba.vorschau.nichtSynchronisierteDaten") : t("hzba.vorschau.nichtSynchronisierteBilder") }}
            </span>
          </div>
          <div>
            <ion-button
              v-if="!isOffline"
              fill="flat"
              shape="round"
              :disabled="isReadonly"
              data-cy="button-upload-data"
              @click="$emit('saveHzba')"
            >
              {{ t("hzba.buttons.synchronisieren") }}
            </ion-button>
          </div>
        </div>
      </info-banner>

      <offline-hint style="margin-top: 8px">
        {{ t("hzba.vorschau.keinInternetLokalGespeichert") }}
      </offline-hint>

      <div style="color: #b6b6b6">
        <div
          v-if="hzba && hzba.updatedAt"
          class="mb-2 mt-2"
        >
          {{ t("hzba.vorschau.zuletztSynchronisiertAm") }} {{ new Date(hzba.updatedAt).toLocaleString("de-De") }}
        </div>

        <div>
          <p v-if="photoUploads">
            {{ t('hzba.vorschau.bilderHochladenStatus') }}
          </p>
          <p v-else>
            {{ `${uploadImageStatus.totalSuccessfulUploads()}/${uploadImageStatus.totalImages()} ${t('hzba.vorschau.bilderHochgeladen')}` }}
          </p>
        </div>

        <div v-if="!hzba?.updatedAt">
          {{ t("hzba.vorschau.nichtHochgeladenInfo") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import ProgressIndicator from "@/components/ProgressIndicator";
import StatusProgressItem from "@/components/hzba/StatusProgressItem";
import { defineComponent, watch } from "vue";
import { IonButton, IonIcon } from "@ionic/vue";
import { checkmark } from 'ionicons/icons';
// import {getAllAPhotosInObj} from "@/utilities/photo-storage";
import {isStrapiPhoto} from "@/utilities/get-media-url";
import {computed} from "vue";
import { useStore } from "@/composables/useTypedStore";
import OfflineHint from "@/components/Other/OfflineHint";
import InfoBanner from "@/components/Base/InfoBanner.vue";
import { BestandsaufnahmePhotoUploadTask } from '@/api/UploadApi';
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import {HzbaStatusKey} from "@/models/ba/interfaces/IBestandsaufnahme";
import {store} from "@/store/store";
import useUploadImageStatus from "@/composables/useUploadImageStatus";

export default defineComponent({
  name: "StatusProgress",
  components: {InfoBanner, OfflineHint, StatusProgressItem, ProgressIndicator, IonIcon, IonButton},
  props: {
    currentStatus: {
      type: String,
      required: true
    },
  },
  emits: ['saveHzba', 'saveHzbaLocally'],
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' })
    const store = useStore();
    const stages: HzbaStatusKey[] = [ "ANGELEGT", "GEPLANT", "IN_DURCHFUEHRUNG", "ABGESCHLOSSEN", "FREIGEGEBEN", "ARCHIVIERT" ]
    
    const getClassForStage = (stage: HzbaStatusKey) => {

      const index = stages.findIndex(el => el.toLowerCase() === stage.toLowerCase());
      const currentStatusIndex = stages.findIndex(el => el.toLowerCase() === props.currentStatus?.toLowerCase())

      if (index < currentStatusIndex) { return 'done' }
      if (index === currentStatusIndex) { return 'doing' }
      return ''
    }

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    const hzba = computed<Bestandsaufnahme | undefined>(() => {
      return store.state.currentHzba.currentBa;
    });

    const photoUploads = computed<BestandsaufnahmePhotoUploadTask | undefined>(() => {
      return store.state.currentHzba.photoUploads;
    })


    const uploadImageStatus = useUploadImageStatus();

    const isOffline = computed(() => { return store.getters['app/isOffline'] })

    return {
      hzba,
      stages,
      getClassForStage,
      checkmark,
      isOffline,
      t,
      isReadonly,
      photoUploads,
      uploadImageStatus
    }

  }
})
</script>

<style scoped lang="scss">

.status-container {
  display: flex;
  margin: 24px;
}

.synced {
  background: #6395fc;
  border-radius: 24px;
  text-align: center;
  color: var(--white100);
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 2px;
  justify-content: center;
}

</style>