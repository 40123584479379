

<template>
  <section class="pdf-content">
    <div>
      <cover-page :ba="ba" :immobilie="immobilie" :lang="lang"></cover-page>
    </div>
  </section>
</template>

<script lang="ts">
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import CoverPage from './CoverPage.vue';


export default defineComponent({
  name: 'PdfLEWMassnahmenTemplate',
  components: {
    CoverPage,
  },
  props: {
     ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();
    return {
      user: uUser.user,
      t
    };
  },
});
</script>

<style scoped>
.pdf-content {
  width: 800px;
  margin: 0 auto;
  color: white;
}

* {
  color: black;
  font-family: 'Avenir-Next-LT-Pro-Regular';
}
</style>