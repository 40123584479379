import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22e3716a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pdf-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cover_page = _resolveComponent("cover-page")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_cover_page, {
        ba: _ctx.ba,
        immobilie: _ctx.immobilie,
        lang: _ctx.lang
      }, null, 8, ["ba", "immobilie", "lang"])
    ])
  ]))
}