import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonImg = _resolveComponent("IonImg")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!

  return (_openBlock(), _createBlock(_component_ion_buttons, { slot: "primary" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        "data-cy": "userDialogButton-popover",
        class: "chooseProjectButton",
        onClick: _cache[0] || (_cache[0] = (e) => _ctx.openPopover(e, 'user.changeProject'))
      }, " P "),
      _createVNode(_component_ion_button, {
        "data-cy": "userDialogButton-popover",
        onClick: _cache[1] || (_cache[1] = (e) => _ctx.openPopover(e, 'user.profileImage'))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IonImg, {
            src: 
          _ctx.user?.profilbild
            ? _ctx.getPhotoUrl(_ctx.user.profilbild)
            : './assets/img/profiles/placeholder_profile_image.png'
        ,
            class: "rounded-full overflow-hidden",
            style: {"width":"32px","height":"32px","background":"white"}
          }, null, 8, ["src"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}