<template>
  <div
    v-if="!isMinLarge"
    class="mt-3"
  >
    <!--
    Mobile Header
     -->
    <ion-segment
      v-if="selectedView !== 'summary'"
      :value="selectedView"
      scrollable="true"
      mode="md"
      class="w-screen lg:w-auto"
      @ionChange="e => changeRoute(e)"
    >
      <ion-segment-button
        v-for="(tab, index) in tabs"
        :key="index"
        :value="tab.identifier"
        :class="[
          selectedView === tab.identifier || (!selectedView && index === 0 ) ? 'active' : '',
          tab.isUnlocked() ? '' : 'disabled'
        ]"
        layout="icon-start"
        :data-cy="`btn-mobile-nav-${tab.identifier}`"
      >
        <ProgressIndicator
          :progress="tab.getProgress()"
        />
        <!--        <ion-label class="normal-case">{{ $t("hzba.navigation.uebersicht") }}</ion-label>-->
        <ion-label class="normal-case">
          {{ tab.getTitle() }}
        </ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>



  <!--
  Desktop Header
   -->
  <div
    v-else
    class="w-screen lg:w-auto mt-2"
    @ionChange="$emit('change', $event)"
  >
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      class="segment-button"
      :data-cy="`btn-desktop-nav-${tab.identifier}`"
      :class="[
        selectedView === tab.identifier || (!selectedView && index === 0 ) ? 'active' : '', tab.isUnlocked() ? '' : 'disabled'
      ]"
      layout="icon-start"
      @click="changeRouteDesktop(tab)"
    >
      <ProgressIndicator
        :progress="tab.getProgress()"
        style="margin-right: 8px"
        data-cy="progress-desktop"
      />
      <ion-label>{{ tab.getTitle() }}</ion-label>
    </div>



    <div class="mt-8 lg:block hidden">
      <AButton
        type="button"
        fill="solid"
        shape="round"
        class="mr-2"
        data-cy="btn-preview"
        :btn-primary="true"
        @click="goToSummary"
      >
        {{ $t("hzba.buttons.vorschau") }}
      </AButton>
      <AButton
        v-if="ba?.hasPdfSupport()"
        type="button"
        fill="solid"
        shape="round"
        data-cy="btn-pdf-preview"
        :btn-primary="true"
        @click="$router.replace(`/ba/${ba?.id}/pdf-preview`)"
      >
        {{ $t("hzba.buttons.pdfVorschau") }}
      </AButton>
    </div>
  </div>
</template>

<script lang="ts">
import AButton from "@/components/Base/AButton";
import ProgressIndicator from "@/components/ProgressIndicator";
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { Fragenblock } from "@/models/ba/Fragenblock";
import { IonButton, IonLabel, IonSegment, IonSegmentButton, useIonRouter } from "@ionic/vue";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "BaTabs",
  components: {AButton, ProgressIndicator, IonSegment, IonSegmentButton, IonLabel, IonButton},
  props: {
    selectedView: {
      type: String,
      default: undefined
    },
  },
emits: ['showSummary', 'goTo', 'changeTab'],
  setup(props: any, { emit }: { emit: any}) {
    const store = useStore();
    const router = useRouter();
    const prevRoute = ref();

    const ionRouter = useIonRouter();


    const { isMinLarge } = useScreenSize();

    let currentTab = '';

    const ba = computed<Bestandsaufnahme | undefined>(() => {
      return store.state.currentHzba.currentBa;
    });

    const tabs = computed<Fragenblock[] | undefined>(() => {
      return ba.value?.fragenblocks
    })

    watch([tabs], (newVal) => {
      tabs.value?.forEach(el => el.setupUnlockDetector())
    }, { immediate: true })

    const changeRoute = (e: any) => {
      // console.log("change route", e.detail.value)
      if (currentTab !== e.detail.value && e.detail.value) {
        currentTab = e.detail.value;
        // router.replace(`/ba/${ba.value!.id}/${e.detail.value}`)

        emit('changeTab', e.detail.value);
        // ionRouter.navigate(`/ba/${ba.value!.id}/${e.detail.value}`, 'root', 'replace', undefined);
      }
    }

    const changeRouteDesktop = (tab: Fragenblock) => {
      // router.replace(`/ba/${ba.value!.id}/${tab.identifier}`)
      // ionRouter.navigate(`/ba/${ba.value!.id}/${tab.identifier}`, 'root', 'replace', undefined);

      emit('changeTab', tab.identifier);
    }

    const goToSummary = () => {
      emit('showSummary');
    }

    return {ba, tabs, goToSummary, changeRoute, changeRouteDesktop, isMinLarge}
  }
}
</script>

<style scoped lang="scss">

ion-segment-button {
  --background: var(--white100);
  --background-checked: var(--secondary) !important;
  --border-radius: 0;
  height: 62px;
  --indicator-color: transparent !important;
  max-width: unset;
  --margin-start: 0;
  --background-hover-opacity: 0;
  --background-focused-opacity: 0;
}

.segment-button {
  display: flex;
  padding: 16px;

  &.active {

    font-weight: 600;
    //color: $aconBlue;
    border-image-slice: 1;
    border-width: 3px;
    border-bottom: 2px solid;
    border-image-source: var(--primary) !important;
   }
}

.segment-button:hover {
  background: $grey-300;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

ion-segment {
  --background: var(--white100);
}
</style>